import React, { ButtonHTMLAttributes } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";
import { getIcon, IconProps, IconType } from "utils/getIcon";
import LoadingSpinnerCustom from "../LoadingSpinnerCustom/LoadingSpinnerCustom";

interface CustomButtonV2Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  customStyles?: React.CSSProperties;
  size?: "small" | "large" | "extra-small";
  color?: "primary" | "secondary" | "danger";
  iconLeft?: IconType;
  iconRight?: IconType;
  iconLeftProps?: IconProps;
  iconRightProps?: IconProps;
  title: string;
  loading?: boolean;
}

const CustomButtonV2: React.FC<CustomButtonV2Props> = ({
  customStyles,
  size = "small",
  color = "primary",
  iconLeft,
  iconRight,
  iconLeftProps,
  iconRightProps,
  title,
  loading = false,
  ...buttonProps
}) => {
  const isDisabled = buttonProps.disabled;
  const isLoading = loading && !isDisabled;

  const defaultIconColor = isDisabled
    ? "#98A2B3"
    : color === "primary"
      ? "#ffffff"
      : color === "danger"
        ? "#FF0044"
        : "#191B38";

  const spinnerColor =
    color === "primary" ? "light" : color === "danger" ? "danger" : "dark";

  return (
    <button
      {...buttonProps}
      className={cn(styles["custom-button"], styles[size], styles[color], {
        [styles["loading"]]: isLoading
      })}
      style={customStyles}
      disabled={isDisabled || isLoading}
    >
      <div className={styles["content"]}>
        {iconLeft && (
          <span className={styles["icon"]}>
            {getIcon(iconLeft, {
              ...iconLeftProps,
              color: iconLeftProps?.color || defaultIconColor
            })}
          </span>
        )}
        {title}
        {iconRight && (
          <span className={styles["icon"]}>
            {getIcon(iconRight, {
              ...iconRightProps,
              color: iconRightProps?.color || defaultIconColor
            })}
          </span>
        )}
      </div>
      {loading && (
        <div className={styles["spinner-container"]}>
          <LoadingSpinnerCustom color={spinnerColor} />
        </div>
      )}
    </button>
  );
};

export default CustomButtonV2;
