import ProductCardV2 from "components/ProductCardV2/ProductCardV2";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import SkeletonBestsellerCard from "components/UI/Skeleton/SkeletonBestsellerCard/SkeletonBestsellerCard";
import HeartIcon from "assets/profile/icons/HeartIcon";
import CustomLinkV2 from "components/UI/CustomLinkV2/CustomLinkV2";
import { useEffect, useRef, useState } from "react";
import { getUserFavoriteProducts } from "store/user/userThunks/userThunks";
import { IProductWithOffers } from "types/IProduct";
import { getErrorData } from "utils/getErrorData";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import styles from "./styles.module.scss";

const Favorites: React.FC = () => {
  const { user, isFavoriteLoading } = useAppSelector((state) => state.user);
  const countFavoriteProducts = user?.favouriteProducts.length || 0;
  const catalogLink = "/catalog";
  const dispatch = useAppDispatch();
  const favouriteProductsRef = useRef<IProductWithOffers[]>([]);
  const [, setUpdate] = useState(false);

  useEffect(() => {
    dispatch(getUserFavoriteProducts())
      .unwrap()
      .then((favouriteProducts) => {
        if (favouriteProducts && favouriteProducts.length > 0) {
          favouriteProductsRef.current = favouriteProducts.map((item) => {
            if ("offers" in item.product) {
              return item.product as IProductWithOffers;
            } else {
              const productWithOffers: IProductWithOffers = {
                ...item.product,
                offers: []
              };
              return productWithOffers;
            }
          });
          setUpdate((prev) => !prev);
        }
      })
      .catch((error) => {
        const errorData = getErrorData(error);
        // eslint-disable-next-line no-console
        console.error(
          "Ошибка при получении избранных продуктов пользователя:",
          errorData.message
        );
      });
  }, [dispatch]);

  return (
    <ProfileWrapper title="Избранное">
      {isFavoriteLoading ? (
        <div className={styles.table}>
          {Array.from({ length: countFavoriteProducts }).map((_, index) => (
            <div key={index} className={styles.table__cell}>
              <SkeletonBestsellerCard isProfile />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.table}>
          {favouriteProductsRef.current.length > 0 ? (
            favouriteProductsRef.current.map((product, index) => (
              <div key={index} className={styles.table__cell}>
                <ProductCardV2
                  product={product}
                  initialFavoriteStatus={true}
                />
              </div>
            ))
          ) : (
            <div className={styles.empty}>
              <p className={styles.empty__text}>
                  Нажмите{" "}
                <span className={styles["empty__icon-wrapper"]}>
                  <HeartIcon color="gray" height={18} width={20} />
                </span>
                  , чтобы добавить товары в избранное
              </p>
              <CustomLinkV2
                to={catalogLink}
                title="Перейти в каталог"
                size="large"
                color="secondary"
                iconRight="Arrow"
                iconRightProps={{
                  direction: "right",
                  width: 15,
                  height: 15
                }}
              />
            </div>
          )}
        </div>
      )}
    </ProfileWrapper>
  );
};

export default Favorites;
