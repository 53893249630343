import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { citiesApi } from "../../api/citiesApi";
import useDebounce from "./useDebounce";
import { CustomError, getErrorData } from "utils/getErrorData";
import { ICityData } from "types/IGetCitiesBySearchValue";
import { isError } from "store/user/userThunks/userThunks";

interface IUseCitiesSearchProps {
  limit: number;
}

const useCitiesSearch = (props: IUseCitiesSearchProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchedCities, setSearchedCities] = useState<ICityData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoResultSearch, setIsNoResultSearch] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);

  useEffect(() => {
    (async () => {
      if (!debouncedValue) {
        setIsLoading(false);
        setIsNoResultSearch(false);
        return;
      }

      try {
        const params = {
          query: debouncedValue,
          limit: props.limit
        };
        const data = await citiesApi.getCitiesBySearchValue(params);
        if (isError(data)) {
          setIsNoResultSearch(true);
          throw new CustomError(data.message, data?.code);
        }

        if (!data.response) {
          throw new CustomError("Данных о населённом пункте нет");
        }

        if (data.response.items.length === 0 && !!debouncedValue) {
          setIsNoResultSearch(true);
          setSearchedCities([]);
          return;
        }

        setSearchedCities(data.response.items);
        setIsNoResultSearch(false);
      } catch (err) {
        const { message } = getErrorData(err);
        setIsNoResultSearch(true);
        setSearchedCities([]);
        toast(message, { type: "error" });
      } finally {
        setIsLoading(false);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const onChangeSearchValue = (value: string) => {
    setSearchValue(value);
    setIsLoading(true);
    setIsNoResultSearch(false);
  };

  const onResetCitiesSearch = () => {
    setSearchValue("");
    setIsLoading(false);
    setIsNoResultSearch(false);
  };

  return {
    searchValue,
    searchedCities,
    isLoading,
    isNoResultSearch,
    onChangeSearchValue,
    onResetCitiesSearch,
  };
};

export default useCitiesSearch;
