import { userApi } from "api/userApi";
import { isError } from "store/user/userThunks/userThunks";
import { CustomError, getErrorData } from "utils/getErrorData";
import { setTokens } from "utils/localStorage";

export const fetchPrivate = async (
  input: string | URL | globalThis.Request,
  init?: RequestInit
): Promise<Response> => {
  const options = init || {};
  const res = await fetch(input, init);

  if (res.status === 401) {
    try {
      const refreshData = await userApi.refreshUser();

      if (isError(refreshData)) {
        throw new CustomError(refreshData.message, refreshData?.code);
      }

      if (!refreshData.response) {
        throw new CustomError("Нет данных на восстановление доступа");
      }

      setTokens({
        token: refreshData.response.token,
        refreshToken: refreshData.response.refresh
      });


      const newHeaders: HeadersInit = {
        ...options.headers,
        Authorization: `Bearer ${refreshData.response.token}`
      };

      options.headers = newHeaders;

      return await fetch(input, options);
    } catch (err) {
      throw getErrorData(err);
    }
  }

  return res;
};
