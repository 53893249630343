import { IDeliveryAdress } from "types/IDeliveryAdressAction";
import { IUser } from "types/IUser";

export enum Gender {
  Male = "male",
  Female = "female",
}

export const formatDate = (dateString: string | null): string => {
  if (!dateString) return "––";
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("ru-RU", options);
};

export const formatGender = (gender: string | null): string => {
  if (!gender) return "––";
  switch (gender) {
  case Gender.Male:
    return "Мужской";
  case Gender.Female:
    return "Женский";
  default:
    return "––";
  }
};

export const formatCity = (addresses: IDeliveryAdress[]): string => {
  if (addresses.length > 0) {
    return addresses[0].city || "––";
  }
  return "––";
};

export const formatAddress = (addresses: IDeliveryAdress[]): string => {
  if (addresses.length > 0) {
    const { street, house, flat, floor, entrance } = addresses[0];
    let address = `${street}, ${house}`;
    if (flat) address += `, кв. ${flat}`;
    if (floor) address += `, этаж ${floor}`;
    if (entrance) address += `, подъезд ${entrance}`;
    return address || "––";
  }
  return "––";
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
  }
  return phoneNumber;
};

export const formatProfileData = (user: IUser) => {
  const city = formatCity(user.accountDeliveryAddresses);
  const address = formatAddress(user.accountDeliveryAddresses);
  const birthday = formatDate(user.birthday);
  const gender = formatGender(user.gender);
  const phone = formatPhoneNumber(user.phone);

  return { city, address, birthday, gender, phone };
};