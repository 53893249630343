import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import PaymentMethod from "../PaymentMethod/PaymentMethod";
import PayOrderRecipientDataBlock from "./components/PayOrderRecipientDataBlock/PayOrderRecipientDataBlock";
import PayOrderOrderedProductsBlock from "./components/PayOrderOrderedProductsBlock/PayOrderOrderedProductsBlock";
import PayOrderInformationBlock from "./components/PayOrderInformationBlock/PayOrderInformationBlock";
import PayOrderCreatedOrderNotification from "./components/PayOrderCreatedOrderNotification/PayOrderCreatedOrderNotification";
import { ICreateNewOrderAuthorizedUsersResponse } from "types/ICreateNewOrderAuthorizedUsers";
import { ICreateNewOrderNonAuthorizedUsersResponse } from "types/ICreateNewOrderNonAuthorizedUsers";

import styles from "./styles.module.scss";

interface IPayOrderProps {
  orderData:
    | ICreateNewOrderAuthorizedUsersResponse
    | ICreateNewOrderNonAuthorizedUsersResponse
    | null;
}

const SCROLL_OFFSET_TOP = 300;

const PayOrder: React.FC<IPayOrderProps> = (props) => {
  const [isOrderCreated, setIsOrderCreated] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const orderId = props.orderData!.id.toString();

  const cartSum = useMemo(() => {
    return props
      .orderData!.orderOffers.reduce((acc, cur) => {
        acc += cur.sale_price * cur.count;
        return acc;
      }, 0)
      .toFixed(2);
  }, [props.orderData]);

  const orderProducts = props.orderData!.orderOffers.map((offer) => ({
    url: `${process.env.REACT_APP_PARFUMART_PUBLIC}/product/${offer.offer.catalog.id}-${offer.offer.catalog.product_rewrite_name}?type=${offer.offer.id}`,
    name: offer.offer.catalog.name,
    price: offer.sale_price,
    count: offer.count,
    currency: "RUB"
  }));

  useEffect(() => {
    const injectScript = () => {
      const orderOffersStr = JSON.stringify(orderProducts);
      const scriptContent = `(function () {
        function readCookie(name) {
          if (document.cookie.length > 0) {
            offset = document.cookie.indexOf(name + "=");
            if (offset != -1) {
              offset = offset + name.length + 1;
              tail = document.cookie.indexOf(";", offset);
              if (tail == -1) tail = document.cookie.length;
              return decodeURIComponent(document.cookie.substring(offset, tail));
            }
          }
          return null;
        }

        var lh_clid = '63720f7b73efc33f80376186';
        var order_id = "${orderId}";
        var cart_sum = "${cartSum}";
        var order_offers = ${orderOffersStr};
        
        var uid = readCookie('_lhtm_u');
        var cookieValue = readCookie('_lhtm_r');
        var vid = cookieValue ? cookieValue.split('|')[1] : null;
        var url = encodeURIComponent(window.location.href);
        var path = "https://track.leadhit.io/stat/lead_form?f_orderid=" + order_id + "&url=" + url + "&action=lh_orderid&uid=" + uid + "&vid=" + vid + "&ref=direct&f_cart_sum=" + cart_sum + "&clid=" + lh_clid;

        var sc = document.createElement("script");
        sc.type = 'text/javascript';
        var headID = document.getElementsByTagName("head")[0];
        sc.src = path;
        headID.appendChild(sc);

        if (Array.isArray(order_offers) && order_offers.length > 0) {
          var requestBody = {
            'order_id': order_id,
            'cart_sum': cart_sum,
            'vid': vid,
            'uid': uid,
            'clid': lh_clid,
            'offers': order_offers
          };
          var xhr = new XMLHttpRequest();
          xhr.open('POST', 'https://track.leadhit.io/stat/lead_order', true);
          xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
          xhr.onreadystatechange = function () {
            if (this.readyState != 4) return;
            console.log('order sended');
          };
          xhr.send(JSON.stringify(requestBody));
        }
      })();`;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = scriptContent;
      document.head.appendChild(script);
    };

    injectScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowOrderCreated = () => {
    setIsOrderCreated(true);
  };

  const onScrollContentToTop = useCallback(() => {
    if (!contentRef.current) {
      return;
    }
    const contentOffsetTop = contentRef?.current.offsetTop;
    window.scrollTo({
      top: contentOffsetTop - SCROLL_OFFSET_TOP,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    onScrollContentToTop();
  }, [onScrollContentToTop]);

  return (
    <div className={styles.pay} ref={contentRef}>
      {isOrderCreated ? (
        props.orderData ? (
          <PayOrderCreatedOrderNotification orderData={props.orderData} />
        ) : null
      ) : (
        <>
          {props.orderData && (
            <div className={styles["pay__info-block"]}>
              <PayOrderInformationBlock
                orderData={props.orderData}
                onOrderCreated={handleShowOrderCreated}
              />
            </div>
          )}
          <div className={styles.pay__method}>
            <PaymentMethod />
          </div>
          <div className={styles["pay__recipient-and-products"]}>
            <PayOrderRecipientDataBlock
              orderData={props.orderData}
              onOrderCreated={handleShowOrderCreated}
            />
            <PayOrderOrderedProductsBlock
              orderData={props.orderData}
              onOrderCreated={handleShowOrderCreated}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PayOrder;
