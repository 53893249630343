import * as yup from "yup";

const cityRegExp = /^[a-zA-Zа-яёА-ЯЁ0-9№,.'\s-]+$/i;
const addressPartRegExp = /^[a-zA-Zа-яёА-ЯЁ0-9/-]+$/i;

export interface ISchemaAddressV2 {
  addressName: string;
  city: string;
  deliveryAddress: string;
  flat?: string | null;
  floor?: string | null;
  entrance?: string | null;
  intercom?: string | null;
}

export const addressSchemaNew: yup.SchemaOf<ISchemaAddressV2> = yup.object().shape({
  addressName: yup.string().required("\"Название адреса\" является обязательным полем"),
  city: yup
    .string()
    .matches(cityRegExp, "\"Название населённого пункта\" недопустимо")
    .required("\"Населённый пункт\" является обязательным полем"),
  deliveryAddress: yup.string().required("\"Адрес доставки\" является обязательным полем"),
  flat: yup
    .string()
    .nullable()
    .matches(addressPartRegExp, "\"Квартира\" содержит недопустимые символы"),
  floor: yup
    .string()
    .nullable()
    .matches(addressPartRegExp, "\"Этаж\" содержит недопустимые символы"),
  entrance: yup
    .string()
    .nullable()
    .matches(addressPartRegExp, "\"Подъезд\" содержит недопустимые символы"),
  intercom: yup
    .string()
    .nullable()
    .matches(addressPartRegExp, "\"Домофон\" содержит недопустимые символы")
});