export const getQueryStringForRequest = (options: Record<string, string | undefined>): string => {
  if (!options) {
    return "";
  }

  const filteredOptions = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(options).filter(([_, value]) => value !== undefined) as [string, string][]
  );

  if (Object.keys(filteredOptions).length === 0) {
    return "";
  }

  const searchParams = new URLSearchParams(filteredOptions);
  return `?${searchParams.toString()}`;
};