import { useMemo } from "react";

import { useAppSelector } from "store/reduxHooks";
import CustomLinkV2 from "components/UI/CustomLinkV2/CustomLinkV2";

interface ISocialMediaLinkNewProps {
  service: string;
  vendorName: string;
}

const SocialMediaLinkNew: React.FC<ISocialMediaLinkNewProps> = (props) => {
  const { user, deviceType } = useAppSelector((state) => state.user);

  const isLinked = useMemo(() => {
    return user.profiles.some((item) => item.service === props.service);
  }, [props.service, user.profiles]);

  const currentHref = useMemo(() => {
    if (isLinked || !props.vendorName) {
      return undefined;
    }

    return `${process.env.REACT_APP_BACKEND_URL}/api/public/login/vendor/${props.vendorName}`;
  }, [props.vendorName, isLinked]);

  const buttonText = isLinked ? "Отвязать" : "Привязать";
  const buttonColor = isLinked ? "danger" : "secondary";
  const isDisabled = !currentHref && !isLinked;

  return (
    <CustomLinkV2
      to={currentHref || "#"}
      title={buttonText}
      size={deviceType.isMobile ? "extra-small" : "small"}
      color={buttonColor}
      disabled={isDisabled}
      target="_self"
      rel="noopener noreferrer"
    />
  );
};

export default SocialMediaLinkNew;