import { deliveryApi } from "api/deliveryApi";
import { isError } from "store/user/userThunks/userThunks";
import { CustomError, getErrorData } from "./getErrorData";
import { setProviderInfo } from "store/user/user";
import { toast } from "react-toastify";
import { ICheckoutData } from "types/ICheckoutData";
import { Dispatch } from "redux";
import { IProviderInfoStore } from "types/IProviderInfoStore";


export const recalculateDeliveryCost = async (
  trigger: boolean,
  productsMainPrice: number,
  checkoutData: ICheckoutData,
  dispatch: Dispatch<{ payload: IProviderInfoStore; type: string }>,
  setPreviousCartSum?: (sum: number) => void,
  previousCartSumRef?: React.MutableRefObject<number>
) => {
  if (trigger) {
    if (!checkoutData.providerInfo) {
      // eslint-disable-next-line no-console
      console.error("Нет информации о провайдере");
      return;
    }
    try {
      const providers = [checkoutData.providerInfo.provider];
      const data = await deliveryApi.getDeliveryCost({
        city: checkoutData.orderCity,
        providers,
        assessedCost: productsMainPrice
      });

      if (isError(data)) {
        throw new CustomError(data.message, data?.code);
      }

      if (data.response) {
        const providerType = checkoutData.providerInfo.type;
        const deliveryData =
          providerType === "delivery"
            ? data.response.toDoor
            : data.response.toPoint;

        const updatedProviderInfo = deliveryData.find(
          (item) =>
            item.deliveryProvider === checkoutData.providerInfo?.provider
        );

        if (updatedProviderInfo) {
          dispatch(
            setProviderInfo({
              ...checkoutData.providerInfo,
              amount: updatedProviderInfo.deliveryCost,
              daysMax: updatedProviderInfo.daysMax,
              daysMin: updatedProviderInfo.daysMin
            })
          );
        }
      }

      if (setPreviousCartSum) {
        setPreviousCartSum(productsMainPrice);
      } else if (previousCartSumRef) {
        previousCartSumRef.current = productsMainPrice;
      }
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(
        "Произошла ошибка при расчете стоимости доставки:",
        errorData.message
      );
      toast.error("Произошла ошибка при перерасчете стоимости доставки");
    }
  }
};