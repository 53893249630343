import { Link, LinkProps } from "react-router-dom";
import { getIcon, IconProps, IconType } from "utils/getIcon";
import styles from "./styles.module.scss";
import cn from "classnames";
import LoadingSpinnerCustom from "../LoadingSpinnerCustom/LoadingSpinnerCustom";


interface CustomLinkV2Props extends LinkProps {
  customStyles?: React.CSSProperties;
  size?: "small" | "large" | "extra-small";
  color?: "primary" | "secondary" | "danger";
  iconLeft?: IconType;
  iconRight?: IconType;
  iconLeftProps?: IconProps;
  iconRightProps?: IconProps;
  title: string;
  loading?: boolean;
  disabled?: boolean;
}

const CustomLinkV2: React.FC<CustomLinkV2Props> = ({
  customStyles,
  size = "small",
  color = "primary",
  iconLeft,
  iconRight,
  iconLeftProps,
  iconRightProps,
  title,
  loading = false,
  disabled = false,
  ...linkProps
}) => {
  const isDisabled = disabled;
  const isLoading = loading && !disabled;
  const defaultIconColor = disabled
    ? "#98A2B3"
    : color === "primary"
      ? "#ffffff"
      : color === "danger"
        ? "#FF0044"
        : "#191B38";

  const spinnerColor =
    color === "primary" ? "light" : color === "danger" ? "danger" : "dark";

  const isExternalLink = typeof linkProps.to === "string" && /^https?:\/\//.test(linkProps.to);

  const commonProps = {
    className: cn(styles["custom-link"], styles[size], {
      [styles[color]]: !isDisabled,
      [styles["loading"]]: isLoading,
      [styles["disabled"]]: isDisabled,
    }),
    style: customStyles,
    "aria-disabled": isDisabled || isLoading,
    tabIndex: isDisabled || isLoading ? -1 : undefined,
    onClick: isDisabled || isLoading ? (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.preventDefault() : linkProps.onClick,
  };

  return isExternalLink ? (
    <a
      {...commonProps}
      href={isDisabled || isLoading ? undefined : linkProps.to as string}
      target={linkProps.target}
      rel={linkProps.rel}
    >
      <div className={styles["content"]}>
        {iconLeft && (
          <span className={styles["icon"]}>
            {getIcon(iconLeft, { ...iconLeftProps, color: iconLeftProps?.color || defaultIconColor })}
          </span>
        )}
        {title}
        {iconRight && (
          <span className={styles["icon"]}>
            {getIcon(iconRight, { ...iconRightProps, color: iconRightProps?.color || defaultIconColor })}
          </span>
        )}
      </div>
      {loading && (
        <div className={styles["spinner-container"]}>
          <LoadingSpinnerCustom color={spinnerColor} />
        </div>
      )}
    </a>
  ) : (
    <Link
      {...commonProps}
      to={isDisabled || isLoading ? "" : linkProps.to}
    >
      <div className={styles["content"]}>
        {iconLeft && (
          <span className={styles["icon"]}>
            {getIcon(iconLeft, { ...iconLeftProps, color: iconLeftProps?.color || defaultIconColor })}
          </span>
        )}
        {title}
        {iconRight && (
          <span className={styles["icon"]}>
            {getIcon(iconRight, { ...iconRightProps, color: iconRightProps?.color || defaultIconColor })}
          </span>
        )}
      </div>
      {loading && (
        <div className={styles["spinner-container"]}>
          <LoadingSpinnerCustom color={spinnerColor} />
        </div>
      )}
    </Link>
  );
};

export default CustomLinkV2;