import CustomLinkV2 from "components/UI/CustomLinkV2/CustomLinkV2";
import { ProfileRoutesList } from "utils/constants/routes";
import RedBoxIcon from "assets/profile/icons/RedBoxIcon";
import styles from "./styles.module.scss";
import LoadingSpinnerCustom from "components/UI/LoadingSpinnerCustom/LoadingSpinnerCustom";
import { useAppSelector } from "store/reduxHooks";
import { memo, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { ORDER_STATUSES } from "utils/constants";

const OrdersPreview: React.FC = memo(() => {
  const { deviceType, orders, isOrdersLoading } = useAppSelector((state) => ({
    deviceType: state.user.deviceType,
    orders: state.user.orders.orders || [],
    isOrdersLoading: state.user.orders.isOrdersLoading
  }));

  const hasOrders = orders.length > 0;

  const previewOrders = useMemo(() => {
    return orders.slice(0, 2).map(order => ({
      ...order,
      status: ORDER_STATUSES[order.status]?.title || order.status
    }));
  }, [orders]);

  const linkSize = deviceType.isTablet || deviceType.isMobile ? "small" : "large";

  return (
    <div className={styles["orders-preview"]}>
      <h2 className={styles["orders-preview__title"]}>Мои заказы</h2>
      <div className={styles["orders-preview__content"]}>
        {isOrdersLoading ? (
          <div className={styles["spinner-container"]}>
            <LoadingSpinnerCustom variant="alternative"/>
          </div>
        ) : hasOrders ? (
          <div className={styles["orders-preview__list"]}>
            {previewOrders.map(order => (
              <NavLink to={`${ProfileRoutesList.MY_ORDERS}/${order.id}`} key={order.id} className={styles["orders-preview__item"]}>
                <div>
                  <RedBoxIcon />
                </div>
                <p className={styles["orders-preview__item-id"]}>Заказ №{order.id}</p>
                <p className={styles["orders-preview__item-status"]}>Статус: {order.status}</p>
              </NavLink>
            ))}
          </div>
        ) : (
          <p className={styles["orders-preview__empty"]}>Нет активных заказов</p>
        )}
      </div>
      <div className={styles["orders-preview__button-container"]}>
        {hasOrders && (
          <CustomLinkV2
            to={ProfileRoutesList.MY_ORDERS}
            title="Посмотреть все"
            size={linkSize}
            color="primary"
            iconRight="Arrow"
            iconRightProps={{ direction: "right", width: 12, height: 12 }}
            customStyles={{ width: "267px" }}
          />
        )}
      </div>
    </div>
  );
});

export default OrdersPreview;

OrdersPreview.displayName = "OrdersPreview";