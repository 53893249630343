import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomButtonV2 from "components/UI/CustomButtonV2/CustomButtonV2";
import { useFormik } from "formik";
import CustomProfileInput from "components/UI/CustomProfileInput/CustomProfileInput";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { addressSchemaNew, ISchemaAddressV2 } from "schemas/addressSchemaNew";
import { addDeliveryAdress } from "store/user/userThunks/userThunks";
import { getErrorData } from "utils/getErrorData";
import { toast } from "react-toastify";
import ListExistingAddresses from "./ListExistingAddresses/ListExistingAddresses";
import useYandexMapForAddress from "utils/hooks/useYandexMapForAddress";
import CrossIcon from "assets/profile/icons/CrossIcon";
import useDebounce from "utils/hooks/useDebounce";
import useCitiesSearch from "utils/hooks/useCitiesSearch";
// import LoadingSpinnerCustom from "components/UI/LoadingSpinnerCustom/LoadingSpinnerCustom";
import { processAddress } from "utils/helpers/processAddress";
import useDelivery from "utils/hooks/useDelivery";
import { IGetUserCity } from "types/IGetUserCity";
import { ICityData } from "types/IGetCitiesBySearchValue";
import CustomDropdownV2 from "components/CustomDropdownV2/CustomDropdownV2";
import styles from "./styles.module.scss";
import cn from "classnames";

export interface IDeliveryData {
  deliveryAddresses: string[];
  setDeliveryAddressByPosition: () => Promise<void>;
  isLoading: boolean;
  isAddressesNotFound: boolean;
  fullDeliveryAddresses: IGetUserCity[];
}

const ADRESS_VALUES: Array<{
  id: number;
  name: keyof ISchemaAddressV2;
  placeholder: string;
  inputType: string;
}> = [
  {
    id: 1,
    name: "flat",
    placeholder: "Квартира",
    inputType: "text"
  },
  {
    id: 2,
    name: "floor",
    placeholder: "Этаж",
    inputType: "text"
  },
  {
    id: 3,
    name: "entrance",
    placeholder: "Подъезд",
    inputType: "text"
  },
  {
    id: 4,
    name: "intercom",
    placeholder: "Домофон",
    inputType: "text"
  }
];

const Addresses: React.FC = () => {
  const { user, deviceType } = useAppSelector((state) => state.user);
  const addresses = user.accountDeliveryAddresses;
  const dispatch = useAppDispatch();
  const [showMap, setShowMap] = useState(false);
  const [selectedCityLatitude, setSelectedCityLatitude] = useState<
    number | null
  >(null);
  const [selectedCityLongitude, setSelectedCityLongitude] = useState<
    number | null
  >(null);
  const [selectedAddressLatitude, setSelectedAddressLatitude] = useState<
    number | null
  >(null);
  const [selectedAddressLongitude, setSelectedAddressLongitude] = useState<
    number | null
  >(null);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [isStreetInputFocused, setIsStreetInputFocused] =
    useState<boolean>(false);
  const [showCities, setShowCities] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [isHouseNumberEntered, setIsHouseNumberEntered] = useState(false);
  const [isAddressManuallyChanged, setIsAddressManuallyChanged] =
    useState(false);
  const previousCityRef = useRef<string | null>(null);
  const cityInputRef = useRef<HTMLInputElement | null>(null);
  const buttonSize: "extra-small" | "small" | "large" = deviceType.isMobile
    ? "extra-small"
    : deviceType.isTablet
      ? "small"
      : "large";

  const {
    searchValue,
    searchedCities,
    isLoading,
    isNoResultSearch,
    onChangeSearchValue
  } = useCitiesSearch({ limit: 5 });

  const formik = useFormik<ISchemaAddressV2>({
    initialValues: {
      addressName: "",
      city: "",
      deliveryAddress: "",
      flat: "",
      floor: "",
      entrance: "",
      intercom: ""
    },
    validationSchema: addressSchemaNew,
    onSubmit: async (values, { resetForm }) => {
      if (!isAddressSelected) {
        toast.error("Пожалуйста, выберите существующую улицу из предложенных.");
        return;
      }

      const addressParts = values.deliveryAddress.split(", ");
      if (addressParts.length < 2 || !addressParts[1]) {
        toast.error(
          "Пожалуйста, укажите номер дома через запятую после названия улицы."
        );
        return;
      }

      try {
        const addressData = await dispatch(
          addDeliveryAdress({
            name: values.addressName,
            city: values.city,
            street: addressParts[0] || "",
            house: addressParts[1] || "",
            flat: values.flat ?? "",
            floor: values.floor ?? "",
            entrance: values.entrance ?? ""
          })
        ).unwrap();

        if (!addressData) {
          return;
        }
        toast.success(`Адрес "${values.addressName}" успешно создан`);
        resetForm();
        resetMap();
        setCurrentCity(null);
      } catch (err) {
        const errorData = getErrorData(err);
        toast.error(errorData.message);
      }
    },
    enableReinitialize: true
  });

  useEffect(() => {
    if (
      previousCityRef.current &&
      previousCityRef.current !== formik.values.city
    ) {
      formik.setFieldValue("deliveryAddress", "");
    }
    previousCityRef.current = formik.values.city;
  }, [formik, formik.values.city]);

  const handleSubmitClick = useCallback(() => {
    const isFormEmpty =
      JSON.stringify(formik.values) === JSON.stringify(formik.initialValues);

    if (isFormEmpty) {
      toast.error("Для сохранения адреса необходимо заполнить данные");
    } else {
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length > 0) {
          const firstError = Object.values(errors)[0];
          toast.error(firstError);
        } else {
          formik.handleSubmit();
          setShowMap(false);
        }
      });
    }
  }, [formik, setShowMap]);

  const handleAddressSelect = (address: string) => {
    formik.setFieldValue("deliveryAddress", address);
    setShowMap(false);
    setIsAddressSelected(true);
  };

  const handleAddressClick = (address: IGetUserCity) => {
    const processedAddress = processAddress(address.value);
    formik.setFieldValue("deliveryAddress", processedAddress);
    setAddressCoordinates(address.data.geo_lat, address.data.geo_lon);
    setShowSuggestions(false);
    setIsAddressSelected(true);
    setIsHouseNumberEntered(processedAddress.split(", ").length > 1);
  };

  const debouncedAddress = useDebounce(formik.values.deliveryAddress, 1500);

  const setMapCoordinates = (
    latitude: number | null,
    longitude: number | null
  ) => {
    setSelectedCityLatitude(latitude);
    setSelectedCityLongitude(longitude);
  };

  const setAddressCoordinates = (
    latitude: number | null,
    longitude: number | null
  ) => {
    setSelectedAddressLatitude(latitude);
    setSelectedAddressLongitude(longitude);
  };

  const deliveryData: IDeliveryData = useDelivery({
    searchValue: formik.values.deliveryAddress,
    isFocusInput: isStreetInputFocused,
    city: formik.values.city
  });

  const handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    setIsStreetInputFocused(true);
    setShowSuggestions(true);
    if (!isHouseNumberEntered) {
      setIsAddressSelected(false);
    }
  };

  const handleCitySelect = (city: ICityData) => {
    formik.setFieldValue("city", city.name);
    onChangeSearchValue("");
    setMapCoordinates(city.geo_lat, city.geo_lon);
    setAddressCoordinates(null, null);
    setIsAddressManuallyChanged(false);
    setCurrentCity(city.name);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    onChangeSearchValue(e.target.value);
    setAddressCoordinates(null, null);
    setIsAddressManuallyChanged(true);
    setShowCities(true);
    clearAddressSuggestions();
  };

  const handleStreetBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(e);
    setIsStreetInputFocused(false);
    setShowSuggestions(false);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const {
    mapRef,
    placemarkRef,
    resetMap,
    addressRef,
    currentCity,
    setCurrentCity
  } = useYandexMapForAddress({
    showMap,
    onAddressSelect: handleAddressSelect,
    onClose: () => setShowMap(false),
    initialAddress: debouncedAddress || formik.values.city,
    cityLatitude: selectedCityLatitude ?? undefined,
    cityLongitude: selectedCityLongitude ?? undefined,
    addressLatitude: selectedAddressLatitude ?? undefined,
    addressLongitude: selectedAddressLongitude ?? undefined
  });

  useEffect(() => {
    if (
      currentCity &&
      formik.values.city !== currentCity &&
      !isAddressManuallyChanged
    ) {
      formik.setFieldValue("city", currentCity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCity,
    formik.values.city,
    formik.setFieldValue,
    isAddressManuallyChanged
  ]);

  useEffect(() => {
    if (!showMap) {
      resetMap();
    }
  }, [showMap, resetMap]);

  const clearAddressSuggestions = () => {
    deliveryData.fullDeliveryAddresses = [];
    setShowSuggestions(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      cityInputRef.current &&
      !cityInputRef.current.contains(event.target as Node)
    ) {
      setShowCities(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDeliveryInputClick = useCallback(() => {
    if (
      !formik.values.city ||
      !selectedCityLatitude ||
      !selectedCityLongitude
    ) {
      toast.info("Сначала укажите населённый пункт");
      return;
    }
    setShowMap(true);
  }, [formik.values.city, selectedCityLatitude, selectedCityLongitude, setShowMap]);

  return (
    <div className={styles.addresses}>
      <div className={styles.addresses__header}>
        <h2 className={styles.addresses__title}>Адреса</h2>
        <CustomButtonV2
          title="Сохранить"
          type="submit"
          onClick={handleSubmitClick}
          size={buttonSize}
          color="primary"
        />
      </div>
      <div className={styles.addresses__divider}></div>
      <form onSubmit={formik.handleSubmit} className={styles.addres}>
        <div className={styles.addres__header}>
          <h4 className={styles.addres__title}>Добавить новый адрес</h4>
        </div>
        <div className={styles.addres__inputs}>
          <CustomProfileInput
            placeholder="Введите название адреса"
            label="Название адреса"
            name="addressName"
            value={formik.values.addressName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            customWrapperStyles={styles["full-width"]}
            isRequired
          />
          <div className={cn(styles["input-wrapper"], styles["full-width"])}>
            <CustomProfileInput
              placeholder="Населённый пункт"
              label="Населённый пункт"
              name="city"
              value={formik.values.city}
              onChange={handleCityChange}
              onBlur={formik.handleBlur}
              customWrapperStyles={styles["full-width"]}
              isRequired
              ref={cityInputRef}
            />
            <CustomDropdownV2<ICityData>
              showDropdown={!!searchValue && showCities}
              isLoading={isLoading}
              noResultsText="Нет результатов"
              items={searchedCities}
              onItemSelect={handleCitySelect}
              handleMouseDown={handleMouseDown}
              isNoResultSearch={isNoResultSearch}
              processItem={(city) => (
                <>
                  <span className={styles["dropdown-item--name"]}>{city.name}</span>
                  <span className={styles["dropdown-item--region"]}>{city.reqion_with_type}</span>
                </>
              )}
            />
          </div>
          <div className={cn(styles["input-wrapper"], styles["full-width"])}>
            <CustomProfileInput
              placeholder="Введите адрес доставки"
              label="Адрес доставки"
              name="deliveryAddress"
              value={formik.values.deliveryAddress}
              onChange={handleStreetChange}
              onBlur={handleStreetBlur}
              customWrapperStyles={styles["full-width"]}
              readOnly={!formik.values.city}
              onClick={handleDeliveryInputClick}
              isRequired
            />
            <CustomDropdownV2<IGetUserCity>
              showDropdown={showSuggestions}
              isLoading={deliveryData.isLoading}
              noResultsText="Нет результатов"
              items={deliveryData.fullDeliveryAddresses}
              onItemSelect={handleAddressClick}
              handleMouseDown={handleMouseDown}
              isNoResultSearch={deliveryData.fullDeliveryAddresses.length === 0}
              processItem={(address) => (
                <span className={styles["dropdown-item--name"]}>
                  {processAddress(address.value)}
                </span>
              )}
            />
          </div>
        </div>
        <div className={styles["addres__additional-inputs"]}>
          {ADRESS_VALUES.map((input) => (
            <CustomProfileInput
              key={input.id}
              placeholder={input.placeholder}
              label={input.placeholder}
              name={input.name}
              value={formik.values[input.name] ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              customWrapperStyles={styles["half-width"]}
              maxLength={6}
            />
          ))}
        </div>
      </form>
      {showMap && (
        <div className={styles["map-container"]}>
          <div ref={mapRef} className={styles["map-container__map"]} />
          <div
            ref={placemarkRef}
            className={styles["map-container__placemark"]}
          ></div>
          <div
            ref={addressRef}
            className={styles["map-container__address"]}
            style={{ display: "none" }}
          ></div>
          <button
            className={styles["map-container__close-button"]}
            onClick={() => setShowMap(false)}
          >
            <CrossIcon color="#191B38" height={20} width={20} />
          </button>
        </div>
      )}
      {addresses.length > 0 && (
        <div className={styles.addresses__divider}></div>
      )}
      <ListExistingAddresses />
    </div>
  );
};

export default Addresses;
