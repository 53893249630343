import { nanoid } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { deliveryApi } from "../api/deliveryApi";

import { DEFAULT_COORDINATES, PROVIDERS } from "./constants";
import { getBalloon } from "./helpers/yMaps/yMapsObjectsGenerator";
import { AddAvailableProvidersOnMapParams } from "./hooks/useYandexMap";
import {
  Feature,
  FeatureCollection,
  GetPlacemarkOnMapParams,
  IEvent,
  IMap,
  Item,
  IUserPlacemark,
  ObjectManager
} from "types/IYMapTypes";
import { SelectedProviderData } from "types/IProviderInfo";
import { Coordinates } from "types/ICheckoutData";
import { citiesApi } from "api/citiesApi";
import { isError } from "store/user/userThunks/userThunks";
import { CustomError } from "utils/getErrorData";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ymaps: any;
  }
}

export interface IAddProviderCollectionToMap {
  map: IMap | null;
  collection: FeatureCollection;
  objectManager: ObjectManager | null;
}

export interface IChangePlacemartCoordinates {
  map: IMap | null;
  coordinates: Coordinates | undefined;
  userPlacemark: IUserPlacemark | null;
}

// type City = {
//   city: string
// }

export const INIT_COORDS: Coordinates = [45.03529843993246, 38.9769731798558]; // Krasnodar;
export const OFFSET = 0;
export const SCROLL_DURATION = 1000;
const PRIMARY_COLOR = "#C73C87";

export const createYMapsScript = () => {
  const script = document.createElement("script");
  script.src = `https://api-maps.yandex.ru/2.1/?apikey=${process.env.REACT_APP_YANDEX_MAPS_API_KEY}&lang=ru_RU`;
  document.head.appendChild(script);
};

export const getCityCoordinates = async (
  cityName: string
): Promise<Coordinates> => {
  try {
    // Получаем координаты города с лимитом в 1 по первому совпадению
    const response = await citiesApi.getCitiesBySearchValue({
      query: cityName,
      limit: 1
    });

    if (isError(response)) {
      throw new CustomError(response.message, response?.code);
    }

    if (response.response === null) {
      throw new CustomError("Не нашлось ни одного города по совпадению.");
    }

    const firstCityMatch = response.response.items[0];

    if (!firstCityMatch) {
      throw new CustomError(
        `Совпадений по выбранному населённому пункту не нашлось: ${cityName}`
      );
    }

    // Первым в массиве координат идет lat вторым lon
    if (typeof firstCityMatch.geo_lon !== "number" || typeof firstCityMatch.geo_lat !== "number") {
      throw new CustomError(
        `Нет координат по запросу населённого пункта: ${cityName}`
      );
    }

    const coordinates: Coordinates = [
      firstCityMatch.geo_lat,
      firstCityMatch.geo_lon
    ];

    return coordinates;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Ошибка в  getCityCoordinates:", error);
    toast(`Не удалось получить координаты выбранного города ${cityName}`);
    return INIT_COORDS;
  }
};

export const setMapCenter = ({
  map,
  coordinates
}: {
  map: IMap;
  coordinates: Coordinates | undefined;
}) => {
  if (!coordinates) {
    // eslint-disable-next-line no-console
    console.error("При попытке получиться центр карты по координатам, координат не было найдено");
    return;
  }
  let pixelCenter = map.getGlobalPixelCenter(coordinates);

  pixelCenter = [pixelCenter[0] - OFFSET, pixelCenter[1]];
  const geoCenter = map.options
    .get("projection")
    .fromGlobalPixels(pixelCenter, map.getZoom());
  map.setCenter(geoCenter);
};

export const getPlacemarkOnMap = ({
  map,
  coordinates = DEFAULT_COORDINATES,
  cb,
  isDraggable
}: GetPlacemarkOnMapParams) => {
  const placemark = new window.ymaps.Placemark(
    coordinates,
    {},
    { draggable: isDraggable, iconColor: PRIMARY_COLOR }
  );

  placemark.events.add("dragend", function (e: IEvent) {
    const coords = e.get("target").geometry.getCoordinates();
    cb?.(coords);
  });
  map.geoObjects.add(placemark);
  return placemark;
};

export const getUpdateCoordinatesByOffset = ({
  map,
  coordinates
}: {
  map: IMap;
  coordinates: Coordinates;
}): Coordinates => {
  const pixelOffset = map.options
    .get("projection")
    .toGlobalPixels(coordinates, map.getZoom());
  const updatedPixelOffset = [pixelOffset[0] - OFFSET, pixelOffset[1]];
  const updatedCoordinates = map.options
    .get("projection")
    .fromGlobalPixels(updatedPixelOffset, map.getZoom());
  return updatedCoordinates;
};

export const scrollToPlacemark = async ({
  map,
  coordinates
}: {
  map: IMap;
  coordinates: Coordinates;
}): Promise<void> => {
  try {
    await map.panTo(coordinates, {
      flying: false,
      duration: 1200
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Произошла ошибка при перемещении карты:", error);
    throw error;
  }
};

export const getCityBySearchAddress = async (
  address: string
): Promise<string[]> => {
  try {
    const result = await window.ymaps.geocode(address);
    const firstGeoObject = result.geoObjects.get(0);

    if (!firstGeoObject) {
      throw new Error("Не найден объект по запросу");
    }

    const city = firstGeoObject.getLocalities().length
      ? firstGeoObject.getLocalities()
      : firstGeoObject.getAdministrativeAreas();
    return city;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Произошла ошибка при поиске города:", err);
    throw err;
  }
};

export const getProviderCollection = (items: Item[]): FeatureCollection => {
  const features: Feature[] = [];

  for (let i = 0; i < items.length; i++) {
    const params = {
      address: items[i].address,
      deliveryProvider: PROVIDERS[items[i].deliveryProvider].title,
      deliveryProviderType: items[i].deliveryProvider,
      description: items[i].description ?? "Описание отсутствует",
      paymentCard: items[i].paymentCard ?? false,
      paymentCash: items[i].paymentCash,
      phone: items[i].phone,
      timeTable: items[i].timeTable,
      mySecretCustomId: nanoid()
    };

    const { header, body, footer } = getBalloon(params);
    features.push({
      type: "Feature",
      id: nanoid(),
      geometry: {
        type: "Point",
        coordinates: [items[i].lat, items[i].lng],
        providerType: PROVIDERS[items[i].deliveryProvider].type,
        params
      },
      properties: {
        balloonContentHeader: header,
        balloonContentBody: body,
        balloonContentFooter: footer,
        hintContent: `${PROVIDERS[items[i].deliveryProvider].title}`
      },
      options: {
        iconLayout: "default#image",
        iconImageSize: [34, 41]
      }
    });
  }

  return {
    type: "FeatureCollection",
    features
  };
};

export const addProviderCollectionToMap = ({
  map,
  collection,
  objectManager
}: IAddProviderCollectionToMap) => {
  if (!objectManager || !map) {
    return;
  }
  objectManager.add(collection);
  map.geoObjects.add(objectManager);
};

export const changePlacemartCoordinates = ({
  map,
  coordinates = DEFAULT_COORDINATES,
  userPlacemark
}: IChangePlacemartCoordinates): void => {
  try {
    if (!userPlacemark) {
      throw new Error("Отсутствует геоположение пользователя");
    }

    if (!map) {
      throw new Error("Карта не загружена");
    }

    userPlacemark.geometry.setCoordinates(coordinates);
    const updatedCoordinates: Coordinates = getUpdateCoordinatesByOffset({
      map,
      coordinates
    });

    scrollToPlacemark({ map, coordinates: updatedCoordinates })
      .then(() => {
        return;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Ошибка при перемещении карты:", error);
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Ошибка при изменении координат:", error);
  }
};

export const addAvailableProvidersOnMap = ({
  city,
  availableProviders,
  setAvailableProviders,
  setFeatures,
}: AddAvailableProvidersOnMapParams): void => {
  availableProviders.forEach(async (provider: string) => {
    const data = await deliveryApi.getProvidersList({
      city,
      availableOperations: [2, 3],
      providers: [provider]
    });

    if ("message" in data) {
      return toast(data.message);
    }

    if (data.response === null) {
      return toast("Доступных провайдеров не было найдено");
    }

    const providerResponse = Object.entries(data.response);

    if (Array.isArray(providerResponse[0][1])) {
      const collection = getProviderCollection(providerResponse[0][1]);
      setFeatures((prev: Feature[]) => [...prev, ...collection.features]);
    }

    const providerData = {
      type: provider,
      addresses: providerResponse[0][1] as SelectedProviderData[],
      selected: true
    };

    setAvailableProviders?.(providerData);
  });
};
