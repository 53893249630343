import { useMemo } from "react";
import usePayment from "../../../../../../../utils/hooks/usePayment";

import styles from "./styles.module.scss";
import { ICreateNewOrderAuthorizedUsersResponse } from "types/ICreateNewOrderAuthorizedUsers";
import { ICreateNewOrderNonAuthorizedUsersResponse } from "types/ICreateNewOrderNonAuthorizedUsers";
import { useAppSelector } from "store/reduxHooks";
import { toast } from "react-toastify";

interface IPayOrderInformationBlockProps {
  orderData:
    | ICreateNewOrderAuthorizedUsersResponse
    | ICreateNewOrderNonAuthorizedUsersResponse;
  onOrderCreated: () => void;
}

const PayOrderInformationBlock: React.FC<IPayOrderInformationBlockProps> = (
  props
) => {
  const { orderData, onOrderCreated } = props;
  const { checkoutData } = useAppSelector((state) => state.user);
  const { confirmPayment } = usePayment({ orderData, onOrderCreated });

  const option: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "numeric",
    year: "numeric"
  };

  Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const dateOrder = new Date(orderData.created_at);

  const recipientName = useMemo(() => {
    const firstName = orderData.orderRecipient?.name;
    return firstName;
  }, [orderData.orderRecipient]);

  const showPaymentButton = useMemo(() => {
    const paymentType = checkoutData.paymentMethod?.type;
    if (!paymentType) {
      return toast("Способ оплаты не указан");
    }
    const allowedPaymentMethodsTypes = ["cash", "yookassa", "dolymi"];
    const shouldShowPaymentButton =
      allowedPaymentMethodsTypes.includes(paymentType);
    return shouldShowPaymentButton;
  }, [checkoutData.paymentMethod]);

  return (
    <div className={styles["order-info"]}>
      <div className={styles["order-info__title"]}>Информация о заказе</div>
      <div className={styles["order-info__order-date"]}>
        Заказ №<span className={styles.primary}> {orderData.id} </span>
        от {dateOrder.toLocaleString("ru-RU", option)}
      </div>
      {showPaymentButton ? (
        <button
          className={styles["order-info__payment-button"]}
          onClick={confirmPayment}
        >
          перейти к оплате
        </button>
      ) : (
        <span className={styles["empty-block"]} />
      )}
      <span className={styles["order-info__text"]}>
        {`Спасибо, ${recipientName}! Ваш заказ получен!`}
      </span>
      <p className={styles["order-info__text"]}>
        По указанному номеру в ближайшее время с Вами свяжется первый
        освободившийся менеджер. Обращаем Ваше внимание, что звонок поступит в
        рабочие часы клиентского отдела{" "}
        <a
          className={styles.primary}
          href="https://parfumart.ru"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Parfumart.ru{" "}
        </a>
        Заказ считается принятым только после его подтверждения менеджером.
      </p>
    </div>
  );
};

export default PayOrderInformationBlock;
