import { useMemo } from "react";
import { Link } from "react-router-dom";

import cartIcon from "../../assets/header_icons/cart-icon.svg";
import searchIcon from "../../assets/header_icons/search-icon.svg";
import parfumartLogo from "../../assets/parfumart-logo.svg";
import MobileNavigation from "../MobileNavigation/MobileNavigation";
import { formatPrice } from "utils/helpers/formatedPrice";
import { useAppSelector } from "store/reduxHooks";
import styles from "./styles.module.scss";

const MobileStickyHeader: React.FC = () => {
  const { cart } = useAppSelector((state) => state.user);

  const price = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.calculated_amount) {
        acc += cur.calculated_amount;
      }
      return acc;
    }, 0);
  }, [cart]);

  const productAmount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      acc += cur.count;
      return acc;
    }, 0);
  }, [cart]);

  return (
    <header className={styles.root}>
      <MobileNavigation />
      <img src={searchIcon} alt="search-icon" className={styles["search-icon"]} />
      <Link to="/" className={styles["main-link"]}>
        <img src={parfumartLogo} alt="parfumart_logo" className={styles["main-link__logo"]} />
      </Link>
      <div className={styles.cart}>
        <Link to="cart" className={styles.cart__link}>
          <div className={styles.cart__icon_wrapper}>
            <img src={cartIcon} alt="cart-icon" className={styles.cart__icon} />
            <span className={styles["cart__products-amount"]}>{productAmount}</span>
          </div>
          <span className={styles["cart__products-price"]}>{`${formatPrice(price)} ₽`}</span>
        </Link>
      </div>
    </header>
  );
};

export default MobileStickyHeader;
