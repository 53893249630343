import React, { useState } from "react";
import { toast } from "react-toastify";
import { removeDeliveryAdress } from "store/user/userThunks/userThunks";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import CustomButtonV2 from "components/UI/CustomButtonV2/CustomButtonV2";
import CustomProfileInput from "components/UI/CustomProfileInput/CustomProfileInput";
import DeleteAdressModal from "../DeleteAdressModal/DeleteAdressModal";
import styles from "./styles.module.scss";

const ListExistingAddresses: React.FC = () => {
  const { user, deviceType } = useAppSelector((state) => state.user);
  const addresses = user.accountDeliveryAddresses;
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState<number | null>(null);

  const handleDeleteClick = (id: number) => {
    setAddressToDelete(id);
    setShowDeleteModal(true);
  };

  const onDeleteAddress = async () => {
    if (addressToDelete !== null) {
      const address = addresses.find((addr) => addr.id === addressToDelete);
      await dispatch(removeDeliveryAdress({ addressId: addressToDelete }));
      setShowDeleteModal(false);
      setAddressToDelete(null);
      toast.success(`Адрес "${address?.name}" успешно удален`);
    }
  };

  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setAddressToDelete(null);
  };

  return (
    <>
      {addresses.map((address, index) => (
        <React.Fragment key={address.id}>
          <form className={styles.addres}>
            <div className={styles.addres__header}>
              <h4 className={styles.addres__title}>{address.name}</h4>
              <CustomButtonV2
                title={deviceType.isMobile ? "" : "Удалить адрес"}
                type="button"
                onClick={() => handleDeleteClick(address.id)}
                size="small"
                color="danger"
                iconLeft="IconTrash"
                iconLeftProps={{ width: 17, height: 17, color: "#FF0044" }}
              />
            </div>
            <div className={styles.addres__inputs}>
              <CustomProfileInput
                name="city"
                value={address.city}
                disabled
                customWrapperStyles={styles["full-width"]}
              />
              <CustomProfileInput
                name="deliveryAddress"
                value={`${address.street}, ${address.house}, ${address.flat ? `кв. ${address.flat}, ` : ""}${address.floor ? `эт. ${address.floor}, ` : ""}${address.entrance ? `под. ${address.entrance}` : ""}`}
                disabled
                customWrapperStyles={styles["full-width"]}
              />
            </div>
          </form>
          {index < addresses.length - 1 && (
            <div className={styles.addresses__divider}></div>
          )}
        </React.Fragment>
      ))}
      {showDeleteModal && (
        <DeleteAdressModal
          onClose={onCloseDeleteModal}
          onDelete={onDeleteAddress}
        />
      )}
    </>
  );
};

export default ListExistingAddresses;