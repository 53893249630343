import * as yup from "yup";

export interface ISchemaPersonalData {
  id?: string;
  name: string;
  surname: string;
  phone: string;
  email?: string;
  dob?: Date | null;
}

const nameRegExp = /^[a-zA-Z, а-яёА-ЯЁ,.'-]+$/i;
const phoneRegExp =
  // eslint-disable-next-line no-useless-escape
  /^\+?[7 ]?[-\(\s]?\d{3}[-\)\s]?\d{3}[-\s]?\d{2}[-\s]?\d{2}$/;

export const personalDataSchema: yup.SchemaOf<ISchemaPersonalData> = yup
  .object()
  .shape({
    id: yup.string(),
    name: yup
      .string()
      .matches(nameRegExp, "Имя не является допустимым")
      .required("Имя является обязательным полем"),
    surname: yup
      .string()
      .matches(nameRegExp, "Фамилия не является допустимой")
      .required("Фамилия является обязательным полем"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Номер телефона не является допустимым")
      .required("Номер телефона является обязательным полем"),
    email: yup.string().email("Электронная почта не корректна"),
    dob: yup.date().nullable().typeError("Дата рождения не корректна")
  });
