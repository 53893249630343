interface AvatarProfileIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const AvatarProfileIcon: React.FC<AvatarProfileIconProps> = ({ width = 56, height = 70, color = "#C1C4D6" }) => (
  <svg width={width} height={height} viewBox="0 0 56 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.9891 45.8466C13.253 45.8466 0.666016 48.1699 0.666016 57.4632C0.666016 66.76 13.1744 69.1653 27.9891 69.1653C42.7252 69.1653 55.3122 66.8454 55.3122 57.5486C55.3122 48.2519 42.8072 45.8466 27.9891 45.8466Z" fill={color}/>
    <path d="M27.9892 36.9943C38.0274 36.9943 46.0702 28.9481 46.0702 18.9133C46.0702 8.87856 38.0274 0.832306 27.9892 0.832306C17.9545 0.832306 9.9082 8.87856 9.9082 18.9133C9.9082 28.9481 17.9545 36.9943 27.9892 36.9943Z" fill={color}/>
  </svg>
);

export default AvatarProfileIcon;