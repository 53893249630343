
import { useAppDispatch } from "store/reduxHooks";
import FavoritesPreview from "./components/FavoritesPreview/FavoritesPreview";
import OrdersPreview from "./components/OrdersPreview/OrdersPreview";
import styles from "./styles.module.scss";
import PersonalDataPreview from "./components/PersonalDataPreview/PersonalDataPreview";
import { useEffect } from "react";
import { getUserFavoriteProducts, getUserOrdersThunk } from "store/user/userThunks/userThunks";

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserFavoriteProducts());
    dispatch(getUserOrdersThunk({}));
  }, [dispatch]);

  return (
    <main className={styles.root}>
      <h2 className={styles.title}>Личный кабинет</h2>
      <div className={styles.container}>
        <div className={styles["top-container"]}>
          <div className={styles["favorites-preview"]}>
            <FavoritesPreview />
          </div>
          <div className={styles["orders-preview"]}>
            <OrdersPreview />
          </div>
        </div>
        <PersonalDataPreview />
      </div>
    </main>
  );
};

export default Profile;