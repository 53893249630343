import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getUserOrdersThunk } from "store/user/userThunks/userThunks";
import OrderItemV2 from "./components/OrderItemV2/OrderItemV2";
import CustomButtonV2 from "components/UI/CustomButtonV2/CustomButtonV2";
import Pagination from "components/Pagination/Pagination";
import LoadingSpinnerCustom from "components/UI/LoadingSpinnerCustom/LoadingSpinnerCustom";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import { getErrorData } from "utils/getErrorData";
import CustomLinkV2 from "components/UI/CustomLinkV2/CustomLinkV2";

import styles from "./styles.module.scss";

const DEFAULT_LIMIT = 5;
const SCROLL_OFFSET_TOP = 100;
const CATALOG_LINK = "/catalog";

const MyOrders: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    orders,
    isOrdersLoading,
    currentPage: storedCurrentPage,
    ordersOffset: storedOrdersOffset
  } = useAppSelector((state) => state.user.orders);
  const [ordersAmount, setOrdersAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(storedCurrentPage || 1);
  const [ordersOffset, setOrdersOffset] = useState(storedOrdersOffset || 0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const isShowMoreAction = useRef<boolean>(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const onScrollContentToTop = useCallback(() => {
    if (contentRef.current) {
      const contentOffsetTop = contentRef.current.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - SCROLL_OFFSET_TOP,
        behavior: "smooth"
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const options = {
          limit: DEFAULT_LIMIT,
          offset: ordersOffset,
          isShowMore: isShowMoreAction.current,
          currentPage
        };
        const action = await dispatch(getUserOrdersThunk(options));
        if (getUserOrdersThunk.fulfilled.match(action)) {
          setOrdersAmount(action.payload.meta.total);
        }
      } catch (err) {
        const errorData = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error("Ошибка при получении заказов", errorData.message);
      } finally {
        isShowMoreAction.current = false;
        setIsLoadingMore(false);
      }
    })();
  }, [dispatch, ordersOffset, currentPage]);

  const handleShowMoreOrders = () => {
    const newOffset = ordersOffset + DEFAULT_LIMIT;
    if (ordersAmount < newOffset) return;
    isShowMoreAction.current = true;
    setOrdersOffset(newOffset);
    setCurrentPage((prevPage) => prevPage + 1);
    setIsLoadingMore(true);
  };

  const handlePageChange = (page: number | string) => {
    const offsetValue = Number(page) * DEFAULT_LIMIT - DEFAULT_LIMIT;
    setCurrentPage(Number(page));
    setOrdersOffset(offsetValue);
    isShowMoreAction.current = false;
    onScrollContentToTop();
  };

  const shouldRenderShowMoreButton = useMemo(() => {
    const isProductsEnough = (orders?.length ?? 0) >= DEFAULT_LIMIT;
    const isLastPage = Math.ceil(ordersAmount / DEFAULT_LIMIT) === currentPage;
    return isProductsEnough && !isLastPage;
  }, [orders, ordersAmount, currentPage]);

  return (
    <ProfileWrapper title="Мои заказы" ref={contentRef}>
      {isOrdersLoading && !isLoadingMore ? (
        <div className={styles.content__spinner}>
          <LoadingSpinnerCustom color="dark" variant="alternative" />
        </div>
      ) : (orders?.length ?? 0) === 0 ? (
        <div className={styles.empty}>
          <p className={styles.empty__text}>У вас пока нет заказов</p>
          <CustomLinkV2
            to={CATALOG_LINK}
            title="Перейти в каталог"
            size="large"
            color="secondary"
            iconRight="Arrow"
            iconRightProps={{
              direction: "right",
              width: 15,
              height: 15
            }}
          />
        </div>
      ) : (
        <>
          <ul className={styles["orders-list"]}>
            {orders.map((order) => (
              <OrderItemV2 key={order.id} order={order} />
            ))}
          </ul>
          {!!orders.length && (
            <div className={styles["show-block"]}>
              {shouldRenderShowMoreButton && (
                <CustomButtonV2
                  title="Показать еще"
                  onClick={handleShowMoreOrders}
                  disabled={isLoadingMore}
                  color="primary"
                  size="small"
                  type="button"
                />
              )}
              {isLoadingMore && (
                <div className={styles.content__spinner}>
                  <LoadingSpinnerCustom color="dark" variant="alternative" />
                </div>
              )}
              <Pagination
                totalCount={ordersAmount}
                currentPage={currentPage}
                pageSize={DEFAULT_LIMIT}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </>
      )}
    </ProfileWrapper>
  );
};

export default MyOrders;
