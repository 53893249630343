import Arrow from "assets/profile/icons/Arrow";
import BellIcon from "assets/profile/icons/BellIcon";
import BuildingIcon from "assets/profile/icons/BuildingIcon";
import CalendarIcon from "assets/profile/icons/CalendarIcon";
import Chevron from "assets/profile/icons/Chevron";
import CrossIcon from "assets/profile/icons/CrossIcon";
import DangerCircleIcon from "assets/profile/icons/DangerCircleIcon";
import EditIcon from "assets/profile/icons/EditIcon";
import HeartIcon from "assets/profile/icons/HeartIcon";
import LogOutIcon from "assets/profile/icons/LogOutIcon";
import IconTrash from "assets/profile/icons/IconTrash";
import MailIcon from "assets/profile/icons/MailIcon";
import MarkerIcon from "assets/profile/icons/MarkerIcon";
import PhoneIcon from "assets/profile/icons/PhoneIcon";
import PlusIcon from "assets/profile/icons/PlusIcon";
import RedBoxIcon from "assets/profile/icons/RedBoxIcon";
import RouteIcon from "assets/profile/icons/RouteIcon";
import SearchIcon from "assets/profile/icons/SearchIcon";
import ShoppingBagIcon from "assets/profile/icons/ShoppingBagIcon";
import UserIcon from "assets/profile/icons/UserIcon";
import React from "react";

interface BaseIconProps {
  width?: number;
  height?: number;
  color?: string;
}

interface ArrowProps extends BaseIconProps {
  direction: "left" | "right";
}

interface ChevronProps extends BaseIconProps {
  direction: "up" | "down" | "left" | "right";
}

interface HeartIconProps extends BaseIconProps {
  color: "red" | "gray" | "white";
}

export type IconProps = BaseIconProps | ArrowProps | ChevronProps | HeartIconProps;

export type IconType =
  | "BellIcon"
  | "BuildingIcon"
  | "CalendarIcon"
  | "CrossIcon"
  | "DangerCircleIcon"
  | "EditIcon"
  | "MailIcon"
  | "MarkerIcon"
  | "PhoneIcon"
  | "PlusIcon"
  | "RedBoxIcon"
  | "RouteIcon"
  | "SearchIcon"
  | "ShoppingBagIcon"
  | "UserIcon"
  | "Arrow"
  | "Chevron"
  | "HeartIcon"
  | "LogOutIcon"
  | "IconTrash";

type IconsType = {
  BellIcon: React.FC<BaseIconProps>;
  BuildingIcon: React.FC<BaseIconProps>;
  CalendarIcon: React.FC<BaseIconProps>;
  CrossIcon: React.FC<BaseIconProps>;
  DangerCircleIcon: React.FC<BaseIconProps>;
  EditIcon: React.FC<BaseIconProps>;
  MailIcon: React.FC<BaseIconProps>;
  MarkerIcon: React.FC<BaseIconProps>;
  PhoneIcon: React.FC<BaseIconProps>;
  PlusIcon: React.FC<BaseIconProps>;
  RedBoxIcon: React.FC<BaseIconProps>;
  RouteIcon: React.FC<BaseIconProps>;
  SearchIcon: React.FC<BaseIconProps>;
  ShoppingBagIcon: React.FC<BaseIconProps>;
  UserIcon: React.FC<BaseIconProps>;
  LogOutIcon: React.FC<BaseIconProps>;
  IconTrash: React.FC<BaseIconProps>;
};

const icons: IconsType = {
  BellIcon: BellIcon,
  BuildingIcon: BuildingIcon,
  CalendarIcon: CalendarIcon,
  CrossIcon: CrossIcon,
  DangerCircleIcon: DangerCircleIcon,
  EditIcon: EditIcon,
  MailIcon: MailIcon,
  MarkerIcon: MarkerIcon,
  PhoneIcon: PhoneIcon,
  PlusIcon: PlusIcon,
  RedBoxIcon: RedBoxIcon,
  RouteIcon: RouteIcon,
  SearchIcon: SearchIcon,
  ShoppingBagIcon: ShoppingBagIcon,
  UserIcon: UserIcon,
  LogOutIcon: LogOutIcon,
  IconTrash: IconTrash
};

const ArrowIcon = Arrow;
const ChevronIcon = Chevron;
const HeartIconComponent = HeartIcon;

const getIcon = (
  name: keyof IconsType | "Arrow" | "Chevron" | "HeartIcon",
  props: BaseIconProps | ArrowProps | ChevronProps | HeartIconProps
): React.ReactNode | null => {
  if (name in icons) {
    const IconComponent = icons[name as keyof IconsType];
    return React.createElement(IconComponent, props as BaseIconProps);
  }

  switch (name) {
  case "Arrow":
    return React.createElement(ArrowIcon, props as ArrowProps);
  case "Chevron":
    return React.createElement(ChevronIcon, props as ChevronProps);
  case "HeartIcon":
    return React.createElement(HeartIconComponent, props as HeartIconProps);
  default:
    // eslint-disable-next-line no-console
    console.warn(`Иконки с "${name}" несуществует.`);
    return null;
  }
};

export type { BaseIconProps, ArrowProps, ChevronProps, HeartIconProps };
export { getIcon };