import { memo, useCallback, useMemo, useState } from "react";
import { IProfiles } from "types/IUser";
import CustomLinkV2 from "components/UI/CustomLinkV2/CustomLinkV2";
import { ProfileRoutesList } from "utils/constants/routes";
import AvatarProfileIcon from "assets/profile/icons/AvatarProfileIcon";
import { SOCIAL_MEDIA } from "utils/constants/authSocialMedia";
import { formatProfileData } from "utils/helpers/formatProfileData";
import CustomButtonV2 from "components/UI/CustomButtonV2/CustomButtonV2";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { useNavigate } from "react-router-dom";
import { clearValueFromStorage, removeTokens, setValueInStorage } from "utils/localStorage";
import { removeUser } from "store/user/user";
import { getUserCart } from "store/user/cartThunks/cartThunks";
import cn from "classnames";
import styles from "./styles.module.scss";
import ExitModal from "layouts/AdditionalHeader/ExitModal/ExitModal";
import BottomSheet from "components/UI/Bottomsheet/Bottomsheet";
import ExitFromPersonalAreaContent from "components/ExitFromPersonalAreaContent/ExitFromPersonalAreaContent";

const PersonalDataPreview: React.FC = memo(() => {
  const { deviceType, user } = useAppSelector((state) => ({
    user: state.user.user,
    deviceType: state.user.deviceType
  }));
  const [isShowExitModal, setIsShowExitModal] = useState(false);
  const [isShowExitBS, setIsShowExitBS] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { city, address, birthday, gender, phone } = useMemo(
    () => formatProfileData(user),
    [user]
  );

  const handleOpenExit = () => {
    if (deviceType.isMobile) {
      setIsShowExitBS(true);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsShowExitModal(true);
    }
  };

  const performExit = useCallback(() => {
    removeTokens();
    dispatch(removeUser());
    clearValueFromStorage("user_city");
    setValueInStorage({ key: "isLoggedOut", value: "true" });
    navigate("/", { replace: true });
    dispatch(getUserCart({}));
  }, [dispatch, navigate]);

  const onCloseExitModal = ({ isExit }: { isExit: boolean }) => {
    if (isExit) {
      performExit();
      setIsShowExitModal(false);
      return;
    }
    setIsShowExitModal(false);
  };

  const handleExit = ({ isExit }: { isExit: boolean }) => {
    if (isExit) {
      performExit();
    }
    setIsShowExitBS(false);
  };

  const onCloseExitBottomsheet = () => {
    setIsShowExitBS(false);
  };

  const linkedSocialMedia = useMemo(() => {
    return user.profiles
      .filter((profile): profile is IProfiles & { service: string } => {
        return typeof profile.service === "string" && profile.service !== null;
      })
      .map((profile) => profile.service);
  }, [user.profiles]);

  const avatarSizes = useMemo(() => {
    const smallSize = { width: 27, height: 34 };
    const largeSize = { width: 54, height: 68 };

    if (deviceType.isMobile || deviceType.isTablet) {
      return smallSize;
    }
    return largeSize;
  }, [deviceType]);

  const linkSize = deviceType.isTablet || deviceType.isMobile ? "small" : "large";

  return (
    <div className={styles["personal-data"]}>
      <div className={styles.header}>
        <h2 className={styles.header__title}>Личные данные</h2>
        <CustomLinkV2
          to={ProfileRoutesList.PERSONAL_DATA}
          title="Изменить"
          size={linkSize}
          color="secondary"
          iconLeft="EditIcon"
          iconLeftProps={{ width: 15, height: 15 }}
          className={styles["custom-link"]}
        />
      </div>
      <div className={styles.divider}></div>
      <div className={styles["section-profile"]}>
        <div className={styles.info}>
          <div className={styles["info__avatar-wrapper"]}>
            <AvatarProfileIcon width={avatarSizes.width} height={avatarSizes.height} />
          </div>
          <div className={styles.info__details}>
            <p className={styles.info__name}>
              {user.name} {user.surname}
            </p>
            {user.email && <p className={styles.info__email}>{user.email}</p>}
            <p className={styles.info__phone}>{phone}</p>
          </div>
        </div>
        <CustomButtonV2
          title="Выйти"
          size={linkSize}
          color="danger"
          iconLeft="LogOutIcon"
          iconLeftProps={{ width: 15, height: 15 }}
          onClick={handleOpenExit}
        />
      </div>
      <div className={styles.divider}></div>
      <div className={styles["section-info"]}>
        <div className={styles.column}>
          <p className={styles.column__label}>Дата рождения</p>
          <p className={styles.column__value}>{birthday}</p>
          <p className={styles.column__label}>Населённый пункт</p>
          <p className={styles.column__value}>{city}</p>
        </div>
        <div className={styles.column}>
          <p className={styles.column__label}>Пол</p>
          <p className={styles.column__value}>{gender}</p>
          <p className={styles.column__label}>Адрес доставки</p>
          <p className={styles.column__value}>{address}</p>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles["section-info"]}>
        <div className={styles.column}>
          <h3 className={styles["social-media__title"]}>
            Привязка к социальным сетям
          </h3>
          <div className={styles["social-media__items"]}>
            {SOCIAL_MEDIA.map((item) => {
              const isLinked = linkedSocialMedia.includes(item.service);
              return (
                <div key={item.id} className={styles["social-media__item"]}>
                  <div className={styles["social-media__icon-wrapper"]}>
                    <img
                      src={item.img}
                      alt={item.alt}
                      className={cn({
                        [styles["social-media__icon"]]: isLinked,
                        [styles["social-media__icon--bw"]]: !isLinked
                      })}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.column}>
          <h3 className={styles["password__title"]}>Пароль</h3>
          <div className={styles["password__dots"]}>
            {Array.from({ length: 10 }).map((_, index) => (
              <div key={index} className={styles["password__dot"]}></div>
            ))}
          </div>
        </div>
      </div>
      {isShowExitModal && (
        <ExitModal onClose={onCloseExitModal} />
      )}
      {isShowExitBS && (
        <BottomSheet isOpen={isShowExitBS} onClose={onCloseExitBottomsheet} hideBackground>
          <ExitFromPersonalAreaContent onClose={handleExit} />
        </BottomSheet>
      )}
    </div>
  );
});

export default PersonalDataPreview;

PersonalDataPreview.displayName = "PersonalDataPreview";
