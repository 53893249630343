import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useFormik } from "formik";
import { changePasswordSchema } from "schemas/changePasswordSchema";
import { userApi } from "api/userApi";
import { isError } from "store/user/userThunks/userThunks";
import { CustomError, getErrorData } from "utils/getErrorData";
import { toast } from "react-toastify";
import CustomProfileInput from "components/UI/CustomProfileInput/CustomProfileInput";
import CustomButtonV2 from "components/UI/CustomButtonV2/CustomButtonV2";

interface IPasswordFormData {
  [key: string]: string;
}

interface IPasswordValue {
  id: number;
  title: string;
  name: string;
  inputType: "password";
  autoComplete: "current-password" | "new-password";
}

const PASSWORD_VALUES: IPasswordValue[] = [
  {
    id: 0,
    title: "Введите старый пароль",
    name: "oldPassword",
    inputType: "password",
    autoComplete: "current-password"
  },
  {
    id: 1,
    title: "Введите новый пароль",
    name: "newPassword",
    inputType: "password",
    autoComplete: "new-password"
  }
];

const ChangingPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorsOnSubmit, setShowErrorsOnSubmit] = useState(false);

  const formik = useFormik<IPasswordFormData>({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      username: ""
    },
    validationSchema: changePasswordSchema,
    onSubmit: async () => {
      try {
        setIsLoading(true);
        const res = await userApi.changeUserPassword({
          old_password: formik.values.oldPassword,
          new_password: formik.values.newPassword
        });

        if (isError(res)) {
          throw new CustomError(res.message, res?.code);
        }

        toast.success(res.response);
      } catch (err) {
        const errorData = getErrorData(err);
        toast.error(errorData.message);
      } finally {
        setIsLoading(false);
        formik.resetForm();
      }
    }
  });

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowErrorsOnSubmit(true);
    formik.handleSubmit();
  };

  return (
    <div className={styles.password}>
      <h2 className={styles.password__title}>Изменение пароля</h2>
      <form className={styles.password__form} onSubmit={onSubmitHandler}>
        <input
          type="text"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          style={{ display: "none" }}
          autoComplete="username"
        />
        {PASSWORD_VALUES.map((item) => (
          <CustomProfileInput
            key={item.id}
            name={item.name}
            placeholder={item.title}
            value={formik.values[item.name]}
            onChange={formik.handleChange}
            isError={
              !!formik.errors[item.name] &&
              (formik.touched[item.name] || showErrorsOnSubmit)
            }
            helpText={
              formik.errors[item.name] &&
              (formik.touched[item.name] || showErrorsOnSubmit)
                ? formik.errors[item.name]
                : ""
            }
            inputType={item.inputType}
            autoComplete={item.autoComplete}
          />
        ))}
        <CustomButtonV2
          title="Сохранить"
          type="submit"
          color="secondary"
          loading={isLoading}
          customStyles={{ width: "100%" }}
        />
      </form>
    </div>
  );
};

export default ChangingPassword;
