import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getUserCart,
  IAddPublicCartData
} from "../../../../store/user/cartThunks/cartThunks";
import {
  getUserToken,
  setValueInStorage
} from "../../../../utils/localStorage";
import {
  setCheckoutItems,
  setPromocode,
} from "../../../../store/user/user";

import OrderStringItem from "../../../../components/UI/OrderStringItem/OrderStringItem";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import PromocodeInput from "../../../../components/UI/PromocodeInput/PromocodeInput";
import PromocodeModal from "../PromocodeModal/PromocodeModal";

import { formatPrice } from "utils/helpers/formatedPrice";
import { IGetUserCartRequest } from "types/IGetUserCart";
import { isError } from "store/user/userThunks/userThunks";
import { CustomError, getErrorData } from "utils/getErrorData";
import { arraysEqual } from "utils/helpers/arraysEqual";
import { recalculateDeliveryCost } from "utils/recalculateDeliveryCost";

import styles from "./styles.module.scss";

const OrderData: React.FC = () => {
  const { user, cart, checkoutData } = useAppSelector(
    (state) => state.user
  );
  const [inputValue, setInputValue] = useState(checkoutData.promoCode);
  const [showModal, setShowModal] = useState(false);
  const [isPromocodeSelected, setIsPromocodeSelected] = useState(
    !!checkoutData.promoCode
  );
  const [notValidCodeMessage, setNotValidCodeMessage] = useState("");
  const token = getUserToken();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const modalRef = useRef(null);
  const previousSelectedProductIds = useRef<number[]>([]);

  const productsAmount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      acc = acc + cur.count;
      return acc;
    }, 0);
  }, [cart]);

  const selectedProductsAmount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.isSelected) {
        acc = acc + cur.count;
      }

      return acc;
    }, 0);
  }, [cart]);

  // Старая цена товара - та что большая без скидок
  const productsOldPrice = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.isSelected && cur.offer.oldPrice) {
        acc += cur.offer.oldPrice * cur.count;
      }

      return acc;
    }, 0);
  }, [cart]);

  const productsMainPrice = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.isSelected && cur.calculated_amount) {
        acc += cur.calculated_amount;
      }

      return acc;
    }, 0);
  }, [cart]);

  const productsShopDiscount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.isSelected && cur.calculated_price) {
        acc +=
          cur.offer.oldPrice * cur.count - cur.calculated_price * cur.count;
      }

      return acc;
    }, 0);
  }, [cart]);

  const productsPromoDiscount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.used_promo_code && cur.isSelected && cur.discount_by_promo_code) {
        acc += cur.discount_by_promo_code;
      }

      return acc;
    }, 0);
  }, [cart]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onSendPromocode = async () => {
    if (!user.phone) {
      setShowModal(true);
      return;
    }

    if (!inputValue || !cart.length) {
      return;
    }

    try {
      const selectedProductIds = cart.reduce<number[]>((acc, cur) => {
        if (cur.isSelected) {
          acc.push(cur.offer.id);
        }
        return acc;
      }, []);

      if (!selectedProductIds.length) {
        setNotValidCodeMessage(
          "Выберите хотя бы один товар для применения промокода"
        );
        return;
      }

      const isPromocode = !!inputValue.trim().length;
      if (!isPromocode) {
        return;
      }

      const options: IGetUserCartRequest = {
        calculate_orders: selectedProductIds.join(","),
        use_promo_code: inputValue.trim()
      };

      const usePromocodeResponse = dispatch(getUserCart(options)).unwrap();
      usePromocodeResponse
        .then((response) => {
          if (isError(response)) {
            throw new CustomError(response.message, response?.code);
          }
          dispatch(setCheckoutItems(selectedProductIds));
          setIsPromocodeSelected(true);
          dispatch(setPromocode(inputValue));
          setNotValidCodeMessage("");
        })
        .catch((error) => {
          const errorData = getErrorData(error);
          setNotValidCodeMessage(errorData.message);
        });
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(
        "Произошла ошибка при применении промокода",
        errorData.message
      );
      toast.error("Произошла ошибка при применении промокода");
      setIsPromocodeSelected(false);
      setNotValidCodeMessage("Произошла ошибка при применении промокода");
    }
  };

  const onRemovePromocode = async () => {
    try {
      const selectedProductIds = cart.reduce<number[]>((acc, cur) => {
        if (cur.isSelected) {
          acc.push(cur.offer.id);
        }
        return acc;
      }, []);

      const options: IGetUserCartRequest = {
        calculate_orders: selectedProductIds.join(",")
      };
      const deletePromocodeResponse = dispatch(getUserCart(options)).unwrap();
      deletePromocodeResponse
        .then((response) => {
          if (isError(response)) {
            throw new CustomError(response.message, response?.code);
          }
          dispatch(setCheckoutItems(selectedProductIds));
          setIsPromocodeSelected(false);
          setInputValue("");
          dispatch(setPromocode(""));
          setNotValidCodeMessage("");
        })
        .catch((error) => {
          const errorData = getErrorData(error);
          setNotValidCodeMessage(errorData.message);
        });
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(
        "Произошла ошибка при удалении промокода",
        errorData.message
      );
      toast.error("Произошла ошибка при удалении промокода");
      setIsPromocodeSelected(false);
      setNotValidCodeMessage("Произошла ошибка при удалении промокода");
    }
  };

  const [previousCartSum, setPreviousCartSum] = useState<number>(productsMainPrice);

  const onCheckoutItems = async () => {
    const checkoutItemsIds = cart.reduce<number[]>((acc, cur) => {
      if (cur.isSelected) {
        // Добавляем только товары в наличии в оформление заказа
        if (cur.offer.available) {
          acc.push(cur.offer.id);
        }
      }
      return acc;
    }, []);

    const isNotAvailableExist = !!cart.find(
      (product) => product.isSelected && !product.offer.available
    );

    if (isNotAvailableExist) {
      toast.info(
        "Вы пытаетесь оформить заказ с товаром, которого нет в наличии"
      );
      return;
    }

    if (!checkoutItemsIds.length) {
      return toast("Перед оформлением выберите товары");
    }

    const trigger = productsMainPrice !== previousCartSum && checkoutData.providerInfo !== null;

    await recalculateDeliveryCost(
      trigger,
      productsMainPrice,
      checkoutData,
      dispatch,
      setPreviousCartSum
    );

    setValueInStorage({
      key: "checkoutItemsIds",
      value: JSON.stringify(checkoutItemsIds)
    });
    dispatch(setCheckoutItems(checkoutItemsIds));
    navigate("checkout");
  };

  useEffect(() => {
    const selectedProductIds = cart
      .filter((cur) => cur.isSelected)
      .map((cur) => cur.offer.id);
    const hasSelectedChanged = !arraysEqual(
      selectedProductIds,
      previousSelectedProductIds.current
    );

    const cartOptions: IAddPublicCartData | IGetUserCartRequest = {
      calculate_orders: selectedProductIds.join(",")
    };

    if (hasSelectedChanged) {
      setIsPromocodeSelected(false);
      setInputValue("");
      dispatch(setPromocode(""));
      setNotValidCodeMessage("");
      dispatch(getUserCart(cartOptions));
    }

    previousSelectedProductIds.current = selectedProductIds;
  }, [cart, isPromocodeSelected, dispatch]);

  return (
    <div className={styles.root}>
      <div className={styles["title-wrapper"]}>
        <h3 className={styles.title}>Ваш заказ</h3>
      </div>
      <div className={styles["amount-wrapper"]}>
        <OrderStringItem
          title="Всего товаров в корзине:"
          subtitle={`${productsAmount}`}
          contanerClassname={styles.amount}
          subtitleClassname={styles["text-black"]}
        />
        <OrderStringItem
          title="Выбранные товары:"
          contanerClassname={styles["chosen-items"]}
          subtitle={`${selectedProductsAmount}`}
        />
      </div>
      {token && (
        <PromocodeInput
          inputValue={inputValue}
          notValidCodeMessage={notValidCodeMessage}
          setIsNotValidCode={setNotValidCodeMessage}
          onChange={onChangeHandler}
          onSendPromocode={onSendPromocode}
          isPromocodeSelected={isPromocodeSelected}
          onRemovePromocode={onRemovePromocode}
        />
      )}
      <div className={styles["price-wrapper"]}>
        <OrderStringItem
          title="Стоимость товаров:"
          subtitle={`${formatPrice(productsOldPrice) || 0} ₽`}
          contanerClassname={styles.price}
        />
        {token && (
          <OrderStringItem
            title="Скидка по промокоду:"
            subtitle={`${formatPrice(productsPromoDiscount) || 0} ₽`}
            contanerClassname={styles.price}
            subtitleClassname={styles["text-black"]}
          />
        )}
        <OrderStringItem
          title="Итоговая скидка:"
          subtitle={`${formatPrice(productsShopDiscount) || 0} ₽`}
          contanerClassname={styles.price}
          subtitleClassname={styles["text-black"]}
        />
      </div>
      <OrderStringItem
        title="Всего к оплате:"
        subtitle={`${formatPrice(productsMainPrice)} ₽`}
        contanerClassname={styles.price}
      />
      <CustomButton
        title="Перейти к оформлению"
        className={styles.link}
        onClick={onCheckoutItems}
      />
      {showModal && (
        <PromocodeModal
          isOpen={showModal}
          modalRef={modalRef}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default OrderData;
