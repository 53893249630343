import styles from "./styles.module.scss";

import Portal from "../../../HOC/Portal";
import ContentFoundCheaper from "../ContentFoundCheaper/ContentFoundCheaper";

interface IModalFoundCheaperProps {
  onClose: () => void;
  modalRef: React.RefObject<HTMLDivElement>;
  currentOfferId: number;
}

const ModalFoundCheaper: React.FC<IModalFoundCheaperProps> = (props) => {

  return (
    <Portal modalRef={props.modalRef} onClose={props.onClose}>
      <div className={styles.modal}>
        <ContentFoundCheaper currentOfferId={props.currentOfferId} onClose={props.onClose} modalRef={props.modalRef} />
        <div className={styles.modal__background} />
      </div>
    </Portal>
  );
};

export default ModalFoundCheaper;
