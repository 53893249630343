import { OrderOffer } from "types/IGetUserOrderData";
import { IProductWithOffers } from "types/IProduct";

export const transformOrderOfferToProductWithOffers = (orderOffer: OrderOffer): IProductWithOffers => {
  const { offer } = orderOffer;
  return {
    ...offer,
    brands: [],
    category: { name: "", rewrite_name: "" },
    description: "",
    properties: [],
    productImage: offer.offerImage ?? offer.catalog.productImage,
    vendorCode: "",
    product_rewrite_name: "",
    offers: [offer],
    minPrice: 0,
    maxPrice: 0,
  };
};