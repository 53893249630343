import { Link, useLocation } from "react-router-dom";

import { ProfileRoutesList } from "../../utils/constants/routes";

import CustomButton from "../CustomButton/CustomButton";
import userAvatar from "../../assets/icons/png/user_avatar.png";

import cn from "classnames";
import styles from "./styles.module.scss";
import { useAppSelector } from "store/reduxHooks";

interface PersonalAreaNavigationContentProps {
  containerStyles?: string;
  onClose: () => void;
  onContinueExit: () => void;
}

const PersonalAreaNavigationContent: React.FC<PersonalAreaNavigationContentProps> = (props) => {
  const { user } = useAppSelector((state) => state.user);
  const location = useLocation();

  return (
    <div className={cn(styles.root, props.containerStyles)}>
      <img src={userAvatar} alt="user_avatar" className={styles.avatar} />
      <span className={styles["user-data"]}>
        <span>{!user.surname ? "" : `${user.surname} `}</span>
        <span>{!user.name ? "" : user.name}</span>
        <span>{!user.patronymic ? "" : ` ${user.patronymic}`}</span>
      </span>
      <div className={styles["links-wrapper"]}>
        <Link
          to={ProfileRoutesList.PROFILE}
          className={cn(styles.link, {
            [styles["link--active"]]: ProfileRoutesList.PROFILE === location.pathname,
          })}
          onClick={props.onClose}
        >
          Профиль
        </Link>
        <Link
          to={ProfileRoutesList.FAVORITES}
          className={cn(styles.link, {
            [styles["link--active"]]: ProfileRoutesList.FAVORITES === location.pathname,
          })}
          onClick={props.onClose}
        >
          Избранное
        </Link>
        <Link
          to={ProfileRoutesList.MY_ORDERS}
          className={cn(styles.link, {
            [styles["link--active"]]: ProfileRoutesList.MY_ORDERS === location.pathname,
          })}
          onClick={props.onClose}
        >
          Заказы
        </Link>
        <Link
          to={ProfileRoutesList.PERSONAL_DATA}
          className={cn(styles.link, {
            [styles["link--active"]]: ProfileRoutesList.PERSONAL_DATA === location.pathname,
          })}
          onClick={props.onClose}
        >
          Личные данные
        </Link>
      </div>
      <CustomButton
        title="Выйти из аккаунта"
        isWhite
        onClick={props.onContinueExit}
        className={styles.button}
      />
    </div>
  );
};

export default PersonalAreaNavigationContent;
