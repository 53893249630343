import StatusBadge from "components/UI/StatusBadge/StatusBadge";
import { useMemo } from "react";
import { mapStatusToEnum } from "utils/helpers/getStatusStyle";
import styles from "./styles.module.scss";

interface OrderStatusAndDateProps {
  status: string;
  date: string;
}

const OrderStatusAndDate: React.FC<OrderStatusAndDateProps> = ({ status, date }) => {

  const formattedDate = useMemo(() => {
    const dateObj = new Date(date);
    return dateObj.toLocaleString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
  }, [date]);

  return (
    <div className={styles.order}>
      <div className={styles.order__block}>
        <div className={styles.order__label}>Статус заказа</div>
        <StatusBadge status={mapStatusToEnum(status)} />
      </div>
      <div className={styles.order__block}>
        <div className={styles.order__label}>Дата заказа</div>
        <div className={styles.order__date}>{formattedDate}</div>
      </div>
    </div>
  );
};

export default OrderStatusAndDate;