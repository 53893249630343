import { forwardRef, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { getIcon, IconProps, IconType } from "utils/getIcon";
import cn from "classnames";
import styles from "./styles.module.scss";

interface CustomProfileInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  startIcon?: IconType;
  endIcon?: IconType;
  startIconProps?: IconProps;
  endIconProps?: IconProps;
  helpText?: string;
  customInputStyles?: string;
  label?: string;
  customLabelStyles?: string;
  customWrapperStyles?: string;
  inputType?: string;
}

const defaultIconColor = "#D0D5DD";
const focusedIconColor = "#98A2B3";

const CustomProfileInput = forwardRef<
  HTMLInputElement,
  CustomProfileInputProps
>(
  (
    {
      isError,
      isRequired,
      isDisabled,
      startIcon,
      endIcon,
      startIconProps,
      endIconProps,
      helpText,
      label,
      customInputStyles,
      customLabelStyles,
      customWrapperStyles,
      inputType,
      ...props
    },
    ref
  ) => {
    const [hasText, setHasText] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null) as React.MutableRefObject<HTMLInputElement>;


    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      setHasText(event.target.value !== "");
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setHasText(event.target.value !== "");
    };

    const handleClear = () => {
      if (inputRef.current && hasText) {
        inputRef.current.value = "";
        setHasText(false);
        inputRef.current.focus();
      }
    };

    const setRefs = (el: HTMLInputElement) => {
      inputRef.current = el;
      if (typeof ref === "function") {
        ref(el);
      } else if (ref) {
        (ref as React.MutableRefObject<HTMLInputElement | null>).current = el;
      }
    };

    useEffect(() => {
      if (inputRef.current) {
        setHasText(inputRef.current.value !== "");
      }
    }, [inputRef.current?.value]);

    return (
      <div className={cn(styles["input-wrapper"], customWrapperStyles)}>
        {label && (
          <label className={cn(styles["label"], customLabelStyles)}>
            {label}{" "}
            {isRequired && <span className={styles["required"]}>*</span>}
          </label>
        )}
        <div
          className={cn(styles["input-container"], {
            [styles["input-container--error"]]: isError,
            [styles["input-container--disabled"]]: isDisabled
          })}
        >
          {startIcon && (
            <span className={styles["icon-start"]}>
              {getIcon(startIcon, {
                ...startIconProps,
                color: isFocused
                  ? focusedIconColor
                  : startIconProps?.color || defaultIconColor
              })}
            </span>
          )}
          <input
            ref={setRefs}
            type={showPassword ? "text" : inputType}
            className={cn(styles["input"], customInputStyles, {
              [styles["input--error"]]: isError,
              [styles["input--disabled"]]: isDisabled
            })}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            required={isRequired}
            {...props}
          />
          {inputType === "password" && hasText ? (
            <button
              className={styles["show-password"]}
              onClick={() => setShowPassword(!showPassword)}
              type="button"
            >
              {!showPassword ? "Показать" : "Скрыть"}
            </button>
          ) : (
            <span
              className={styles["icon-end"]}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onClick={() => {
                if (!isError && endIcon === "CrossIcon" && hasText) {
                  handleClear();
                }
              }}
            >
              {isError
                ? getIcon("DangerCircleIcon", { color: "#FF3369" })
                : endIcon &&
                getIcon(endIcon, {
                  ...endIconProps,
                  color: isFocused
                    ? focusedIconColor
                    : endIconProps?.color || defaultIconColor
                })}
            </span>
          )}
        </div>
        <div
          className={cn(styles["help-text"], {
            [styles["help-text--error"]]: isError,
            [styles.hidden]: !helpText
          })}
        >
          {helpText}
        </div>
      </div>
    );
  }
);

CustomProfileInput.displayName = "CustomProfileInput";

export default CustomProfileInput;
