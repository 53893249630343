interface BellIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const BellIcon: React.FC<BellIconProps> = ({ width = 20, height = 22, color = "#191B38" }) => (
  <svg width={width} height={height} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.35418 20C8.05931 20.6224 8.98556 21 10 21C11.0145 21 11.9407 20.6224 12.6458 20M16 7C16 5.4087 15.3679 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 10 1C8.4087 1 6.88258 1.63214 5.75736 2.75736C4.63214 3.88258 4 5.4087 4 7C4 10.0902 3.22047 12.206 2.34967 13.6054C1.61513 14.7859 1.24786 15.3761 1.26133 15.5408C1.27624 15.7231 1.31486 15.7926 1.46178 15.9016C1.59446 16 2.19259 16 3.38886 16H16.6111C17.8074 16 18.4055 16 18.5382 15.9016C18.6851 15.7926 18.7238 15.7231 18.7387 15.5408C18.7521 15.3761 18.3849 14.7859 17.6503 13.6054C16.7795 12.206 16 10.0902 16 7Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default BellIcon;