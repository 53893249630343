import { FC, useEffect, useRef } from "react";

import cn from "classnames";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import useSlider from "utils/hooks/useCssSlider";
import { RootState } from "store/store";
import { useAppSelector } from "store/reduxHooks";
import ProgressiveImage from "components/ProgressiveImage/ProgressiveImage";
import bannerErrorImage from "../../../assets/banners/bannerErrorImage.webp";
import { getRelativeLink } from "utils/helpers/getRelativeLink";

const SliderOnCss: FC = () => {
  const banners = useAppSelector((state: RootState) => state.banners.banners);
  const {
    innerRef,
    currentSlide,
    currentBanner,
    filteredBanners,
    handlePrevClick,
    handleNextClick,
    handleDotClick,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    setIsPaused,
    handleMouseLeave,
    handleSlideClick,
    handleLinkClick
  } = useSlider({ banners });

  const bulletsRef = useRef<HTMLDivElement | null>(null);
  const { deviceType } = useAppSelector((state: RootState) => state.user);
  const innerStyle = {
    marginLeft: `-${currentSlide * 100}%`,
    "--slides-count": filteredBanners.length + 2
  } as React.CSSProperties;

  useEffect(() => {
    const updateBulletWidth = () => {
      const slidesCount = filteredBanners.length;
      const containerWidth =
        bulletsRef.current?.clientWidth || window.innerWidth;

      let maxBulletWidth;
      if (deviceType.isLargeDesktop) {
        maxBulletWidth = 108;
      } else if (deviceType.isDesktop) {
        maxBulletWidth = 80;
      } else {
        maxBulletWidth = 66;
      }

      const totalGap = (slidesCount - 1) * 10;
      const availableWidth = containerWidth - totalGap;
      const bulletWidth = Math.min(
        maxBulletWidth,
        availableWidth / slidesCount
      );

      if (bulletsRef.current) {
        bulletsRef.current.style.setProperty(
          "--bullet-width",
          `${bulletWidth}px`
        );
      }
    };

    updateBulletWidth();
    window.addEventListener("resize", updateBulletWidth);

    return () => {
      window.removeEventListener("resize", updateBulletWidth);
    };
  }, [
    filteredBanners.length,
    deviceType.isMobile,
    deviceType.isTablet,
    deviceType.isDesktop,
    deviceType.isLargeDesktop
  ]);

  return (
    <>
      {!deviceType.isMobile && !deviceType.isTablet && (
        <div className={styles.inner_slider}>
          <button
            className={cn(styles.btn, styles.btn__left)}
            onClick={handlePrevClick}
          ></button>
          <button
            className={cn(styles.btn, styles.btn__right)}
            onClick={handleNextClick}
          ></button>
        </div>
      )}
      <div
        id="slider"
        className={styles.slider}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        {filteredBanners.map((banner, index) => (
          <input
            key={`${index}-${banner.image.original_webp_image.link}-${index}`}
            type="radio"
            name="slider"
            id={`slide${index + 1}`}
            defaultChecked={index === 0}
            className={styles.radio}
          />
        ))}
        <div
          id="slides"
          className={styles.slides}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div id="overflow" className={styles.overflow}>
            <div
              className={styles.inner}
              ref={innerRef}
              style={innerStyle}
              onClick={handleSlideClick}
            >
              {filteredBanners.length > 0 && (
                <div
                  className={cn(
                    styles.slide,
                    styles[`slide${filteredBanners.length}`]
                  )}
                >
                  <NavLink
                    to={!filteredBanners[filteredBanners.length - 1].link ? "/" : getRelativeLink(
                      filteredBanners[filteredBanners.length - 1].link ?? ""
                    )}
                    className={styles.slide_link}
                    onClick={handleLinkClick}
                  >
                    <div className={styles.slide_content}>
                      <ProgressiveImage
                        lowQualitySrc={
                          filteredBanners[filteredBanners.length - 1].image
                            .reduced_webp_image_0?.link ||
                          filteredBanners[filteredBanners.length - 1].image
                            .original_webp_image.link
                        }
                        highQualitySrc={
                          filteredBanners[filteredBanners.length - 1].image
                            .original_webp_image.link ||
                          filteredBanners[filteredBanners.length - 1].image
                            .reduced_webp_image_0?.link
                        }
                        alt={
                          filteredBanners[filteredBanners.length - 1]
                            .header_text
                        }
                        errorImage={bannerErrorImage}
                        className={styles.image}
                      />
                    </div>
                  </NavLink>
                </div>
              )}
              {filteredBanners.map((banner, index) => (
                <div
                  key={`${index}-${banner.image.original_webp_image.link}-${index}`}
                  className={cn(styles.slide, styles[`slide${index + 1}`])}
                >
                  <NavLink
                    to={!banner.link ? "/" : getRelativeLink(banner.link)}
                    className={styles.slide_link}
                    onClick={handleLinkClick}
                  >
                    <div className={styles.slide_content}>
                      <ProgressiveImage
                        lowQualitySrc={
                          banner.image.reduced_webp_image_0?.link ||
                          banner.image.original_webp_image.link
                        }
                        highQualitySrc={
                          banner.image.original_webp_image.link ||
                          banner.image.reduced_webp_image_0?.link
                        }
                        alt={banner.header_text}
                        errorImage={bannerErrorImage}
                        className={styles.image}
                      />
                    </div>
                  </NavLink>
                </div>
              ))}
              {filteredBanners.length > 0 && (
                <div className={cn(styles.slide, styles["slide1"])}>
                  <NavLink
                    to={!filteredBanners[0].link ? "/" : getRelativeLink(filteredBanners[0].link)}
                    className={styles.slide_link}
                    onClick={handleLinkClick}
                  >
                    <div className={styles.slide_content}>
                      <ProgressiveImage
                        lowQualitySrc={
                          filteredBanners[0].image.reduced_webp_image_0?.link ||
                          filteredBanners[0].image.original_webp_image.link
                        }
                        highQualitySrc={
                          filteredBanners[0].image.original_webp_image.link ||
                          filteredBanners[0].image.reduced_webp_image_0?.link
                        }
                        alt={filteredBanners[0].header_text}
                        errorImage={bannerErrorImage}
                        className={styles.image}
                      />
                    </div>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.text_overlay}>
          <h2
            className={cn(styles.text_overlay__title, {
              [styles["text_overlay__title--white"]]:
                currentBanner?.dark_background
            })}
          >
            {currentBanner?.header_text}
          </h2>
          <p
            className={cn(styles.text_overlay__subtitle, {
              [styles["text_overlay__subtitle--white"]]:
                currentBanner?.dark_background
            })}
          >
            {currentBanner?.subheader_text}
          </p>
        </div>
        <div ref={bulletsRef} className={styles.bullets}>
          {filteredBanners.map((_, index) => (
            <div
              key={index}
              className={styles.bullets__wrapper}
              onClick={(event) => handleDotClick(index, event)}
            >
              <label
                htmlFor={`slide${index + 1}`}
                className={cn(styles.bullets__label, {
                  [styles.active]: currentSlide === index + 1
                })}
              ></label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SliderOnCss;
