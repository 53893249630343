import React from "react";

import styles from "./styles.module.scss";

interface CartProductPriceProps {
  salePrice: string | null;
  totalPrice: string | null;
}

export const CartProductPrice: React.FC<CartProductPriceProps> = (props) => {
  return (
    <div className={styles["product-price"]}>
      {props.salePrice ? (
        <p className={styles["product-price__total-price"]}>
          {props.salePrice}
        </p>
      ) : (
        <div />
      )}
      <p className={styles["product-price__sale-price"]}>
        {props.totalPrice ? props.totalPrice : "Нет в наличии"}
      </p>
    </div>
  );
};
