interface ChevronProps {
    direction: "up" | "down" | "left" | "right";
    width?: number;
    height?: number;
    color?: string;
  }

const Chevron: React.FC<ChevronProps> = ({ direction, width, height, color = "#191B38" }) => {
  const paths = {
    up: (
      <path d="M13 7L7 1L1 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    ),
    down: (
      <path d="M1 1L7 7L13 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    ),
    left: (
      <path d="M7 13L1 7L7 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    ),
    right: (
      <path d="M1 13L7 7L1 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    )
  };

  const defaultSizes = {
    up: { width: 14, height: 8 },
    down: { width: 14, height: 8 },
    left: { width: 8, height: 14 },
    right: { width: 8, height: 14 }
  };
  const { width: defaultWidth, height: defaultHeight } = defaultSizes[direction];

  return (
    <svg width={width || defaultWidth} height={height || defaultHeight} viewBox={`0 0 ${defaultWidth} ${defaultHeight}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      {paths[direction]}
    </svg>
  );
};

export default Chevron;