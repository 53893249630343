import { useMemo } from "react";
import { PROVIDERS, WAY_TO_GET } from "../../../../../../../utils/constants";

import { ReactComponent as InfoIcon } from "../../../../../../../assets/icons/svg/info-icon.svg";

import styles from "./styles.module.scss";
import { useAppSelector } from "store/reduxHooks";
import { ProvidersInfo } from "types/IProviderInfo";
import { IChosenProvider } from "utils/hooks/useYandexMap";
import { toast } from "react-toastify";

interface IProviderItemProps {
  item: IChosenProvider;
  providersInfo: ProvidersInfo | null;
  onSelectProvider: (provider: IChosenProvider | null) => void;
  chosenProvider: IChosenProvider | null;
}

const ProviderItem: React.FC<IProviderItemProps> = (props) => {
  const { checkoutData } = useAppSelector((state) => state.user);

  const currentTitle = useMemo(() => {

    if (!props.providersInfo) {
      return toast("Данных о фирмах доставки нет");
    }
    const wayToGetKey = Object.entries(WAY_TO_GET).find((item) => {
      return item[1] === checkoutData.wayToGet;
    })?.[0] as "toDoor" | "toPoint";
    const findedItem = props.providersInfo[wayToGetKey].find(
      (item) => item.deliveryProvider === props.item.type
    );
    return findedItem?.resultTitle ?? "";
  }, [props.item.type, checkoutData.wayToGet, props.providersInfo]);

  const providerDescription = useMemo(() => {
    return PROVIDERS[props.item.type]?.description ?? "";
  }, [props.item.type]);

  const onClickHandler = () => {
    if (props.item.type === props.chosenProvider?.type) {
      props.onSelectProvider(null);
    } else {
      props.onSelectProvider(props.item);
    }
  };

  if (!currentTitle) {
    return null;
  }

  return (
    <li className={styles.root}>
      <input
        type="checkbox"
        checked={props.item.type === props.chosenProvider?.type}
        className={styles.input}
        onChange={onClickHandler}
      />
      <label className={styles.label} onClick={onClickHandler}>
        {currentTitle}
      </label>
      {!!providerDescription && (
        <div className={styles["info-wrapper"]}>
          <InfoIcon className={styles.info__icon} />
          <span className={styles.info__text}>{providerDescription}</span>
        </div>
      )}
    </li>
  );
};

export default ProviderItem;
