import { type IDeliveryAdress } from "types/IDeliveryAdressAction";

export const formatStreetAddress = (address: string): string => {
  const parts = address.split(", ");
  const streetPart = parts.find(
    (part) => part.trim().startsWith("ул.") || part.includes("ул.")
  );
  if (streetPart) {
    return streetPart.replace(/^\s*ул\.?\s*/, "").trim();
  }
  const lastPart = parts[parts.length - 1].trim();
  return lastPart.replace(/^\s*ул\.?\s*/, "").trim();
};

export const formatAddress = (address: IDeliveryAdress) => {
  return `г ${address.city}, ${address.street}, ${address.house}${
    address.flat ? `, кв. ${address.flat}` : ""
  }${address.entrance ? `, подъезд ${address.entrance}` : ""}${
    address.floor ? `, этаж ${address.floor}` : ""
  }`;
};

