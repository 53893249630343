interface ArrowProps {
  direction: "left" | "right";
  width?: number;
  height?: number;
  color?: string;
}

const Arrow: React.FC<ArrowProps> = ({ direction, width = 12, height = 12, color = "#191B38" }) => {
  const paths = {
    left: (
      <path d="M12.8333 7.00008H1.16665M1.16665 7.00008L6.99998 1.16675M1.16665 7.00008L6.99998 12.8334" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    ),
    right: (
      <path d="M1.16669 7.00008H12.8334M12.8334 7.00008L7.00002 1.16675M12.8334 7.00008L7.00002 12.8334" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    )
  };

  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      {paths[direction]}
    </svg>
  );
};

export default Arrow;