import { useCallback, useEffect, useRef, useState } from "react";
import { IEvent, IGeoObject, IGeoObjectCollection, IMap } from "types/IYMapTypes";

interface GeocodeResult extends IGeoObject {
  getThoroughfare(): string | null;
  getPremiseNumber(): string | null;
}

interface UseYandexMapForAddressOptions {
  showMap: boolean;
  onAddressSelect: (address: string) => void;
  onClose: () => void;
  initialAddress?: string;
  cityLatitude?: number;
  cityLongitude?: number;
  addressLatitude?: number;
  addressLongitude?: number;
}

const useYandexMapForAddress = ({
  showMap,
  onAddressSelect,
  onClose,
  cityLatitude,
  cityLongitude,
  addressLatitude,
  addressLongitude
}: UseYandexMapForAddressOptions) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [map, setMap] = useState<IMap | null>(null);
  const [currentCity, setCurrentCity] = useState<string | null>(null);
  const mapRef = useRef<HTMLDivElement | null>(null);
  const placemarkRef = useRef<HTMLDivElement | null>(null);
  const addressRef = useRef<HTMLDivElement | null>(null);

  const initMap = useCallback(() => {
    if (!mapRef.current || !window.ymaps || !cityLatitude || !cityLongitude) return;


    const newMap = new window.ymaps.Map(mapRef.current, {
      center: [cityLatitude, cityLongitude],
      zoom: 9,
      controls: []
    });

    newMap.events.add("actionend", function () {
      const newCenter = newMap.getCenter();
      if (placemarkRef.current) {
        placemarkRef.current.style.left = `${newMap.container.getSize()[0] / 2}px`;
        placemarkRef.current.style.top = `${newMap.container.getSize()[1] / 2}px`;
      }

      window.ymaps.geocode(newCenter).then(function (res: IGeoObjectCollection) {
        const firstGeoObject = res.geoObjects.get(0) as GeocodeResult;
        const thoroughfare = firstGeoObject.getThoroughfare() || "";
        const houseNumber = firstGeoObject.getPremiseNumber() || "";
        const formattedAddress = `${thoroughfare}${houseNumber ? `, д. ${houseNumber}` : ""}`;
        if (addressRef.current) {
          if (formattedAddress) {
            addressRef.current.innerText = formattedAddress;
            addressRef.current.style.display = "block";
          } else {
            addressRef.current.style.display = "none";
          }
        }
        const locality = firstGeoObject.properties.get(
          "metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.SubAdministrativeArea.Locality.LocalityName"
        );
        setCurrentCity(locality);
      }).catch((error: unknown) => {
        // eslint-disable-next-line no-console
        console.error("Geocode error:", error);
      });
    });

    newMap.events.add("click", function (e: IEvent) {
      const coords = e.get("coords");
      window.ymaps.geocode(coords).then(function (res: IGeoObjectCollection) {
        const firstGeoObject = res.geoObjects.get(0) as GeocodeResult;
        const thoroughfare = firstGeoObject.getThoroughfare() || "";
        const houseNumber = firstGeoObject.getPremiseNumber() || "";
        const formattedAddress = `${thoroughfare}${houseNumber ? `, д. ${houseNumber}` : ""}`;
        onAddressSelect(formattedAddress);
        onClose();
        newMap.setCenter(coords, 19);
      }).catch((error: unknown) => {
        // eslint-disable-next-line no-console
        console.error("Geocode error:", error);
      });
    });

    setMap(newMap);
  }, [cityLatitude, cityLongitude, onAddressSelect, onClose]);

  useEffect(() => {
    if (showMap && !map) {
      window.ymaps.ready(initMap);
    }
  }, [showMap, map, initMap]);

  useEffect(() => {
    if (map) {
      if (addressLatitude !== undefined && addressLongitude !== undefined) {
        map.setCenter([addressLatitude, addressLongitude], 19);
      } else if (cityLatitude !== undefined && cityLongitude !== undefined) {
        map.setCenter([cityLatitude, cityLongitude], 14);
      }
    }
  }, [map, cityLatitude, cityLongitude, addressLatitude, addressLongitude]);

  useEffect(() => {
    if (mapRef.current && showMap) {
      mapRef.current.style.display = "block";
    } else if (mapRef.current) {
      mapRef.current.style.display = "none";
    }
  }, [showMap]);

  return {
    mapRef,
    resetMap: () => setMap(null),
    placemarkRef,
    addressRef,
    currentCity,
    setCurrentCity
  };
};

export default useYandexMapForAddress;
