interface RedBoxIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const RedBoxIcon: React.FC<RedBoxIconProps> = ({ width = 20, height = 18, color = "#FF0044" }) => (
  <svg width={width} height={height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 6V14.2C19.5 15.8802 19.5 16.7202 19.173 17.362C18.8854 17.9265 18.4265 18.3854 17.862 18.673C17.2202 19 16.3802 19 14.7 19H7.3C5.61984 19 4.77976 19 4.13803 18.673C3.57354 18.3854 3.1146 17.9265 2.82698 17.362C2.5 16.7202 2.5 15.8802 2.5 14.2V6M2.6 1H19.4C19.9601 1 20.2401 1 20.454 1.10899C20.6422 1.20487 20.7951 1.35785 20.891 1.54601C21 1.75992 21 2.03995 21 2.6V4.4C21 4.96005 21 5.24008 20.891 5.45399C20.7951 5.64215 20.6422 5.79513 20.454 5.89101C20.2401 6 19.9601 6 19.4 6H2.6C2.03995 6 1.75992 6 1.54601 5.89101C1.35785 5.79513 1.20487 5.64215 1.10899 5.45399C1 5.24008 1 4.96005 1 4.4V2.6C1 2.03995 1 1.75992 1.10899 1.54601C1.20487 1.35785 1.35785 1.20487 1.54601 1.10899C1.75992 1 2.03995 1 2.6 1ZM8.6 9.5H13.4C13.9601 9.5 14.2401 9.5 14.454 9.60899C14.6422 9.70487 14.7951 9.85785 14.891 10.046C15 10.2599 15 10.5399 15 11.1V11.9C15 12.4601 15 12.7401 14.891 12.954C14.7951 13.1422 14.6422 13.2951 14.454 13.391C14.2401 13.5 13.9601 13.5 13.4 13.5H8.6C8.03995 13.5 7.75992 13.5 7.54601 13.391C7.35785 13.2951 7.20487 13.1422 7.10899 12.954C7 12.7401 7 12.4601 7 11.9V11.1C7 10.5399 7 10.2599 7.10899 10.046C7.20487 9.85785 7.35785 9.70487 7.54601 9.60899C7.75992 9.5 8.03995 9.5 8.6 9.5Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default RedBoxIcon;