import { ICheckoutData } from "types/ICheckoutData";

export const calculateCheckoutTotalSum = (checkoutData: ICheckoutData) => {
  const productsSum = checkoutData.items.reduce((acc, cur) => {
    if (cur.calculated_amount) {
      acc += cur.calculated_amount;
    }
    return acc;
  }, 0);
  return productsSum;
};