import cn from "classnames";
import styles from "./styles.module.scss";
import { SkeletonImg } from "../SkeletonImg/SkeletonImg";
import { SkeletonLine } from "../SkeletonLine/SkeletonLine";

const SkeletonHorizontalCard: React.FC = () => {
  return (
    <div className={cn(styles.card, styles.horizontal)}>
      <SkeletonImg className={styles.image} />
      <div className={styles.details}>
        <SkeletonLine style={{ width: "100px", height: "20px", borderRadius: "20px" }} />
        <SkeletonLine style={{ width: "80px", height: "20px", borderRadius: "20px" }} />
        <SkeletonLine style={{ width: "90px", height: "20px", borderRadius: "20px" }} />
      </div>
    </div>
  );
};

export default SkeletonHorizontalCard;