import { useCallback, useMemo, useRef, useState } from "react";

import cn from "classnames";
import styles from "./styles.module.scss";
import questionMark from "../../../../../../assets/icons/svg/question-mark.svg";
import ModalMarkDownInfo from "../../../../../../components/UI/ModalMarkDownInfo/ModalMarkDownInfo";
import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import ContentMarkDownInfo from "../../../../../../components/UI/ContentMarkDownInfo/ContentMarkDownInfo";
import { useAppSelector } from "store/reduxHooks";
import { formatPrice } from "utils/helpers/formatedPrice";
import { IOffer } from "types/IOffer";
import { ICurrentOffer } from "types/ICurrentOffer";

interface IProductOffersListItemProps {
  productId?: number;
  offer: IOffer;
  isActiveOffer: boolean;
  onSelectOffer: (option: ICurrentOffer) => void;
}

const ProductOffersListItem: React.FC<IProductOffersListItemProps> = (
  props
) => {
  const [isModalMarkDown, setIsModalMarkDown] = useState(false);
  const refModalMarkDown = useRef<HTMLDivElement | null>(null);
  const { deviceType } = useAppSelector((state) => state.user);

  const offerTitle = useMemo(() => {
    const currentOfferVolume = props.offer.offerVolumes.find((item) => {
      return props.offer.name.includes(item.value_type);
    });
    // TODO - уточнить что показываем , если массив объема пуст. Какое описание делаем
    if (!currentOfferVolume) {
      return {
        volume: "",
        description: ""
      };
    }

    return {
      volume: `${currentOfferVolume.value} ${currentOfferVolume.value_type}`,
      description: props.offer.name
        .split(currentOfferVolume.value_type)[1]
        ?.trim()
    };
  }, [props.offer]);

  const onClickHandler = () => {
    const selectedOffer: ICurrentOffer = {
      id: props.offer.id,
      description: props.offer.name,
      promotionPrice: props.offer.currentPrice,
      price: props.offer.oldPrice,
      percent: props.offer.percent,
      available: props.offer.available
    };
    props.onSelectOffer(selectedOffer);
  };

  const findMarkdown = useCallback(() => {
    if (!props.offer.offerGoodsTypes[0]) return false;
    const isMarkdown = props.offer.offerGoodsTypes.filter(
      (element) => element.value === "уценка"
    );

    return !!isMarkdown[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickMarkdown = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    setIsModalMarkDown(true);
  };

  const isMarkDownInfo = useMemo(() => {
    return isModalMarkDown && !deviceType.isTablet && !deviceType.isMobile;
  }, [isModalMarkDown, deviceType]);

  return (
    <div
      className={styles.root}
      onClick={onClickHandler}
      data-offer_id={props.offer.id}
      data-group_id={props.productId}
    >
      <span className={styles.indicator}>
        {props.isActiveOffer && <span className={styles["indicator-inner"]} />}
      </span>
      <div className={styles.offer}>
        <span className={styles.offer_volume}>{offerTitle.volume}</span>
        <span className={styles["offer_description-wrapper"]}>
          <span className={styles.offer_separator}>&ndash;</span>
          <span className={styles.offer_description}>
            {offerTitle.description}
          </span>
        </span>
      </div>
      <div className={styles["promotion-content"]}>
        <div className={styles["promotion-content__icon-percent"]}>
          {findMarkdown() && (
            <div className={styles["promotion-content__markdown"]}>
              <img src={questionMark} onClick={clickMarkdown} />
            </div>
          )}

          {!!props.offer.percent && (
            <span className={styles["promotion-content__percent"]}>
              {`${props.offer.percent}%`}
            </span>
          )}
        </div>
        <span
          className={cn(styles["promotion-content__price"], {
            [styles["promotion-content__price--single"]]: !(
              props.offer.akcia_label || props.offer.sale_label
            )
          })}
          itemProp="offers"
          itemScope
          itemType="https://schema.org/Offer"
        >
          {(props.offer.akcia_label || props.offer.sale_label) ? (
            <>
              <span
                itemProp="price"
                content={`${props.offer.currentPrice}`}
                className={styles["promotion-content__price"]}
              >
                {`${formatPrice(props.offer.currentPrice)}\u202F`}
                <span itemProp="priceCurrency" content="RUB">
                  ₽
                </span>
              </span>
              <span className={styles["promotion-content__price--through"]}>
                {`${formatPrice(props.offer.oldPrice)}\u202F₽`}
              </span>
            </>
          ) : (
            <span
              itemProp="price"
              content={`${props.offer.currentPrice}`}
              className={styles["promotion-content__price"]}
            >
              {`${formatPrice(props.offer.currentPrice)}\u202F`}
              <span itemProp="priceCurrency" content="RUB">
                ₽
              </span>
            </span>
          )}
          {props.offer.available ? (
            <meta
              itemProp="availability"
              content="https://schema.org/InStock"
            />
          ) : (
            <meta
              itemProp="availability"
              content="https://schema.org/OutOfStock"
            />
          )}
        </span>

        {isMarkDownInfo && (
          <ModalMarkDownInfo
            onClose={() => setIsModalMarkDown(false)}
            modalRef={refModalMarkDown}
          />
        )}
        <BottomSheet
          isOpen={
            isModalMarkDown && (deviceType.isTablet || deviceType.isMobile)
          }
          onClose={() => setIsModalMarkDown(false)}
        >
          <ContentMarkDownInfo onClose={() => setIsModalMarkDown(false)} />
        </BottomSheet>
      </div>
    </div>
  );
};

export default ProductOffersListItem;
