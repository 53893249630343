import { getValueFromStorage, setValueInStorage } from "../localStorage";
import { toast } from "react-toastify";
import { userApi } from "../../api/userApi";
import { useNavigate } from "react-router-dom";
import { getUserCart } from "../../store/user/cartThunks/cartThunks";
import { setPromocode } from "../../store/user/user";
import { isError } from "store/user/userThunks/userThunks";
import { CustomError, getErrorData } from "utils/getErrorData";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { ICreateNewOrderAuthorizedUsersResponse } from "types/ICreateNewOrderAuthorizedUsers";
import { ICreateNewOrderNonAuthorizedUsersResponse } from "types/ICreateNewOrderNonAuthorizedUsers";
import { Offer } from "types/UserApiTypes";

interface IUsePaymentData {
  orderData:
    | ICreateNewOrderAuthorizedUsersResponse
    | ICreateNewOrderNonAuthorizedUsersResponse
    | null;
  onOrderCreated: () => void;
}

const usePayment = ({ orderData, onOrderCreated }: IUsePaymentData) => {
  const { checkoutData } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clearPaidItemsFromCart = () => {
    // clear localstorage cart by checkoutItemsIds
    const selectedItemsIdsInStorage: number[] =
      JSON.parse(getValueFromStorage("checkoutItemsIds") ?? "") || [];
    const currentCart: Offer[] = JSON.parse(getValueFromStorage("cart") ?? "") || [];
    const resultCartItems = currentCart.filter(
      (item) => !selectedItemsIdsInStorage.includes(Number(item.id))
    );
    setValueInStorage({ key: "cart", value: JSON.stringify(resultCartItems) });
    // get usercart after changing values of localstorage cart
    dispatch(getUserCart({}));
    dispatch(setPromocode(""));
  };

  const confirmPayment = async () => {
    const paymentType = checkoutData.paymentMethod?.type;
    const orderUuid = orderData?.uuid;

    if (!paymentType) {
      toast("Пожалуйста выберите способ оплаты");
      return;
    }

    if (!orderUuid) {
      toast(
        "Произошла ошибка. Отсутствует номер заказа. Обратитесь к администрации."
      );
      return;
    }

    const allowedPaymentProviders = [
      "sber_pay",
      "dolymi",
      "card_online",
      "tinkoff_pay",
      "yandex_pay",
      "sbp",
      "yandex_split",
      "yookassa",
      "mokka",
    ];
    const isAllowPayment = allowedPaymentProviders.includes(paymentType);

    if (isAllowPayment) {
      const isSberbank =
        paymentType === "sber_pay" || paymentType === "card_online";
      try {
        const res = await userApi.getOrderPaymentLink({
          order: orderUuid,
          payment_provider: `${isSberbank ? "sberbank" : paymentType}`
        });

        if (isError(res)) {
          navigate("/cart");
          throw new CustomError(res.message, res?.code);
        }

        if (!res.response) {
          navigate("/cart");
          throw new CustomError("Произошла ошибка. Ссылка на оплату отсутствует");
        }

        if (res.response.pay_link) {
          clearPaidItemsFromCart();
          window.location.href = res.response.pay_link;
        } else {
          throw new CustomError(
            "Произошла ошибка. Ссылка на оплату отсутствует"
          );
        }
      } catch (err) {
        const errorData = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error(errorData.message);
        toast.error(errorData.message);
      }
    } else {
      if (!orderData.id && !orderData.created_at) {
        toast(
          "Произошла ошибка оформления заказа. Попробуйте перезагрузить страницу."
        );
        navigate("/cart");
        return;
      }
      clearPaidItemsFromCart();
      onOrderCreated();
    }
  };

  return {
    confirmPayment,
    clearPaidItemsFromCart
  };
};

export default usePayment;
