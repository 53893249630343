export const isValidDate = (dateString: string): boolean => {
  const regEx = /^\d{2}-\d{2}-\d{4}$/;
  const match = dateString.match(regEx);
  if (match === null) return false;

  const [day, month, year] = dateString.split("-").map(Number);
  const d = new Date(year, month - 1, day);
  const dNum = d.getTime();
  if (isNaN(dNum)) return false;

  return d.getDate() === day && d.getMonth() + 1 === month && d.getFullYear() === year;
};