import { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import cn from "classnames";

import {
  setDeliveryCoordinates,
  setOrderCity,
  setProviderInfo,
  setUserCurrentCity,
  setUserDeliveryAddress,
  setWayToGet
} from "../../../../../../../store/user/user";
import { WAY_TO_GET } from "../../../../../../../utils/constants";
import useOutsideClick from "../../../../../../../utils/hooks/useOutsideClick";
import useDelivery from "../../../../../../../utils/hooks/useDelivery";
import { formatAddress } from "../../../../../../../utils/helpers/formatedAddresses";

import CustomButton from "../../../../../../../components/CustomButton/CustomButton";
import ProvidersTitlesList from "../../../MainSelectProviderBlock/components/ProvidersTitlesList/ProviderTitleItem";
import CustomScrollbar from "../../../../../../../components/CustomScrollbar/CustomScrollbar";

import styles from "./styles.module.scss";
import LoadingItem from "components/LoadingItem/LoadingItem";

import { ProvidersInfo } from "types/IProviderInfo";
import { IGeoObject, IGeoObjectCollection } from "types/IYMapTypes";
import { WayToGet } from "types/IWayToGet";
import { IChosenProvider } from "utils/hooks/useYandexMap";

interface ISelectAddressUserProps {
  currentProviders: IChosenProvider[];
  providersInfo: ProvidersInfo | null;
  onSelectProvider: (provider: IChosenProvider | null) => void;
  onCloseModal: () => void;
  chosenProvider: IChosenProvider | null;
  setCity: (city: string) => Promise<void>;
}

const SelectAddressUser: React.FC<ISelectAddressUserProps> = (props) => {
  const { checkoutData } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [showAutocompleteList, setShowAutocompleteList] = useState(false);
  const [isFocusInput, setIsFocusInput] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [searchValue, setSearchValue] = useState(
    checkoutData.recipient.address
  );
  const [selectedAddressIdx, setSelectedAddressIdx] = useState<number | null>(
    null
  );
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);

  const {
    deliveryAddresses,
    setDeliveryAddressByPosition,
    isAddressesNotFound,
    isLoading
  } = useDelivery({
    isFocusInput,
    searchValue,
    setSearchValue
  });

  const selectSafeAddress = async (
    address: string,
    idx: number,
    city: string
  ): Promise<void> => {
    let result: IGeoObjectCollection | undefined;
    let geoObject: IGeoObject | undefined;
    try {
      result = await window.ymaps.geocode(address);
      geoObject = result?.geoObjects.get(0);
      if (!geoObject) {
        throw new Error(
          "Адрес не найден. Пожалуйста, введите корректный адрес."
        );
      }

      const coordinates = geoObject?.geometry?.getCoordinates();
      if (
        !coordinates ||
        coordinates.length !== 2 ||
        typeof coordinates[0] !== "number" ||
        typeof coordinates[1] !== "number" ||
        coordinates[0] < 35 ||
        coordinates[0] > 82 ||
        coordinates[1] < 19 ||
        coordinates[1] > 180
      ) {
        throw new Error("Проверьте правильность адреса");
      }
      dispatch(setOrderCity(city));
      dispatch(setUserCurrentCity(city));
      dispatch(setUserDeliveryAddress(address));
      dispatch(setDeliveryCoordinates(coordinates));
      setSearchValue(address);
      setShowAutocompleteList(false);
      setSelectedAddressIdx(idx);
      localStorage.setItem("selectedAddressIdx", idx.toString());
      await props.setCity(city);
    } catch (error) {
      if (
        error !== null &&
        typeof error === "object" &&
        "message" in error &&
        typeof error.message === "string"
      ) {
        // eslint-disable-next-line no-console
        console.error("Ошибка при выборе адреса:", error?.message);

        const knownErrors = [
          "Не верный адрес",
          "Адрес не найден. Пожалуйста, введите корректный адрес.",
          "Проверьте правильность адреса",
          "Доставка курьером недоступна в этом городе"
        ];

        const errorMessage = knownErrors.includes(error?.message)
          ? error.message
          : "Произошла ошибка. Пожалуйста, попробуйте снова.";

        toast(errorMessage);
      }
    }
  };

  const selectAddress = async (address: string) => {
    const result = await window.ymaps.geocode(address);
    const coordinates = result.geoObjects.get(0).geometry.getCoordinates();
    dispatch(setUserDeliveryAddress(address));
    dispatch(setDeliveryCoordinates(coordinates));
    setSearchValue(address);
    setShowAutocompleteList(false);
  };

  const acceptAddress = () => {
    if (!props.chosenProvider) {
      return toast("Выберите фирму доставки");
    }

    if (!checkoutData.recipient.address) {
      return toast("Пожалуйста, выберите адрес доставки");
    }

    if (!props.providersInfo) {
      return toast("Информация о провайдерах недоступна");
    }

    const wayToGetEntry = Object.entries(WAY_TO_GET).find((item) => {
      return item[1] === checkoutData.wayToGet;
    });

    if (!wayToGetEntry) {
      return toast("Неверный способ получения заказа");
    }

    const wayToGetKey = wayToGetEntry[0] as "toDoor" | "toPoint";

    const findedItem = props.providersInfo[wayToGetKey]?.find(
      (item) => item.deliveryProvider === props.chosenProvider?.type
    );

    if (!findedItem) {
      return toast("Информация о доставке не найдена");
    }

    const deliveryType =
      checkoutData.wayToGet === WAY_TO_GET.toDoor ? "delivery" : "pickup";

    dispatch(
      setProviderInfo({
        provider: findedItem.deliveryProvider,
        amount: findedItem.deliveryCost,
        daysMax: findedItem.daysMax,
        daysMin: findedItem.daysMin,
        type: deliveryType
      })
    );
    props.onCloseModal();
  };

  const autocompleteListRef = useRef(null);
  useOutsideClick({
    ref: autocompleteListRef,
    cb: () => {
      setIsFocusInput(false);
      setShowAutocompleteList(false);
    }
  });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onFocusHandler = () => {
    setIsFocusInput(true);
    setShowAutocompleteList(true);
    setIsPlaceholderVisible(false);
  };

  const selectWayToGet = (way: WayToGet) => {
    dispatch(setWayToGet(way));
  };

  useEffect(() => {
    const savedIdx = localStorage.getItem("selectedAddressIdx");
    if (savedIdx !== null) {
      setSelectedAddressIdx(Number(savedIdx));
    }
  }, []);

  const checkProvidersInfo = () => {
    if (!props.providersInfo || !props.providersInfo.toDoor) {
      return;
    }

    if (props.providersInfo.toDoor.length === 0 && !isToastVisible) {
      setIsToastVisible(true);
      toast.info("Доставка курьером недоступна в этом городе", {
        onClose: () => setIsToastVisible(false)
      });
    }
  };

  useEffect(() => {
    checkProvidersInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.providersInfo]);

  const onBlurHandler = () => {
    setIsPlaceholderVisible(true);
  };

  return (
    <div className={styles.root}>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title={WAY_TO_GET.toPoint}
          onClick={() => selectWayToGet(WAY_TO_GET.toPoint)}
          isBlack={checkoutData.wayToGet === WAY_TO_GET.toPoint}
          isWhite={checkoutData.wayToGet !== WAY_TO_GET.toPoint}
          className={cn(styles.buttons__pickup, {
            [styles["buttons--active"]]:
              checkoutData.wayToGet === WAY_TO_GET.toPoint
          })}
        />
        <CustomButton
          title={WAY_TO_GET.toDoor}
          onClick={() => selectWayToGet(WAY_TO_GET.toDoor)}
          isBlack={checkoutData.wayToGet === WAY_TO_GET.toDoor}
          isWhite={checkoutData.wayToGet !== WAY_TO_GET.toDoor}
          className={cn(styles.buttons__delivery, {
            [styles["buttons--active"]]:
              checkoutData.wayToGet === WAY_TO_GET.toDoor
          })}
        />
      </div>
      <div ref={autocompleteListRef} className={styles.autocomplete}>
        <input
          type="text"
          name="address"
          placeholder={isPlaceholderVisible ? "Ваш адрес" : ""}
          autoComplete="off"
          value={searchValue}
          onChange={handleChange}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          className={styles.autocomplete__input}
        />
        {!isLoading &&
          !isAddressesNotFound &&
          showAutocompleteList &&
          deliveryAddresses?.length > 0 && (
          <div className={styles["autocomplete__list-wrapper"]}>
            <div className={styles.autocomplete__list}>
              {deliveryAddresses.map((item, idx) => {
                return (
                  <button key={idx} onClick={() => selectAddress(item)}>
                    {item}
                  </button>
                );
              })}
            </div>
          </div>
        )}
        {isLoading && !isAddressesNotFound && (
          <div className={styles["autocomplete__list-wrapper"]}>
            <LoadingItem
              containerStyles={styles.loading}
              titleStyles={styles.loading__title}
            />
          </div>
        )}
        {isAddressesNotFound && !isLoading && (
          <div className={styles["autocomplete__list-wrapper"]}>
            <div className={styles.autocomplete__list}>
              <p className={styles["not-found"]}>
                По вашему запросу результатов не было найдено
              </p>
            </div>
          </div>
        )}
      </div>
      <div className={styles["btn-wrapper"]}>
        <button
          onClick={setDeliveryAddressByPosition}
          className={styles["additional-button"]}
        >
          Определить моё местоположение
        </button>
      </div>
      {checkoutData.recipient?.accountDeliveryAddresses &&
      checkoutData.recipient?.accountDeliveryAddresses?.length > 0 ? (
          <div className={styles["saved-addresses"]}>
            <h2 className={styles["saved-addresses__subtitle"]}>
            Сохраненные адреса
            </h2>
            <CustomScrollbar
              childrenClassName={styles["saved-addresses__list"]}
              isShowScrollbar={
                checkoutData.recipient?.accountDeliveryAddresses?.length > 3
              }
            >
              <div className={styles["saved-addresses__list"]}>
                {checkoutData.recipient?.accountDeliveryAddresses?.map(
                  (address, idx) => (
                    <button
                      key={idx}
                      onClick={() =>
                        selectSafeAddress(
                          formatAddress(address),
                          idx,
                          address.city
                        )
                      }
                      className={cn(styles["saved-addresses__button"], {
                        [styles["saved-addresses__button--selected"]]:
                        selectedAddressIdx === idx
                      })}
                    >
                      <div className={styles["saved-addresses__name"]}>
                        {address.name}
                      </div>
                      <div className={styles["saved-addresses__details"]}>
                        {`${address.city}, ${address.street}, ${
                          address.house
                        }${address.flat ? `, кв. ${address.flat}` : ""}${
                          address.entrance ? `, подъезд ${address.entrance}` : ""
                        }${address.floor ? `, этаж ${address.floor}` : ""}`}
                      </div>
                    </button>
                  )
                )}
              </div>
            </CustomScrollbar>
          </div>
        ) : (
          <p className={styles["saved-addresses__subtitle"]}>
          Сохранённых адресов нет
          </p>
        )}
      <div className={styles["provider-wrapper"]}>
        <h2 className={styles.title}>Кто доставит?</h2>
        <ProvidersTitlesList
          list={props.currentProviders || []}
          providersInfo={props.providersInfo}
          onSelectProvider={props.onSelectProvider}
          chosenProvider={props.chosenProvider}
        />
      </div>
      <CustomButton
        title="Подтвердить выбор"
        onClick={acceptAddress}
        className={styles["select-button"]}
      />
    </div>
  );
};

export default SelectAddressUser;
