import React, { CSSProperties } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

interface LoadingSpinnerCustomProps {
  color?: "light" | "dark" | "danger" | "blue";
  size?: "small" | "large";
  variant?: "default" | "alternative";
}

interface CustomCSSProperties extends CSSProperties {
  "--i"?: number;
}

const LoadingSpinnerCustom: React.FC<LoadingSpinnerCustomProps> = ({
  color = "light",
  size = "small",
  variant = "default"
}) => {
  if (variant === "alternative") {
    return (
      <div className={cn(styles["spinner-alternative"], styles[color], styles[size])}>
        {Array.from({ length: 20 }, (_, i) => (
          <span key={i} style={{ "--i": i + 1 } as CustomCSSProperties}></span>
        ))}
      </div>
    );
  }
  return (
    <div className={cn(styles.spinner, styles[color], styles[size])}></div>
  );
};

export default LoadingSpinnerCustom;
