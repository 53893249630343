import { IGetUserOrderDataItem } from "types/IGetUserOrderData";
import { NavLink } from "react-router-dom";
import { useMemo } from "react";
import { formatPrice } from "utils/helpers/formatedPrice";
import StatusBadge from "components/UI/StatusBadge/StatusBadge";
import { mapStatusToEnum } from "utils/helpers/getStatusStyle";
import { ProfileRoutesList } from "utils/constants/routes";
import ProductCardV2 from "components/ProductCardV2/ProductCardV2";
import { transformOrderOfferToProductWithOffers } from "utils/helpers/transformOrderOfferToProductWithOffers";

import styles from "./styles.module.scss";

interface OrderItemV2Props {
  order: IGetUserOrderDataItem;
}

const OrderItemV2: React.FC<OrderItemV2Props> = ({ order }) => {
  const prices = useMemo(() => {
    const totalPrice = order.orderOffers.reduce((acc, cur) => {
      return acc + cur.sale_price * cur.count;
    }, 0);

    const totalPriceWithDelivery = totalPrice + (order.delivery_amount ?? 0);

    return {
      totalPriceWithDelivery
    };
  }, [order.orderOffers, order.delivery_amount]);


  const formattedDate = useMemo(() => {
    const date = new Date(order.created_at);
    return date.toLocaleString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
  }, [order.created_at]);

  const productsWithOffers = useMemo(() => {
    return order.orderOffers.map(transformOrderOfferToProductWithOffers);
  }, [order.orderOffers]);

  const status = useMemo(() => mapStatusToEnum(order.status), [order.status]);
  const totalSum = useMemo(
    () => `${formatPrice(prices.totalPriceWithDelivery)} ₽`,
    [prices.totalPriceWithDelivery]
  );

  return (
    <div className={styles["order-item"]}>
      <NavLink
        to={`${ProfileRoutesList.MY_ORDERS}/${order.id}`}
        className={styles["order-item__header"]}
      >
        <div className={styles["order-item__header-left"]}>
          <p className={styles["order-item__id"]}>Заказ № {order.id}</p>
          <StatusBadge status={status} />
        </div>
        <div className={styles["order-item__header-right"]}>
          <p className={styles["order-item__sum-label"]}>Сумма</p>
          <p className={styles["order-item__sum"]}>{totalSum}</p>
          <p className={styles["order-item__date"]}>от {formattedDate}</p>
        </div>
      </NavLink>
      <div className={styles["order-item__divider"]}></div>
      <div className={styles["order-item__content"]}>
        {productsWithOffers.map((product) => (
          <ProductCardV2 key={product.id} product={product} isMime={true} />
        ))}
      </div>
    </div>
  );
};

export default OrderItemV2;
