import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IProductWithOffers } from "types/IProduct";
import {
  ISaleData,
  IVolumeData
} from "../../utils/webWorkers/landingProductCardWebWorker";
import { Link } from "react-router-dom";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";
import { removeSpaces } from "utils/helpers/removeSpaces";
import { FavoriteProductButton } from "pages/UserCart/components/CartProducts/components/CartProductItemV2/components/CartProductActions/components/FavoriteProductButton/FavoriteProductButton";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import {
  addUserFavoriteProduct,
  removeUserFavoriteProduct
} from "store/user/userThunks/userThunks";
import NotAvailable from "components/UI/NotAvailable/NotAvailable";
import noImageNew from "../../assets/images/no-image-new.png";
import { toast } from "react-toastify";
import cn from "classnames";
import styles from "./styles.module.scss";
import { ProductProgressiveImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductProgressiveImage/ProductProgressiveImage";
import { IImagesCache } from "types/IImagesCache";
import { formatPrice } from "utils/helpers/formatedPrice";

export type WorkerMessageData =
  | { type: "saleData"; data: ISaleData }
  | { type: "volumeData"; data: IVolumeData }

interface ProductCardV2Props {
  product: IProductWithOffers;
  cardRef?: React.Ref<HTMLAnchorElement>;
  customHeartClass?: string;
  isHorizontal?: boolean;
  isMime?: boolean;
  initialFavoriteStatus?: boolean;
}

const cache = new Map<string, WorkerMessageData[]>();

const ProductCardV2: React.FC<ProductCardV2Props> = ({
  product,
  cardRef,
  customHeartClass,
  isHorizontal = false,
  isMime = false,
  initialFavoriteStatus = false,
}) => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [saleData, setSaleData] = useState<ISaleData>({ salePercent: null });
  const [volumeData, setVolumeData] = useState<IVolumeData>({
    minVolume: null,
    maxVolume: null,
    volumeType: null
  });
  const [loading, setLoading] = useState(true);
  const imagesCacheRef = useRef<IImagesCache>({});

  const priceData = useMemo(() => {
    const productPriceData = {
      minPrice: product.minPrice,
      maxPrice: product.maxPrice
    };
    return productPriceData;
  }, [product.maxPrice, product.minPrice]);

  // Пока не удаляем, возможно зачеркнутая цена пригодиться в каком то кейсе.
  // const oldPriceData = useMemo(() => {
  //   return product.offers[0] ? formatPrice(product.offers[0].oldPrice) : null;
  // }, [product.offers]);

  useEffect(() => {
    const productId = product.id.toString();
    if (cache.has(productId)) {
      const cachedData = cache.get(productId);
      cachedData?.forEach(({ type, data }) => {
        if (type === "saleData") setSaleData(data);
        if (type === "volumeData") setVolumeData(data);
      });
      setLoading(false);
    } else if (product.offers.length > 0) {
      const worker = new Worker(
        new URL(
          "../../utils/webWorkers/landingProductCardWebWorker",
          import.meta.url
        )
      );
      worker.postMessage({ product });

      const workerData: WorkerMessageData[] = [];

      worker.onmessage = (e: MessageEvent<WorkerMessageData>) => {
        const { type, data } = e.data;
        workerData.push({ type, data } as WorkerMessageData);

        if (type === "saleData") setSaleData(data);
        if (type === "volumeData") setVolumeData(data);

        if (workerData.length === 2) {
          cache.set(productId, workerData);
          setLoading(false);
        }
      };

      return () => {
        worker.terminate();
      };
    } else {
      setSaleData({ salePercent: null });
      setVolumeData({ minVolume: null, maxVolume: null, volumeType: null });
      setLoading(false);
    }
  }, [product]);

  const productUrl = isMime
    ? `/product/${product.offers[0].catalog.id}-${product.offers[0].catalog.product_rewrite_name}?type=${product.offers[0].id}`
    : `/product/${product.id}-${product.product_rewrite_name}`;


  const isSinglePrice = priceData.minPrice === priceData.maxPrice;
  const isOutOfStock = priceData.minPrice === 0 && priceData.maxPrice === 0;

  const unitCode =
    volumeData.volumeType === "мл"
      ? "MLT"
      : volumeData.volumeType === "л"
        ? "LTR"
        : volumeData.volumeType === "г"
          ? "GRM"
          : volumeData.volumeType === "кг"
            ? "KGM"
            : volumeData.volumeType === "шт"
              ? "H87"
              : volumeData.volumeType === "упаковка"
                ? "PK"
                : "";

  const [isFavorite, setIsFavorite] = useState(initialFavoriteStatus);

  const onToggleFavoriteStatus = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      e.preventDefault();
      e.stopPropagation();
      try {
        setIsFavorite(!isFavorite);
        if (!isFavorite) {
          dispatch(addUserFavoriteProduct(product.id));
        } else {
          dispatch(removeUserFavoriteProduct({ product: product.id }));
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Произошла ошибка добавить/убрать из избранного товар", err);
        toast.error(
          <div>
              Произошла ошибка добавить/убрать из избранного товар.
            <br />
              ТОВАР: {`${product.name}`}
          </div>
        );
        throw new Error("Произошла ошибка добавить/убрать из избранного товар");
      }
    },
    [dispatch, isFavorite, product.id, product.name]
  );

  const allOffersUnavailable = product.offers.every(
    (offer) => !offer.available
  );

  const productImageLink = useMemo(() => {
    return product.productImage?.thumbnail_q_100?.link
      ? product.productImage.thumbnail_q_100.link
      : product.productImage?.original?.link
        ? product.productImage.original.link
        : noImageNew;
  }, [product]);

  const isNoImage = productImageLink === noImageNew;

  return (
    <Link
      to={productUrl}
      className={cn(styles.card, {
        [styles.unavailable]: allOffersUnavailable,
        [styles.horizontal]: isHorizontal,
        [styles.mime]: isMime
      })}
      ref={cardRef}
      itemScope
      itemType="https://schema.org/Product"
    >
      <div className={styles.image}>
        {isNoImage ? (
          <div className={styles["wrapper-no-image"]}>
            <img src={productImageLink} alt={product.name} itemProp="image" className={styles["no-image"]} />
          </div>
        ) : (
          product.productImage && (
            <ProductProgressiveImage
              thumbnail_q_0={product.productImage.thumbnail_q_0 ?? null}
              thumbnail_q_50={product.productImage.thumbnail_q_50 ?? null}
              thumbnail_q_100={product.productImage.thumbnail_q_100 ?? null}
              original={product.productImage.original}
              imagesCache={imagesCacheRef.current}
              imgProps={{ alt: product.name, itemProp: "image" }}
            />
          )
        )}
        {allOffersUnavailable && !isHorizontal && !isMime && <NotAvailable />}
      </div>
      {!isMime && (
        <div className={styles.details}>
          {(loading ||
            (priceData.minPrice !== null && priceData.maxPrice !== null)) &&
            (!isOutOfStock || isHorizontal) && (
            <span
              className={styles.details__price}
              itemProp="offers"
              itemScope
              itemType="https://schema.org/Offer"
            >
              <meta itemProp="priceCurrency" content="RUB" />
              {loading ? (
                <SkeletonLine style={{ width: "100px" }} />
              ) : isOutOfStock ? (
                <span>Нет в наличии</span>
              ) : isSinglePrice ? (
                <span itemProp="price">{formatPrice(priceData.minPrice)} ₽</span>
              ) : (
                <>
                  <span itemProp="price">{formatPrice(priceData.minPrice)}&#8202;₽</span>
                  <span className={styles.details__separator}>-</span>
                  <span itemProp="price">{formatPrice(priceData.maxPrice)}&#8202;₽</span>
                </>
              )}
            </span>
          )}
          {(loading || saleData.salePercent !== null) && (
            <div className={styles.details__sale}>
              {loading ? (
                <SkeletonLine
                  style={{ width: "85px", height: "25px", borderRadius: "70px" }}
                />
              ) : (
                <>
                  {saleData.salePercent && (
                    <span className={styles.details__sale__percent}>
                      {!isSinglePrice && "до "} -{saleData.salePercent}%
                    </span>
                  )}
                  {/* {!!oldPriceData && (
                    <span className={styles["details__old-price"]}>
                      {oldPriceData}₽
                    </span>
                  )} */}
                </>
              )}
            </div>
          )}
          <p className={styles.details__name} itemProp="name">
            {product.name}
          </p>
          {(loading ||
            (volumeData.minVolume !== null &&
              volumeData.maxVolume !== null &&
              volumeData.volumeType !== null)) && (
            <span className={styles.details__volume}>
              {loading ? (
                <SkeletonLine style={{ width: "25px", height: "10px" }} />
              ) : volumeData.minVolume === volumeData.maxVolume ? (
                <>
                  <span
                    itemProp="additionalProperty"
                    itemScope
                    itemType="https://schema.org/PropertyValue"
                  >
                    <meta itemProp="name" content="size" />
                    <meta
                      itemProp="value"
                      content={removeSpaces(volumeData.minVolume ?? "")}
                    />
                    <meta itemProp="unitCode" content={unitCode} />
                    {`${volumeData.minVolume} ${volumeData.volumeType}`}
                  </span>
                </>
              ) : (
                <>
                  <span
                    itemProp="additionalProperty"
                    itemScope
                    itemType="https://schema.org/PropertyValue"
                  >
                    <meta itemProp="name" content="size" />
                    <meta
                      itemProp="minValue"
                      content={removeSpaces(volumeData.minVolume ?? "")}
                    />
                    <meta
                      itemProp="maxValue"
                      content={removeSpaces(volumeData.maxVolume ?? "")}
                    />
                    <meta itemProp="unitCode" content={unitCode} />
                    {`${volumeData.minVolume} ${volumeData.volumeType} - ${volumeData.maxVolume} ${volumeData.volumeType}`}
                  </span>
                </>
              )}
            </span>
          )}
          <meta itemProp="description" content={product.description} />
        </div>
      )}
      {!!user?.phone && !isHorizontal && !isMime && (
        <div
          className={cn(styles["card__heart_icon-wrapper"], customHeartClass)}
        >
          <FavoriteProductButton
            isFavorite={isFavorite}
            onToggleFavoriteStatus={onToggleFavoriteStatus}
          />
        </div>
      )}
    </Link>
  );
};

export default ProductCardV2;
