import CustomButtonV2 from "components/UI/CustomButtonV2/CustomButtonV2";
import CustomProfileInput from "components/UI/CustomProfileInput/CustomProfileInput";
import CustomRadioButton from "components/UI/CustomRadioButton/CustomRadioButton";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { useFormik } from "formik";
import { personalDataSchema } from "schemas/personalDataSchema";
import { updateUserData } from "store/user/userThunks/userThunks";
import { getErrorData } from "utils/getErrorData";
import { toast } from "react-toastify";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { maskDate } from "utils/helpers/maskDate";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { months, years } from "utils/constants/months";
import { isValidDate } from "utils/isValidDate";
import styles from "./styles.module.scss";
import { USER_INFO_FIELDS_NEW } from "utils/constants/formItems";
import { formatPhoneNumber } from "utils/helpers/formatProfileData";

export interface IFormData {
  surname: string;
  name: string;
  phone: string;
  email: string;
  dob: string;
}

export interface IFormDataNew {
  fullName: string;
  phone: string;
  email: string;
  dob: string;
}

const UserDataUpdate: React.FC = () => {
  const { user, deviceType } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [gender, setGender] = useState(user.gender || "");
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const outside = useRef<HTMLDivElement | null>(null);

  const buttonSize: "extra-small" | "small" | "large" = deviceType.isMobile
    ? "extra-small"
    : deviceType.isTablet
      ? "small"
      : "large";

  const userPhoneNumber = useMemo(() => formatPhoneNumber(user.phone), [user.phone]);

  const formik = useFormik<IFormData>({
    initialValues: {
      name: user.name || "",
      surname: user.surname || "",
      phone: userPhoneNumber,
      email: user.email || "",
      dob: user.birthday || ""
    },
    validationSchema: personalDataSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        const registeredUserData = await dispatch(
          updateUserData({
            surname: user.surname !== formik.values.surname.trim() ? formik.values.surname.trim() : undefined,
            name: user.name !== formik.values.name.trim() ? formik.values.name.trim() : undefined,
            phone: userPhoneNumber !== formik.values.phone ? formik.values.phone.replace(/[^\d]/g, "") : undefined,
            email: user.email !== formik.values.email.trim() ? formik.values.email.trim() : undefined,
            gender: !user?.gender ? gender : undefined,
            birthday: !user.birthday ? formik.values.dob : undefined
          })
        ).unwrap();

        if (!registeredUserData) {
          return;
        }
        toast("Данные успешно обновлены");
      } catch (err) {
        const errorData = getErrorData(err);
        toast(errorData.message);
      }
    }
  });

  const [inputValue, setInputValue] = useState(formik.values.dob || "");

  useEffect(() => {
    const initialValues = {
      name: user.name || "",
      surname: user.surname || "",
      phone: userPhoneNumber,
      email: user.email || "",
      dob: user.birthday || ""
    };

    const checkFormChanges = () => {
      const currentValues = {
        name: formik.values.name,
        surname: formik.values.surname,
        phone: formik.values.phone,
        email: formik.values.email,
        dob: formik.values.dob
      };

      setIsFormChanged(
        JSON.stringify(initialValues) !== JSON.stringify(currentValues)
      );
    };

    checkFormChanges();
  }, [formik.values, user, userPhoneNumber]);

  const handleDateChange = useCallback((date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().split("T")[0];
      formik.setFieldValue("dob", formattedDate);
      setInputValue(formattedDate);
    } else {
      formik.setFieldValue("dob", "");
      setInputValue("");
    }
    setDatePickerVisible(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setFieldValue, setDatePickerVisible]);

  const handleChangeRaw = (event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (event && event.target && event.target instanceof HTMLInputElement) {
      const e = event as unknown as React.ChangeEvent<HTMLInputElement>;
      maskDate(e);
    }
  };

  const onClickOpenDateInput = () => {
    if (formik.values.dob) {
      return;
    }
    if (!isDatePickerVisible) {
      setDatePickerVisible(true);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    maskDate(e);
    const value = e.target.value;
    setInputValue(value);
    if (isValidDate(value)) {
      formik.setFieldValue("dob", value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (e.key === "Backspace" && (target.selectionStart === 5 || target.selectionStart === 8)) {
      e.preventDefault();
      target.setSelectionRange(target.selectionStart - 1, target.selectionStart - 1);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setInputValue("");
      formik.setFieldValue("dob", "");
    }
  };

  useOutsideClick({
    ref: outside,
    cb: () => {
      setDatePickerVisible(false);
      handleScroll();
    }
  });

  const handleScroll = () => {
    setDatePickerVisible(false);
  };

  const getDayClassName = useCallback((date: Date) => {
    const selectedDate = formik.values.dob ? new Date(formik.values.dob) : null;
    return date.toDateString() === selectedDate?.toDateString() ? styles["selected-day"] : "";
  }, [formik.values.dob]);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isFormChanged) {
      toast("Измените данные для сохранения.");
      return;
    }
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        Array.from(Object.values(errors)).forEach((error) => {
          toast.error(error);
        });
      } else {
        formik.handleSubmit();
      }
    });
  };

  const onClickSelectGender = (gender: string) => {
    if (!user?.gender) {
      setGender(gender);
      setIsFormChanged(true);
    }
  };

  return (
    <div className={styles.update}>
      <div className={styles.update__header}>
        <h2 className={styles.update__title}>Данные пользователя</h2>
        <CustomButtonV2
          title="Сохранить"
          type="submit"
          onClick={handleButtonClick}
          size={buttonSize}
          color="primary"
        />
      </div>
      <div className={styles.update__divider}></div>
      <form className={styles.update__form}>
        <div className={styles.update__row}>
          {USER_INFO_FIELDS_NEW.slice(0, 2).map((field) => (
            <CustomProfileInput
              key={field.id}
              placeholder={`Введите ${field.title}`}
              label={field.title}
              name={field.name}
              value={formik.values[field.name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              customWrapperStyles={styles["full-width"]}
              isRequired={field.isRequiredField}
              isDisabled={field.isReadOnly}
            />
          ))}
        </div>
        <div className={styles.update__row}>
          {USER_INFO_FIELDS_NEW.slice(2).map((field) => (
            <CustomProfileInput
              key={field.id}
              placeholder={
                field.name === "email" ? "Введите Электронную почту" : `Введите ${field.title}`
              }
              label={field.title}
              name={field.name}
              value={formik.values[field.name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              customWrapperStyles={styles["full-width"]}
              isRequired={field.isRequiredField}
              isDisabled={field.isReadOnly}
            />
          ))}
        </div>
        <div className={styles.update__divider}></div>
        <div className={styles.update__row}>
          <div className={styles["dob-button-wrapper"]}>
            <label className={styles["label"]}>Дата рождения</label>
            <CustomDatePicker
              locale="ru"
              dateFormat="dd-MM-yyyy"
              placeholderText="__ / __ / ____"
              maxDate={new Date()}
              customInputStyles={styles["dob-button-custom"]}
              selected={formik.values.dob ? new Date(formik.values.dob) : null}
              onChange={handleDateChange}
              onChangeRaw={handleChangeRaw}
              onClickOpenDateInput={onClickOpenDateInput}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
              handleBlur={handleBlur}
              inputValue={inputValue}
              years={years}
              months={months}
              dayClassName={getDayClassName}
              disabledDobSelect={!!user.birthday}
              disabled={!!user.birthday}
            />
          </div>
          <div className={styles["update__radio-group"]}>
            <label className={styles["update__radio-label"]}>Пол</label>
            <div className={styles["update__radio-buttons"]}>
              <CustomRadioButton
                label="Женский"
                name="gender"
                onCustomChange={() => onClickSelectGender("female")}
                checked={gender === "female"}
                disabled={!!user.gender}
              />
              <CustomRadioButton
                label="Мужской"
                name="gender"
                onCustomChange={() => onClickSelectGender("male")}
                checked={gender === "male"}
                disabled={!!user.gender}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserDataUpdate;
