import { SkeletonImg } from "../SkeletonImg/SkeletonImg";
import { SkeletonLine } from "../SkeletonLine/SkeletonLine";
import cn from "classnames";
import styles from "./styles.module.scss";

interface ISkeletonBestsellerCardProps {
  isMime?: boolean;
  isProfile?: boolean;
};

const SkeletonBestsellerCard: React.FC<ISkeletonBestsellerCardProps> = ({
  isMime = false,
  isProfile = false
}) => {
  return (
    <div className={cn(styles.card, {
      [styles.mime]: isMime,
      [styles.profile]: isProfile
    })}>
      <SkeletonImg className={styles["custom-avatar"]}/>
      {!isMime && (
        <div className={styles.card__details}>
          <SkeletonLine style={{ width: "220px", height: "34px", borderRadius: "20px" }} />
          <SkeletonLine style={{ width: "181px", height: "20px", borderRadius: "20px" }} />
          <SkeletonLine style={{ width: "201px", height: "20px", borderRadius: "20px" }} />
          <SkeletonLine style={{ width: "135px", height: "20px", borderRadius: "20px" }} />
        </div>
      )}
    </div>
  );
};

export default SkeletonBestsellerCard;