interface DangerCircleIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const DangerCircleIcon: React.FC<DangerCircleIconProps> = ({ width = 17, height = 17, color = "#FF0044" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.00008 2.12516C5.20282 2.12516 2.12508 5.2029 2.12508 9.00016C2.12508 12.7967 5.20288 15.8752 9.00008 15.8752C12.7965 15.8752 15.8751 12.7966 15.8751 9.00016C15.8751 5.20296 12.7966 2.12516 9.00008 2.12516ZM0.458416 9.00016C0.458416 4.28243 4.28235 0.458496 9.00008 0.458496C13.7169 0.458496 17.5417 4.28237 17.5417 9.00016C17.5417 13.7171 13.717 17.5418 9.00008 17.5418C4.28229 17.5418 0.458416 13.717 0.458416 9.00016Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.996 5.00366C9.45624 5.00366 9.82933 5.37676 9.82933 5.837V9.5195C9.82933 9.97973 9.45624 10.3528 8.996 10.3528C8.53576 10.3528 8.16267 9.97973 8.16267 9.5195V5.837C8.16267 5.37676 8.53576 5.00366 8.996 5.00366Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.1625 12.1634C8.1625 11.7032 8.53559 11.3301 8.99583 11.3301H9.00416C9.4644 11.3301 9.8375 11.7032 9.8375 12.1634C9.8375 12.6236 9.4644 12.9967 9.00416 12.9967H8.99583C8.53559 12.9967 8.1625 12.6236 8.1625 12.1634Z" fill={color}/>
    </svg>
  );
};

export default DangerCircleIcon;
