const streetPatterns = [
  "ул",
  "улица",
  "пр",
  "просп",
  "проспект",
  "пер",
  "переулок",
  "б-р",
  "бульвар",
  "ш",
  "шоссе",
  "наб",
  "набережная",
  "пл",
  "площадь",
  "проезд",
  "аллея",
  "пр-кт",
  "ал",
  "взв",
  "взд",
  "дор",
  "ззд",
  "км",
  "к-цо",
  "коса",
  "лн",
  "мгстр",
  "пер-д",
  "пл-ка",
  "пр-д",
  "пр-к",
  "пр-ка",
  "пр-лок",
  "проул",
  "рзд",
  "ряд",
  "с-р",
  "с-к",
  "сзд",
  "тракт",
  "туп"
];

const housePatterns = [
  "д",
  "дом",
  "влд",
  "двлд",
  "г-ж",
  "зд",
  "з/у",
  "кв",
  "ком",
  "подв",
  "кот",
  "п-б",
  "к",
  "ОНС",
  "офис",
  "пав",
  "помещ",
  "раб.уч",
  "скл",
  "coop",
  "стр",
  "торг.зал",
  "цех"
];

const isStreet = (part: string): boolean => {
  return streetPatterns.some((pattern) => {
    const lowerPart = part.toLowerCase();
    return (
      lowerPart.startsWith(pattern) &&
      (lowerPart.length === pattern.length || lowerPart[pattern.length] === " ")
    );
  });
};

const isHouse = (part: string): boolean => {
  return housePatterns.some((pattern) => {
    const lowerPart = part.toLowerCase();
    return (
      lowerPart.startsWith(pattern) &&
      (lowerPart.length === pattern.length || lowerPart[pattern.length] === " ")
    );
  });
};

export const processAddress = (address: string): string => {
  const parts = address.split(",");
  let street = "";
  let house = "";

  parts.forEach((part) => {
    const trimmedPart = part.trim();
    const subParts = trimmedPart.split(" ");
    if (subParts.some(isStreet)) {
      street = trimmedPart;
    } else if (subParts.some(isHouse)) {
      house = trimmedPart;
    }
  });

  let result = "";
  if (street) {
    result = street;
    if (house) {
      result += `, ${house}`;
    }
  }

  return result;
};
