import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import SectionTitle from "../../components/SectionTitle/SectionTitle";
import PaymentStatusNotification from "./components/PaymentStatusNotification/PaymentStatusNotification";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IOrderStatus } from "types/IOrderStatus";
import { CustomError, getErrorData } from "utils/getErrorData";
import { userApi } from "api/userApi";
import { toast } from "react-toastify";
import { isError } from "store/user/userThunks/userThunks";

import styles from "./styles.module.scss";

const PaymentStatusPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderUUID = queryParams.get("order_uuid");
  const [orderStatus, setOrderStatus] = useState<IOrderStatus | null>(null);

  useEffect(() => {
    const fetchOrderStatus = async () => {
      if (!orderUUID) {
        return;
      }

      try {
        const response = await userApi.getOrderStatus(orderUUID);

        if (isError(response)) {
          const errorData = getErrorData(response);
          throw new CustomError(errorData.message, errorData?.code);
        }

        setOrderStatus(response.response);
      } catch (error) {
        const errorData = getErrorData(error);
        toast.error(errorData.message);
      }
    };

    fetchOrderStatus();
  }, [orderUUID]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      </HelmetProvider>
      <div className={styles.root}>
        <div className={styles["title-wrapper"]}>
          <Link to="/cart" className={styles.link}>
            {"< вернуться в корзину"}
          </Link>
          <SectionTitle title="Статус оплаты заказа" />
        </div>
        <div className={styles["status-wrapper"]}>
          {orderStatus ? (
            <PaymentStatusNotification
              orderNumber={orderStatus?.id}
              date={orderStatus?.created_at}
              payments={orderStatus?.payments || []}
            />
          ) : (
            <p className={styles["no-order-payment"]}>Данных об оплате заказа нет</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentStatusPage;
