import React from "react";
import styles from "./styles.module.scss";
import { SOCIAL_MEDIA } from "utils/constants/authSocialMedia";
import SocialMediaLinkNew from "./components/SocialMediaLinkNew";

const LinkingToSocial: React.FC = () => {
  return (
    <div className={styles.social}>
      <h3 className={styles.social__title}>Привязка к социальным сетям</h3>
      <div className={styles.social__items}>
        {SOCIAL_MEDIA.map((item) => (
          <div key={item.id} className={styles.social__item}>
            <div className={styles.social__type}>
              <img
                src={item.img}
                alt={item.alt}
                className={styles.social__icon}
              />
              <span className={styles.social__subtitle}>
                {item.title}
              </span>
            </div>
            <SocialMediaLinkNew
              service={item.service}
              vendorName={item.vendorName}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkingToSocial;