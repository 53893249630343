import { useNavigate, useParams } from "react-router-dom";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import OrderStatusAndDate from "./components/OrderStatusAndDate/OrderStatusAndDate";
import OrderInfo from "./components/OrderInfo/OrderInfo";
import OrderTotalSum from "./components/OrderTotalSum/OrderTotalSum";
import OrderTotalItems from "./components/OrderITotalItems/OrderITotalItems";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { getUserOrdersThunk } from "store/user/userThunks/userThunks";
import { useEffect } from "react";
import LoadingSpinnerCustom from "components/UI/LoadingSpinnerCustom/LoadingSpinnerCustom";
import styles from "./styles.module.scss";
import { toast } from "react-toastify";
import usePreviousRoute from "utils/hooks/usePreviousRouteInProfile";
import CustomButtonV2 from "components/UI/CustomButtonV2/CustomButtonV2";


const OrderDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { orders, isOrdersLoading, error } = useAppSelector((state) => state.user.orders);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const goBack = usePreviousRoute();

  useEffect(() => {
    const currentOrder = orders.find(order => order.id.toString() === id);
    if (!currentOrder) {
      dispatch(getUserOrdersThunk({}));
    }
  }, [dispatch, orders, id]);

  useEffect(() => {
    if (error) {
      toast.info("Произошла ошибка получения информации по заказу, попробуйте позже.");
      navigate("/profile/my-orders");
    }
  }, [error, navigate]);

  const order = orders.find(order => order.id.toString() === id);

  if (isOrdersLoading) {
    return (
      <div className={styles.content__spinner}>
        <LoadingSpinnerCustom color="dark" variant="alternative" />
      </div>
    );
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error("Error:", error.message);
    return null;
  }

  if (!order && !isOrdersLoading) {
    return (
      <ProfileWrapper title="Подробности заказа">
        <div className={styles["empty"]}>
          <p className={styles["empty__text"]}>
          Заказ не найден.
          </p>
          <CustomButtonV2
            title="Назад"
            onClick={goBack}
            size="large"
            color="secondary"
            iconLeft="Arrow"
            iconLeftProps={{ direction: "left" }}
          />
        </div>
      </ProfileWrapper>
    );
  }

  return (
    <ProfileWrapper title={`Заказ № ${id}`} titleClassName={styles["custom-title-class"]}>
      {order && (
        <article>
          <OrderStatusAndDate status={order.status} date={order.created_at} />
          <div className={styles.info}>
            <OrderInfo order={order} />
            <OrderTotalSum order={order} />
          </div>
          <OrderTotalItems items={order.orderOffers} />
        </article>
      )}
    </ProfileWrapper>
  );
};

export default OrderDetails;