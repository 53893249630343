import React, { memo, useMemo } from "react";
import CustomLinkV2 from "components/UI/CustomLinkV2/CustomLinkV2";
import styles from "./styles.module.scss";
import { ProfileRoutesList } from "utils/constants/routes";
import HeartIcon from "assets/profile/icons/HeartIcon";

import ProductCardV2 from "components/ProductCardV2/ProductCardV2";
import SkeletonHorizontalCard from "components/UI/Skeleton/SkeletonHorizontalCard/SkeletonHorizontalCard";
import { useAppSelector } from "store/reduxHooks";
import {
  getCorrectWordForm,
  productWordForms
} from "utils/helpers/getCorrectWordForm";

const FavoritesPreview: React.FC = memo(() => {
  const { deviceType, user, isFavoriteLoading } = useAppSelector(
    (state) => state.user
  );
  const products = user.favouriteProducts;
  const hasProducts = products.length > 0;
  const linkSize =
    deviceType.isTablet || deviceType.isMobile ? "small" : "large";
  const productCount = products.length;
  const productWord = useMemo(
    () => getCorrectWordForm(productCount, productWordForms),
    [productCount]
  );
  const catalogLink = "/catalog";
  const favoritesLink = ProfileRoutesList.FAVORITES;
  const linkTo = hasProducts ? favoritesLink : catalogLink;

  return (
    <div className={styles["faivorite-prewiev"]}>
      <div className={styles["faivorite-prewiev__header"]}>
        <div className={styles["faivorite-prewiev__title-container"]}>
          <h2 className={styles["faivorite-prewiev__title"]}>Избранное</h2>
          <span className={styles["faivorite-prewiev__quantity"]}>
            {productCount} {productWord}
          </span>
        </div>
        {hasProducts && (
          <CustomLinkV2
            to={ProfileRoutesList.FAVORITES}
            title="Посмотреть все"
            size={linkSize}
            color="secondary"
            iconRight="Arrow"
            iconRightProps={{ direction: "right", width: 12, height: 12 }}
          />
        )}
      </div>
      {isFavoriteLoading ? (
        <div className={styles["faivorite-prewiev__product-cards"]}>
          <div className={styles["faivorite-prewiev__divider"]}></div>
          <SkeletonHorizontalCard />
          <div className={styles["faivorite-prewiev__divider"]}></div>
          <SkeletonHorizontalCard />
        </div>
      ) : hasProducts ? (
        <>
          <div className={styles["faivorite-prewiev__divider"]}></div>
          <div className={styles["faivorite-prewiev__product-cards"]}>
            {products.slice(0, 2).map((product, index) => (
              <React.Fragment key={product.product.id}>
                {index > 0 && (
                  <div className={styles["faivorite-prewiev__divider"]}></div>
                )}
                <ProductCardV2 product={product.product} isHorizontal />
              </React.Fragment>
            ))}
          </div>
        </>
      ) : (
        <div className={styles["faivorite-prewiev__empty"]}>
          <p className={styles["faivorite-prewiev__empty-text"]}>
            Нажмите{" "}
            <span className={styles["faivorite-prewiev__icon-wrapper"]}>
              <HeartIcon color="gray" height={18} width={20} />
            </span>{" "}
            , чтобы добавить товары в избранное
          </p>
          <CustomLinkV2
            to={linkTo}
            title="Перейти в каталог"
            size="large"
            color="secondary"
            iconRight="Arrow"
            iconRightProps={{ direction: "right", width: 12, height: 12 }}
          />
        </div>
      )}
    </div>
  );
});

export default FavoritesPreview;

FavoritesPreview.displayName = "FavoritesPreview";
