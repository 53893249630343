import { Link, useNavigate } from "react-router-dom";
import { useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useAppDispatch } from "store/reduxHooks";
import {
  isError,
  restoreUserPassword
} from "../../../../../store/user/userThunks/userThunks";
import { toast } from "react-toastify";

import CustomAuthInput from "../../../../../components/CustomAuthInput/CustomAuthInput";
import CustomButton from "../../../../../components/CustomButton/CustomButton";

import styles from "./styles.module.scss";
import { ICodeInput } from "types/ICodeInput";
import { CustomError, getErrorData } from "utils/getErrorData";

interface IRestorePasswordByActionProps {
  restoreType: string;
}

interface IRestoreByActionFormData {
  phone: string;
  codeFirstValue: string;
  codeSecondValue: string;
  codeThirdValue: string;
  codeFourthValue: string;
  password: string;
  [key: string]: string;
}

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^\+?[7 ][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;

const numberRegExp = /\d+/g;
const emptyStringRegExp = /^\s+$/;

const CODE_INPUTS_PHONE: ICodeInput[] = [
  {
    id: 0,
    name: "codeFirstValue"
  },
  {
    id: 1,
    name: "codeSecondValue"
  },
  {
    id: 2,
    name: "codeThirdValue"
  },
  {
    id: 3,
    name: "codeFourthValue"
  }
];

const RestorePasswordByAction: React.FC<IRestorePasswordByActionProps> = (
  props
) => {
  const [showErrorsOnSubmit, setShowErrorsOnSubmit] = useState(false);
  const [errorFromRequest, setErrorFromRequest] = useState<null | string>(null);
  const [showCodeInputs, setShowCodeInputs] = useState(false);

  const inputRefs: React.RefObject<HTMLInputElement>[] = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null)
  ];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const buttonTitle = useMemo(() => {
    if (props.restoreType === "callMe") {
      return showCodeInputs ? "Отправить" : "Позвоните мне";
    }
    return showCodeInputs ? "Отправить" : "Отправить мне СМС";
  }, [props.restoreType, showCodeInputs]);

  const formik = useFormik<IRestoreByActionFormData>({
    initialValues: {
      phone: "",
      codeFirstValue: "",
      codeSecondValue: "",
      codeThirdValue: "",
      codeFourthValue: "",
      password: ""
    },
    validationSchema: yup.object().shape({
      id: yup.string(),
      phone: yup
        .string()
        .matches(phoneRegExp, "Номер телефона некорректный")
        .required("Номер телефона является обязательным"),
      codeFirstValue: yup.string().matches(numberRegExp),
      codeSecondValue: yup.string().matches(numberRegExp),
      codeThirdValue: yup.string().matches(numberRegExp),
      codeFourthValue: yup.string().matches(numberRegExp),
      password: yup.string()
    }),
    onSubmit: async () => {
      try {
        const formattedPhoneNumber = formik.values.phone.replace(/[^\d]/g, "");
        const restoreData = {
          method: "phone",
          contact: formattedPhoneNumber,
          code: `${formik.values.codeFirstValue}${formik.values.codeSecondValue}${formik.values.codeThirdValue}${formik.values.codeFourthValue}`,
          new_password: formik.values.password
        };

        const restoreResponse = await dispatch(
          restoreUserPassword(restoreData)
        ).unwrap();

        if (isError(restoreResponse)) {
          throw new CustomError(restoreResponse.message, restoreResponse?.code);
        }

        setShowCodeInputs(true);
        toast(restoreResponse.response);

        const successMessage = "Пароль успешно изменен";

        if (restoreResponse.response === successMessage) {
          navigate("/sign-in", { replace: true });
        }
      } catch (err) {
        const errorData = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error(
          "При попытке восстановить пароль по телефону произошла ошибка:",
          errorData
        );
        toast.error(errorData.message);
        setErrorFromRequest(errorData.message);
      }
    }
  });

  const onChangeCodeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const { value } = e.target;
    const isValidValue =
      (formik.values[type].length > 0 && !value.length) ||
      (!isNaN(+value) && !emptyStringRegExp.test(value));

    if (isValidValue) {
      const index = e.target.dataset.index ? +e.target.dataset.index : 0;
      const value = e.target.value;

      if (index < CODE_INPUTS_PHONE.length - 1 && value) {
        inputRefs[index + 1]?.current?.focus();
        inputRefs[index + 1]?.current?.select();
      }
      setErrorFromRequest(null);
      formik.handleChange(e);
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorFromRequest(null);
    formik.handleChange(e);
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowErrorsOnSubmit(true);
    formik.handleSubmit();
  };

  return (
    <main className={styles.root}>
      <h2 className={styles.title}>Восстановление пароля</h2>
      {props.restoreType === "call" ? (
        <>
          <p className={styles["phone-number"]}>+ 7(918)456-54-55</p>
          <a href="tel:89184565455" className={styles.link}>
            <span>Позвонить на бесплатный номер</span>
          </a>
        </>
      ) : (
        <form method="post" onSubmit={onSubmitHandler} className={styles.form}>
          {!showCodeInputs ? (
            <CustomAuthInput
              name="phone"
              inputType="tel"
              placeholder="Телефон"
              value={formik.values.phone}
              onChange={onChangeHandler}
              errorValue={formik.errors.phone || errorFromRequest || undefined}
              showErrorsOnSubmit={showErrorsOnSubmit}
              className={styles.input}
              showErrorIfErrorInResponse={!!errorFromRequest}
            />
          ) : (
            <>
              <div className={styles["code-content"]}>
                <div className={styles["code-content__inputs-wrapper"]}>
                  {CODE_INPUTS_PHONE.map((item) => {
                    return (
                      <input
                        key={item.id}
                        ref={inputRefs[item.id]}
                        name={item.name}
                        type="text"
                        data-index={item.id}
                        value={formik.values[item.name]}
                        onChange={(e) => onChangeCodeHandler(e, item.name)}
                        className={styles["code-content__input"]}
                        maxLength={1}
                        autoComplete="off"
                        autoFocus={item.id === 0}
                      />
                    );
                  })}
                </div>
                {!!errorFromRequest && (
                  <span className={styles["error-message"]}>
                    {errorFromRequest}
                  </span>
                )}
              </div>
              <CustomAuthInput
                name="password"
                inputType="password"
                placeholder="Новый пароль"
                value={formik.values.password}
                onChange={onChangeHandler}
                errorValue={formik.errors.password}
                showErrorsOnSubmit={showErrorsOnSubmit}
                className={styles.password}
              />
            </>
          )}
          <CustomButton
            title={buttonTitle}
            className={styles.button}
            type="submit"
            onClick={onSubmitHandler}
          />
        </form>
      )}
      <Link
        to="/sign-in/restore/by-phone"
        replace
        className={styles["link-secondary"]}
      >
        {"< Шаг назад"}
      </Link>
    </main>
  );
};

const propTypes = {
  restoreType: PropTypes.string.isRequired
};

RestorePasswordByAction.propTypes = propTypes;

export default RestorePasswordByAction;
