export const productWordForms: [string, string, string] = [
  "товар",
  "товара",
  "товаров"
];

export function getCorrectWordForm(
  count: number,
  wordForms: [string, string, string]
): string {
  const cases = [2, 0, 1, 1, 1, 2];
  return wordForms[
    count % 100 > 4 && count % 100 < 20
      ? 2
      : cases[count % 10 < 5 ? count % 10 : 5]
  ];
}
