import { useLocation, useNavigate } from "react-router-dom";
import { ProfileRoutesList } from "utils/constants/routes";

const usePreviousRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getPreviousRoute = () => {
    if (location.pathname === ProfileRoutesList.FAVORITES) {
      return ProfileRoutesList.PROFILE;
    } else if (location.pathname === ProfileRoutesList.MY_ORDERS) {
      return ProfileRoutesList.PROFILE;
    } else if (location.pathname === ProfileRoutesList.PERSONAL_DATA) {
      return ProfileRoutesList.PROFILE;
    } else if (/^\/profile\/my-orders\/\d+$/.test(location.pathname)) {
      return ProfileRoutesList.MY_ORDERS;
    } else {
      return ProfileRoutesList.PROFILE;
    }
  };

  const goBack = () => {
    const previousRoute = getPreviousRoute();
    navigate(previousRoute);
  };

  return goBack;
};

export default usePreviousRoute;