import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { WAY_TO_GET } from "../../../../../utils/constants";
import ProvidersFullList from "./components/ProvidersFullList/ProvidersFullList";
import searchIcon from "../../../../../assets/header_icons/search-icon.svg";

import styles from "./styles.module.scss";
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import { setWayToGet } from "../../../../../store/user/user";
import cn from "classnames";
import Checkbox from "../../../../../components/UI/Checkbox/Checkbox";
import useOutsideClick from "../../../../../utils/hooks/useOutsideClick";
import SelectCityModal from "../../../../../components/SelectCityModal/SelectCityModal";
import {
  ProvidersInfo,
  SelectedProvider,
  SelectedProviderData,
  ToPointDetail
} from "types/IProviderInfo";
import { isToPointDetail } from "utils/helpers/isToPointDetail";
import { WayToGet } from "types/IWayToGet";

interface IProvidersListWithAddressesProps {
  providersInfo: ProvidersInfo | null;
  selectedProvider: SelectedProvider;
  onToggleShowProviderInfo: (value: boolean) => void;
  changeSelectedProviderData: (value: SelectedProviderData | null) => void;
  setCity: (city: string) => Promise<void>;
  setSelectedProvider: (
    data: SelectedProvider | ((prevState: SelectedProvider) => SelectedProvider)
  ) => void;
}

const ProvidersListWithAddresses: React.FC<IProvidersListWithAddressesProps> = (
  props
) => {
  const { currentCity, checkoutData } = useAppSelector((state) => state.user);
  const [searchValue, setSearchValue] = useState("");
  const [showSelectCities, setShowSelectCities] = useState(false);

  const dispatch = useAppDispatch();
  const selectCitiesModalRef = useRef(null);

  const initAddresses = useMemo(() => {
    const wayToGetKey = Object.entries(WAY_TO_GET).find((item) => {
      return item[1] === checkoutData.wayToGet;
    })?.[0] as "toDoor" | "toPoint";
    return props.providersInfo?.[wayToGetKey];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.providersInfo]);

  const [addresses, setAddresses] = useState(initAddresses);

  const showAddressesList = useMemo(() => {
    return addresses?.some((item) => {
      if (isToPointDetail(item)) {
        return item?.addresses?.length > 0;
      }
    });
  }, [addresses]);

  useOutsideClick({
    ref: selectCitiesModalRef,
    cb: () => setShowSelectCities(false)
  });

  const onChangeHandler = (value: string) => {
    if (!Array.isArray(initAddresses)) {
      return;
    }
    const regex = new RegExp(`${value}`, "gi");
    const filteredAddresses = initAddresses.reduce<ToPointDetail[]>((acc, item) => {
      if (isToPointDetail(item)) {
        const itemToPoint = item;
        if (!itemToPoint.addresses) return acc;

        const filtered = itemToPoint.addresses.filter((elem) =>
          elem.address?.match(regex)
        );

        if (filtered.length > 0) {
          acc.push({
            ...item,
            addresses: filtered
          });
        }
      }
      return acc;
    }, []);
    setSearchValue(value);
    setAddresses(filteredAddresses);
  };

  const selectWayToGet = (way: WayToGet) => {
    dispatch(setWayToGet(way));
  };

  useEffect(() => {
    if (!initAddresses) return;

    onChangeHandler("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initAddresses]);

  const checkProvidersAvailability = () => {
    if (!props.providersInfo || !Array.isArray(props.providersInfo.toPoint)) {
      return { cdek: true, boxberry: true, x5: true };
    }

    const availability: SelectedProvider = {
      cdek: false,
      boxberry: false,
      x5: false,
      parfumart: false
    };

    props.providersInfo.toPoint.forEach((item) => {
      if (item.addresses && item.addresses.length > 0) {
        availability[item.deliveryProvider] = true;
      }
    });

    return {
      cdek: !availability.cdek,
      boxberry: !availability.boxberry,
      x5: !availability.x5,
      parfumart: !availability.parfumart
    };
  };

  const providersDisabledStatus = checkProvidersAvailability();

  useEffect(() => {
    const availability = checkProvidersAvailability();

    props.setSelectedProvider({
      cdek: !availability.cdek,
      boxberry: !availability.boxberry,
      x5: !availability.x5,
      parfumart: !availability.parfumart
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCity, props.providersInfo]);

  return (
    <div className={styles.root}>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title={WAY_TO_GET.toPoint}
          onClick={() => selectWayToGet(WAY_TO_GET.toPoint)}
          isBlack={checkoutData.wayToGet === WAY_TO_GET.toPoint}
          isWhite={checkoutData.wayToGet !== WAY_TO_GET.toPoint}
          className={cn(styles.buttons__pickup, {
            [styles["buttons--active"]]:
              checkoutData.wayToGet === WAY_TO_GET.toPoint
          })}
        />
        <CustomButton
          title={WAY_TO_GET.toDoor}
          onClick={() => selectWayToGet(WAY_TO_GET.toDoor)}
          isBlack={checkoutData.wayToGet === WAY_TO_GET.toDoor}
          isWhite={checkoutData.wayToGet !== WAY_TO_GET.toDoor}
          className={cn(styles.buttons__delivery, {
            [styles["buttons--active"]]:
              checkoutData.wayToGet === WAY_TO_GET.toDoor
          })}
        />
      </div>
      <div className={styles["provider-wrapper"]}>
        <div className={styles["provider_item"]}>
          <Checkbox
            setIsSelected={() =>
              props.setSelectedProvider((prev) => {
                return { ...prev, cdek: !prev["cdek"] };
              })
            }
            isSelected={props.selectedProvider["cdek"]}
            isDisabled={providersDisabledStatus.cdek}
          />
          <span
            className={cn(styles["provider_item--text"], {
              [styles["text-disabled"]]: providersDisabledStatus.cdek
            })}
          >
            СДЭК
          </span>
        </div>
        <div className={styles["provider_item"]}>
          <Checkbox
            setIsSelected={() =>
              props.setSelectedProvider((prev) => {
                return { ...prev, x5: !prev["x5"] };
              })
            }
            isSelected={props.selectedProvider["x5"]}
            isDisabled={providersDisabledStatus.x5}
          />
          <span
            className={cn(styles["provider_item--text"], {
              [styles["text-disabled"]]: providersDisabledStatus.x5
            })}
          >
            5Post
          </span>
          <span className={styles["provider_item--online-payment"]}>
            Только онлайн оплата
          </span>
        </div>
        <div className={styles["provider_item"]}>
          <Checkbox
            setIsSelected={() =>
              props.setSelectedProvider((prev) => {
                return { ...prev, boxberry: !prev["boxberry"] };
              })
            }
            isSelected={props.selectedProvider["boxberry"]}
            isDisabled={providersDisabledStatus.boxberry}
          />
          <span
            className={cn(styles["provider_item--text"], {
              [styles["text-disabled"]]: providersDisabledStatus.boxberry
            })}
          >
            Boxberry
          </span>
          <span className={styles["provider_item--online-payment"]}>
            Только онлайн оплата
          </span>
        </div>
      </div>
      <h2 className={styles.title}>Куда доставить заказ?</h2>
      <button
        className={styles.city__button}
        onClick={() => setShowSelectCities(true)}
      >
        {`${checkoutData.orderCity || currentCity}`}
      </button>
      {showSelectCities && (
        <SelectCityModal
          modalRef={selectCitiesModalRef}
          onClose={() => setShowSelectCities(false)}
          onCloseCb={() => setShowSelectCities(false)}
          setCity={props.setCity}
          containerStyles={styles.city__modal}
          citiesContainerStyles={styles["city__cities-container"]}
        />
      )}
      <div className={styles["input-wrapper"]}>
        <input
          type={searchValue}
          onChange={(e) => onChangeHandler(e.target.value)}
          className={styles.input}
          placeholder="Введите адрес"
        />
        <img
          src={searchIcon}
          alt="search-icon"
          className={styles["search-icon"]}
        />
      </div>
      <div className={styles["list-wrapper"]}>
        {showAddressesList && (
          <ProvidersFullList
            searchValue={searchValue}
            currentAddresses={addresses ?? []}
            selectedProvider={props.selectedProvider}
            onToggleShowProviderInfo={props.onToggleShowProviderInfo}
            changeSelectedProviderData={props.changeSelectedProviderData}
          />
        )}
      </div>
    </div>
  );
};

export default ProvidersListWithAddresses;
