import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";

import { productsApi } from "../../api/productsApi";

import { getQueryStringForRequest } from "../../utils/helpers/catalogPageHelper";
import { useFiltersV3 } from "./hooks/useFiltersV3";
import useCatalogPagination from "./hooks/useCatalogPagination";
import useSearchParamsHandler, {
  Params
} from "../../utils/hooks/useSearchParamsHandler";
import useCatalogProducts from "./hooks/useCatalogProducts";
import useScrollRestoration from "utils/hooks/useScrollRestoration";
import { searchByCanonical } from "utils/helpers/searchByCanonical";
import { getCanonicalUrl } from "utils/helpers/getCanonicalUrl";
import { setSelectedFilters, toggleSearchTrigger } from "store/catalogV3";
import SortButton from "pages/Catalog/components/SortButton/SortButton";

import CatalogContent from "./components/CatalogContent/CatalogContent";
import LastProductsViewBlock from "./components/LastProductsViewBlock/LastProductsViewBlock";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { HeadHelmet } from "../../utils";
import { H1TextVariables, h1TextVariables } from "./h1TextVariables";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import SelectedFiltersV3 from "./components/CatalogFiltersV3/SelectedFiltersV3/SelectedFiltersV3";
import MainFiltersV3 from "./components/CatalogFiltersV3/MainFiltersV3/MainFiltersV3";
import MobileFiltersButtonV3 from "./components/MobileFiltersButtonV3/MobileFiltersButtonV3";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { IBreadcrumb } from "utils/breadcrumbsUtils";
import { getErrorData, REJECT_ERROR_CODE } from "utils/getErrorData";
import { isError } from "store/user/userThunks/userThunks";
import { IProductWithOffers } from "types/IProduct";
import { IEcommerceYandex } from "types/IEcommerceYandex";

import styles from "./styles.module.scss";

interface ICategoryCounts {
  [key: string]: number;
}

interface ParamsObject {
  [key: string]: string;
}

interface ITitles {
  kosmetika: string;
  parfumeria: string;
}

const TITLES: ITitles = {
  kosmetika: "Косметика",
  parfumeria: "Парфюмерия"
};

type CatalogSectionKey = keyof ITitles;

const SCROLL_OFFSET_TOP = 100;

const Catalog = () => {
  const { filtersV3, deviceType } = useAppSelector((state) => ({
    filtersV3: state.catalogV3.filtersV3,
    deviceType: state.user.deviceType
  }));
  const dispatch = useAppDispatch();
  const location = useLocation();
  const maxProductsPerPage = deviceType.isMobile ? 10 : 18;
  const isNotMobileDevice = !deviceType.isMobile && !deviceType.isTablet;
  const [metaData, setMetaData] = useState({ title: "", description: "" });
  const [h1Text, setH1Text] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [disableScrollRestoration, setDisableScrollRestoration] =
    useState(false);
  const [disableScroll, setDisableScroll] = useState(false);
  const [isMoreProductLoading, setIsMoreProductsLoading] = useState(false);
  const { handleSetShouldGetFiltersStatus } = useFiltersV3();

  const { searchParams, onSaveParams, onChangeSearchParams, onDeleteParams } =
    useSearchParamsHandler();

  useEffect(() => {
    setIsDataLoaded(true);
    return () => {
      setIsDataLoaded(false);
    };
  }, []);

  useScrollRestoration(
    isDataLoaded,
    isNotMobileDevice,
    disableScrollRestoration,
    disableScroll
  );

  const {
    products,
    productsAmount,
    isLoadingProducts,
    handleSetProducts,
    handleChangeShouldGetProductsStatus
  } = useCatalogProducts();

  const { limit, offset, currentPage, onChangeOffset, onChangeCurrentPage } =
    useCatalogPagination({
      initLimit: maxProductsPerPage,
      initOffset: +(searchParams.get("offset") || 0)
    });

  const contentRef = useRef<HTMLElement | null>(null);

  const currentCatalogSection = useMemo(() => {
    const currentMatch = location.pathname.match(/kosmetika|parfumeria/g);
    if (!currentMatch) {
      return null;
    }
    return currentMatch[0] as CatalogSectionKey;
  }, [location.pathname]);

  const onScrollContentToTop = useCallback(() => {
    const contentOffsetTop = contentRef?.current?.offsetTop ?? 0;
    window.scrollTo({
      top: contentOffsetTop - SCROLL_OFFSET_TOP,
      behavior: "smooth"
    });
  }, []);

  const searchOptionsForRequest = useMemo(() => {
    return {
      ...Object.fromEntries(searchParams),
      offset: String(offset)
    };
  }, [offset, searchParams]);

  const breadcrumbsValues: IBreadcrumb[] = useMemo(() => {
    if (!currentCatalogSection || !(currentCatalogSection in TITLES)) {
      return [];
    }

    return [
      {
        value: TITLES[currentCatalogSection],
        link: `/catalog/${currentCatalogSection}`
      }
    ];
  }, [currentCatalogSection]);

  useEffect(() => {
    const getFiltersOnPopState = () => {
      dispatch(toggleSearchTrigger());
    };

    window.addEventListener("popstate", getFiltersOnPopState);
    return () => {
      window.removeEventListener("popstate", getFiltersOnPopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (products?.length <= 6 && products && isNotMobileDevice) {
      onScrollContentToTop();
    } else if (!products && isNotMobileDevice) {
      window.scrollTo({
        top: 100,
        behavior: "smooth"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const limit = maxProductsPerPage;
    const offset = parseInt(params.get("offset") || "0", 10);
    const page = Math.floor(offset / limit) + 1;

    if (page !== currentPage) {
      onChangeCurrentPage(page);
      setDisableScrollRestoration(true);
      onScrollContentToTop();
      setDisableScrollRestoration(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, currentPage, onChangeCurrentPage, onScrollContentToTop]);

  const handleChangePage = (page: number | string) => {
    try {

      handleSetShouldGetFiltersStatus(false);
      const pageNumber = typeof page === "string" ? parseInt(page, 10) : page;
      const offsetValue = pageNumber * limit - limit;
      const shouldRemoveParams = page === 1 && limit === maxProductsPerPage;

      onChangeCurrentPage(pageNumber);
      onChangeOffset(offsetValue);

      const newParams: Params = {};

      if (!shouldRemoveParams) {
        newParams.offset = String(offsetValue);
      } else {
        searchParams.delete("offset");
      }
      onChangeSearchParams(newParams);
      setDisableScrollRestoration(true);
      onScrollContentToTop();
      setDisableScrollRestoration(false);

    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("При пагинации произошла ошибка", error);
    }
  };

  const handleShowMoreProducts = async () => {
    try {
      setIsMoreProductsLoading(true);
      handleSetShouldGetFiltersStatus(false);
      handleChangeShouldGetProductsStatus(false);
      setDisableScroll(true);

      const newOffset = offset + limit;

      if (productsAmount < newOffset) return;

      const defaultOptions = {
        sort_by: "showed",
        order_by: "desc"
      };

      const options = {
        ...defaultOptions,
        ...searchOptionsForRequest,
        limit: String(maxProductsPerPage),
        offset: String(newOffset),
        categories: currentCatalogSection ? currentCatalogSection : undefined
      };

      onChangeCurrentPage(currentPage + 1);
      onChangeOffset(newOffset);
      onSaveParams({
        offset: String(newOffset)
      });

      const requestString = getQueryStringForRequest(options);
      const controller = new AbortController();
      const signal = controller.signal;

      const data = await productsApi.getCatalogWithSearchOptions({
        requestString,
        signal
      });

      if (!data) {
        throw new Error("Не получилось получить товары");
      }

      if (isError(data)) {
        throw new Error(data.message);
      }

      if (!data.response) {
        throw new Error("Нет данных о товарах");
      }

      handleSetProducts((prev: IProductWithOffers[]) => [
        ...prev,
        ...(data.response?.items ?? [])
      ]);
    } catch (err) {
      const errorData = getErrorData(err);
      if (errorData?.code === REJECT_ERROR_CODE) {
        // eslint-disable-next-line no-console
        console.error(errorData.message);
      } else {
        // eslint-disable-next-line no-console
        console.error(
          "При попытке показать больше товаров, произошла ошибка",
          errorData.message
        );
      }
    } finally {
      handleChangeShouldGetProductsStatus(true);
      setIsMoreProductsLoading(false);
    }
  };

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const queryParams = new URLSearchParams(currentUrl.search);
    const canonicalUrl = getCanonicalUrl(window.location.href);
    const hostname = window.location.host;
    const urlWithoutHostname = canonicalUrl.replace(
      `${window.location.protocol}//${hostname}`,
      ""
    );

    let { title, description } = searchByCanonical(urlWithoutHostname);

    const categoryCounts: ICategoryCounts =
      products && products.length > 0
        ? products.reduce<ICategoryCounts>((acc, product) => {
          const categoryName = product.category ? product.category.name : "";
          acc[categoryName] = (acc[categoryName] || 0) + 1;
          return acc;
        }, {})
        : {};

    const categories = Object.keys(categoryCounts);
    let categoryText = "Продукция";
    if (categories.length === 1) {
      categoryText = categories[0];
    } else if (categories.length > 1) {
      const uniqueCategories = new Set(
        products.map((product) =>
          product.category ? product.category.name : ""
        )
      );
      if (uniqueCategories.size === 1) {
        categoryText = uniqueCategories.values().next().value ?? "Продукция";
      }
    }

    title = title.replace("{category}", categoryText);
    description = description.replace("{category}", categoryText);

    const brandsParam = queryParams.get("brands");
    if (brandsParam) {
      const firstBrand = brandsParam.split(",")[0];
      const firstBrandCapitalized =
        firstBrand.charAt(0).toUpperCase() + firstBrand.slice(1);
      title = title.replace("{brand}", firstBrandCapitalized);
      description = description.replace("{brand}", firstBrandCapitalized);
    }

    const seria = queryParams.get("seria");
    if (seria) {
      const firstSeria = seria.split(",")[0];
      const seriaCapitalized =
        firstSeria.charAt(0).toUpperCase() + firstSeria.slice(1);
      title = title.replace("{seria}", seriaCapitalized);
      description = description.replace("{seria}", seriaCapitalized);
    }

    setMetaData({ title: title, description: description });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const isOnSalePage = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("badges") === "sale";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  useEffect(() => {
    if (!products || products.length === 0) return;

    const currentUrl = new URL(window.location.href);
    const queryParams = new URLSearchParams(currentUrl.search);
    const pathname = currentUrl.pathname.replace(/\/$/, "");
    const pathnameText = h1TextVariables[pathname as keyof H1TextVariables];
    let h1Text = pathnameText?.root?.text || "";

    const paramsObject: ParamsObject = {};
    queryParams.forEach((value, key) => {
      if (paramsObject[key]) {
        paramsObject[key] += `,${value}`;
      } else {
        paramsObject[key] = value;
      }
    });

    const hasSale = paramsObject["badges"]?.split(",").includes("sale");

    if (hasSale) {
      h1Text = pathnameText?.["badges=sale"]?.text || h1Text;
    } else {
      h1Text = pathnameText?.root?.text || h1Text;
    }

    const categoryCounts: ICategoryCounts = products.reduce<ICategoryCounts>(
      (acc, product) => {
        const categoryName = product.category ? product.category.name : "";
        acc[categoryName] = (acc[categoryName] || 0) + 1;
        return acc;
      },
      {}
    );

    const categories = Object.keys(categoryCounts);
    let categoryText = "Продукция";
    if (categories.length === 1) {
      categoryText = categories[0];
    } else if (categories.length > 1) {
      const uniqueCategories = new Set(
        products.map((product) =>
          product.category ? product.category.name : ""
        )
      );
      if (uniqueCategories.size === 1) {
        categoryText = uniqueCategories.values().next().value ?? "Продукция";
      }
    }

    h1Text = h1Text.replace("{title}", categoryText);

    let dynamicTextReplaced = false;

    queryParams.forEach((value, key) => {
      if (dynamicTextReplaced) return;

      if (pathnameText) {
        if (pathnameText[key] && !dynamicTextReplaced) {
          h1Text = pathnameText?.[key]?.text || h1Text;
          dynamicTextReplaced = true;
        }
        if (pathnameText[`${key}=${value}`] && !dynamicTextReplaced) {
          h1Text = pathnameText?.[`${key}=${value}`]?.text || h1Text;
          dynamicTextReplaced = true;
        }
      }
    });

    const brandsParam = queryParams.get("brands");
    if (brandsParam && !dynamicTextReplaced) {
      const firstBrand = brandsParam.split(",")[0];
      const firstBrandCapitalized =
        firstBrand.charAt(0).toUpperCase() + firstBrand.slice(1);
      h1Text = h1Text.replace("{brand}", firstBrandCapitalized);
      dynamicTextReplaced = true;
    }

    const seria = queryParams.get("seria");
    if (seria && !dynamicTextReplaced) {
      const firstSeria = seria.split(",")[0];
      const seriaCapitalized =
        firstSeria.charAt(0).toUpperCase() + firstSeria.slice(1);
      h1Text = h1Text.replace("{seria}", seriaCapitalized);
      dynamicTextReplaced = true;
    }

    h1Text = h1Text.replace("{title}", categoryText);

    if (brandsParam) {
      const firstBrand = brandsParam.split(",")[0];
      const firstBrandCapitalized =
        firstBrand.charAt(0).toUpperCase() + firstBrand.slice(1);
      h1Text = h1Text.replace("{brand}", firstBrandCapitalized);
    }

    if (seria) {
      const firstSeria = seria.split(",")[0];
      const seriaCapitalized =
        firstSeria.charAt(0).toUpperCase() + firstSeria.slice(1);
      h1Text = h1Text.replace("{seria}", seriaCapitalized);
    }

    setH1Text(h1Text);
  }, [products, isOnSalePage]);

  // yandex ecommerce 'impressions'
  useEffect(() => {
    if (!products || !products.length) {
      return;
    }

    const impressionsProductsArray = products.map((product) => {
      const isBrand = product.brands && !!product.brands.length;
      return {
        id: product.id?.toString(),
        name: product.name,
        category: product.category.name,
        brand: isBrand ? product.brands[0].brand.name : ""
      };
    });

    const impressionsData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.impressions]: impressionsProductsArray
      }
    };

    const handleEcommerceData = async () => {
      await handleYandexEcommerce(impressionsData);
    };

    handleEcommerceData();
  }, [products]);

  const handleResetDesktopFilters = () => {
    const formattedSearchParams = Object.fromEntries(searchParams);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
    const { sort_by, order_by, ...rest } = formattedSearchParams;
    dispatch(setSelectedFilters([]));
    onDeleteParams(Object.keys(rest ?? {}));
  };

  return (
    <>
      <HeadHelmet title={metaData.title} description={metaData.description} />
      <main className={styles.root}>
        <div className={styles.root__head}>
          {breadcrumbsValues.length > 0 &&
          currentCatalogSection &&
          TITLES[currentCatalogSection] &&
          !isOnSalePage ? (
              <>
                <Breadcrumbs
                  breadcrumbs={breadcrumbsValues}
                  containerStyles={styles.breadcrumbs}
                  showArrow
                />
                <h1 className={styles.seo}>{h1Text}</h1>
                <h2 className={styles.title}>{TITLES[currentCatalogSection]}</h2>
              </>
            ) : (
              <h1 className={styles.seo}>{h1Text}</h1>
            )}
          <div
            className={cn(styles["selected-filters"], {
              [styles["selected-filters--gereral-page"]]:
                currentCatalogSection !== null && !TITLES[currentCatalogSection]
            })}
          >
            {isNotMobileDevice ? (
              <>
                <SelectedFiltersV3 />
              </>
            ) : (
              <MobileFiltersButtonV3 />
            )}
            <SortButton
              onSetShouldGetFilters={handleSetShouldGetFiltersStatus}
              onChangeOffset={onChangeOffset}
            />
          </div>
        </div>
        <section
          className={cn(styles["content-wrapper"], {
            [styles["content-wrapper--empty"]]: !products?.length
          })}
          ref={contentRef}
        >
          {isNotMobileDevice && (
            <MainFiltersV3
              filters={filtersV3}
              onResetDesktopFilters={handleResetDesktopFilters}
            />
          )}
          <CatalogContent
            productAmount={productsAmount}
            currentPage={currentPage}
            limit={limit}
            onClickChangePage={handleChangePage}
            onClickShowMoreProducts={handleShowMoreProducts}
            products={products}
            isInit={isLoadingProducts}
            isMoreProductLoading={isMoreProductLoading}
            maxProductsPerPage={maxProductsPerPage}
          />
        </section>
        <LastProductsViewBlock />
      </main>
    </>
  );
};

export default Catalog;
