import { useAppDispatch, useAppSelector } from "store/reduxHooks";

import { addProductToCart } from "../../../store/user/cartThunks/cartThunks";
import getSortedOffersByPriceAndVolume from "../../../utils/getSortedOffersByPriceAndVolume";

import Portal from "../../../HOC/Portal";
import CustomButton from "../../CustomButton/CustomButton";
import CustomLink from "../../CustomLink/CustomLink";
import OffersList from "../../../pages/SingleProductPage/Components/ProductMainContent/components/ProductOffers/OffersList/OffersList";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross-icon.svg";
import { metric } from "../../../utils/yandexMetrics/yandexMetrics";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";

import styles from "./styles.module.scss";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { IOffer } from "types/IOffer";
import { ICurrentOffer } from "types/ICurrentOffer";
import { IEcommerceYandex } from "types/IEcommerceYandex";
import { IProductWithOffers } from "types/IProduct";

interface IProductCardOffersModalProps {
  logoSrc: string;
  title: string;
  productDescription: string;
  offers: IOffer[];
  productUrl: string;
  modalRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  productItem: IProductWithOffers;
}

const ProductCardOffersModal: React.FC<IProductCardOffersModalProps> = (
  props
) => {
  const { cart } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const sortedOffers = useMemo(() => {
    if (!props.offers) return [];
    const filteredOffers = props.offers.filter((e) => e.available);
    return getSortedOffersByPriceAndVolume(filteredOffers);
  }, [props.offers]);

  const [currentOffer, setCurrentOffer] = useState<ICurrentOffer>({
    id: sortedOffers[0]?.id ?? 0,
    description: sortedOffers[0]?.name ?? "",
    promotionPrice:
      sortedOffers[0]?.currentPrice,
    price: sortedOffers[0]?.oldPrice,
    percent: sortedOffers[0]?.percent ?? null,
    available: sortedOffers[0]?.available ?? false
  });

  const onSelectOffer = (option: ICurrentOffer) => {
    setCurrentOffer(option);
  };

  const wordsArr = props.title.split(" ");
  const fiveWords = wordsArr.splice(0, 5);
  const text =
    fiveWords.length <= wordsArr.length
      ? fiveWords.join(" ")
      : fiveWords.join(" ") + "...";

  // yandex ecommerce 'add'
  const yandexEcommerceAdd = async (id: number) => {
    const chosenOffer = props.productItem.offers.find((item) => item.id === id);

    if (!chosenOffer) {
      return;
    }
    const productVariantString = `${chosenOffer.name}, ID: ${chosenOffer.id}`;
    const ecommerceAddData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.add]: {
          products: [
            {
              id: `${props.productItem.id}`,
              name: props.productItem.name,
              category: props.productItem.category.name,
              brand:
                props.productItem.brands && !!props.productItem.brands.length
                  ? props.productItem.brands[0].brand.name
                  : "",
              price: currentOffer.promotionPrice ?? currentOffer.price ?? 0,
              quantity: 1,
              variant: productVariantString
            }
          ]
        }
      }
    };

    await handleYandexEcommerce(ecommerceAddData);
  };

  const onAddProductToCart = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (!currentOffer.available) {
      toast.info("На данный момент этого товара нет в наличии.");
      return;
    }

    try {
      const options = {
        id: currentOffer.id,
        count: 1
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice ?? 0, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };

  const handlerStopPropagation = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  const productInCart = useMemo(
    () => cart.find((e) => e.offer.id === currentOffer.id),
    [cart, currentOffer]
  );

  const addProductAmount = async () => {
    if (!currentOffer.available) {
      toast.info("На данный момент этого товара нет в наличии.");
      return;
    }
    try {
      const options = {
        id: currentOffer.id,
        count: (productInCart?.count ?? 0) + 1
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice ?? 0, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Произошла ошибка при добавлении товара в корзину", err);
    }
  };

  return (
    <>
      <Portal modalRef={props.modalRef} onClose={props.onClose}>
        <section
          className={styles.root}
          ref={props.modalRef}
          onClick={handlerStopPropagation}
        >
          <button className={styles["close-button"]} onClick={props.onClose}>
            <CrossIcon />
          </button>
          <div className={styles["root_img-wrapper"]}>
            <img
              src={props.logoSrc}
              alt={"TODO"}
              className={styles["root_img-wrapper-img"]}
            />
          </div>
          <div className={styles["content-wrapper"]}>
            <div className={styles.info}>
              <h4 className={styles.description}>{props.productDescription}</h4>
              <p className={styles.title}>{props.title}</p>
              <OffersList
                sortedOffers={sortedOffers}
                activeOfferId={currentOffer.id}
                offersScrollbarStyles={styles["offers__list-wrapper"]}
                offersListStyles={styles.offers__list}
                onSelectOffer={onSelectOffer}
                product={props.productItem}
              />
            </div>
            <div className={styles.buttons}>
              <CustomLink
                title="Подробнее о товаре"
                path={props.productUrl}
                isPrimary
              />
              <CustomButton
                title={productInCart ? "Добавить ещё" : "Добавить в корзину"}
                onClick={!productInCart ? onAddProductToCart : addProductAmount}
                className={styles.buttons__add}
                isDisabled={!currentOffer.available}
              />
            </div>
          </div>
        </section>
        <div className={styles.background} />
      </Portal>
    </>
  );
};

export default ProductCardOffersModal;
