import { nanoid } from "@reduxjs/toolkit";
import { IUserInfo } from "types/IFormData";

export const USER_INFO_FIELDS: IUserInfo[] = [
  {
    id: nanoid(),
    title: "Фамилия",
    name: "surname",
    inputType: "text",
    isRequiredField: true,
    isReadOnly: false,
  },
  {
    id: nanoid(),
    title: "Имя",
    name: "name",
    inputType: "text",
    isRequiredField: true,
    isReadOnly: false,
  },
  {
    id: nanoid(),
    title: "Телефон",
    name: "phone",
    inputType: "tel",
    isRequiredField: true,
    isReadOnly: true,
  },
  {
    id: nanoid(),
    title: "Электронная почта",
    name: "email",
    inputType: "email",
    isRequiredField: false,
    isReadOnly: false,
  },
];

export const ADRESS_FIELDS = [
  {
    id: nanoid(),
    title: "Улица",
    name: "street",
    inputType: "text",
  },
  {
    id: nanoid(),
    title: "Дом",
    name: "house",
    inputType: "text",
  },
  {
    id: nanoid(),
    title: "Квартира",
    name: "apartment",
    inputType: "text",
  },
  {
    id: nanoid(),
    title: "Этаж",
    name: "floor",
    inputType: "text",
  },
  {
    id: nanoid(),
    title: "Подъезд",
    name: "entrance",
    inputType: "text",
  },
];

export const PASSWORD_FIELDS = [
  {
    id: nanoid(),
    title: "Пароль (минимум 8 символов)",
    name: "password",
    inputType: "password",
  },
  {
    id: nanoid(),
    title: "Подтверждение пароля",
    name: "confirmPassword",
    inputType: "password",
  },
];

export const USER_INFO_FIELDS_NEW: IUserInfo[] = [
  {
    id: nanoid(),
    title: "Имя",
    name: "name",
    inputType: "text",
    isRequiredField: true,
    isReadOnly: false,
  },
  {
    id: nanoid(),
    title: "Фамилия",
    name: "surname",
    inputType: "text",
    isRequiredField: true,
    isReadOnly: false,
  },
  {
    id: nanoid(),
    title: "Электронная почта",
    name: "email",
    inputType: "email",
    isRequiredField: false,
    isReadOnly: true,
  },
  {
    id: nanoid(),
    title: "Телефон",
    name: "phone",
    inputType: "tel",
    isRequiredField: true,
    isReadOnly: true,
  },
];