import { useRef } from "react";

import CustomButton from "../../../../../../components/CustomButton/CustomButton";

import styles from "./styles.module.scss";
import useOutsideClick from "../../../../../../utils/hooks/useOutsideClick";

interface IDeleteAddressModalProps {
  onClose: () => void;
  onDelete:  () => void;
}

const DeleteAdressModal: React.FC<IDeleteAddressModalProps> = (props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: modalRef,
    cb: props.onClose,
  });

  return (
    <div className={styles.overlay}>
      <div className={styles.root} ref={modalRef}>
        <span className={styles.title}>Подтвердить удаление?</span>
        <div className={styles["buttons-wrapper"]}>
          <CustomButton
            title="Да"
            type="button"
            isWhite
            className={styles.button}
            onClick={props.onDelete}
            data-testid="confirm-delete-button"
          />
          <CustomButton
            title="Нет"
            type="button"
            className={styles.button}
            onClick={props.onClose}
            data-testid="cancel-delete-button"
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteAdressModal;