import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { setPaymentMethod } from "../../../../../../../store/user/user";

import cashIcon from "../../../../../../../assets/icons/payment-methods/cash.png";
import sbpIcon from "../../../../../../../assets/icons/payment-methods/sbp.png";
import cardIcon from "../../../../../../../assets/icons/payment-methods/card.png";
import sberPayIcon from "../../../../../../../assets/icons/payment-methods/sber-pay.png";
import tinkoffPayIcon from "../../../../../../../assets/icons/payment-methods/tinkoff-pay.png";
import yandexPayIcon from "../../../../../../../assets/icons/payment-methods/yandex-pay.png";
import yandexSplitIcon from "../../../../../../../assets/icons/payment-methods/yandex-split.png";
import dolyamiIcon from "../../../../../../../assets/icons/payment-methods/dolyami.png";
import mokkaIcon from "../../../../../../../assets/icons/payment-methods/mokka.png";

import cn from "classnames";
import styles from "./styles.module.scss";
import { PaymentProvider } from "types/IGetOrderPaymentLink";
import { formatPrice } from "utils/helpers/formatedPrice";

const ICONS: Record<string, string> = {
  cash: cashIcon,
  sbp: sbpIcon,
  card_online: cardIcon,
  card_receipt: cardIcon,
  sber_pay: sberPayIcon,
  tinkoff_pay: tinkoffPayIcon,
  yandex_pay: yandexPayIcon,
  yandex_split: yandexSplitIcon,
  dolymi: dolyamiIcon,
  yookassa: cardIcon,
  mokka: mokkaIcon
};

interface IDefaultPaymentMethodItemProps {
  type: string;
  title: string;
  limit?: number | null;
}

interface IDolyamiPaymentMethodItemProps {
  type: string;
  title: string;
}

export const DefaultPaymentMethodItem: React.FC<
  IDefaultPaymentMethodItemProps
> = (props) => {
  const { checkoutData } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const isSelectedMethod = useMemo(() => {
    return checkoutData.paymentMethod?.type === props.type;
  }, [checkoutData.paymentMethod, props.type]);

  const selectMethod = () => {
    dispatch(
      setPaymentMethod({
        type: props.type as PaymentProvider,
        title: props.title
      })
    );
  };

  return (
    <button
      onClick={selectMethod}
      className={cn(styles.button, styles["default-button"], {
        [styles["button--selected"]]: isSelectedMethod
      })}
    >
      <img src={ICONS[props.type]} alt={`${props.type}-icon`} />
      <p className={styles.title}>{props.title}</p>
      {props?.limit !== null && props?.limit !== undefined && (
        <p className={styles.limit}>
          Доступно: {formatPrice(props.limit)} ₽
        </p>
      )}
    </button>
  );
};

export const DolyamiPaymentMethodItem: React.FC<
  IDolyamiPaymentMethodItemProps
> = (props) => {
  const { checkoutData } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  // !!Код был закоментирован 29-10-2024 - пока что не удаляем предыдущую раскладку!!
  // const price = useMemo(() => {
  //   const selectedProductsPrice = checkoutData.items.reduce((acc, cur) => {
  //     if (cur.isSelected) {
  //       acc += cur.calculated_amount;
  //     }
  //     return acc;
  //   }, 0);
  //   return Math.ceil(selectedProductsPrice / 4);
  // }, [checkoutData.items]);

  const isSelectedMethod = useMemo(() => {
    return checkoutData.paymentMethod?.type === props.type;
  }, [checkoutData.paymentMethod, props.type]);

  const selectMethod = () => {
    dispatch(
      setPaymentMethod({
        type: props.type as PaymentProvider,
        title: props.title
      })
    );
  };

  return (
    // !!Код был закоментирован 29-10-2024 - пока что не удаляем предыдущую раскладку!!
    // <button
    //   onClick={selectMethod}
    //   className={cn(styles["dolyami-button"], {
    //     [styles["button--selected"]]: isSelectedMethod
    //   })}
    // >
    //   <p className={styles.title}>{props.title}</p>
    //   <p className={styles.price}>{`4 платежа по ${price}₽`}</p>
    //   <img src={ICONS[props.type]} alt={`${props.type}-icon`} />
    // </button>
    <button
      onClick={selectMethod}
      className={cn(styles.button, styles["default-button"], {
        [styles["button--selected"]]: isSelectedMethod
      })}
    >
      <img src={ICONS[props.type]} alt={`${props.type}-icon`} />
      <p className={styles.title}>{props.title}</p>
    </button>
  );
};
