import { useAppSelector } from "store/reduxHooks";
import { useEffect, useState } from "react";

import { productsApi } from "../../../../api/productsApi";
import { isError } from "store/user/userThunks/userThunks";

import CustomLink from "../../../../components/CustomLink/CustomLink";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import GeneralProductsSlider from "../GeneralProductsSlider/GeneralProductsSlider";
import { CustomError, getErrorData } from "utils/getErrorData";
import { IProductWithOffers } from "types/IProduct";

import styles from "./styles.module.scss";


const HitsBlock: React.FC = () => {
  const { deviceType } = useAppSelector((state) => state.user);
  const [products, setProducts] = useState<IProductWithOffers[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await productsApi.getProductsWithLimit({
          limit: "16",
          badges: "bestseller"
        });

        if (isError(data)) {
          const errorData = getErrorData(data);
          throw new CustomError(errorData.message, errorData?.code);
        }

        setProducts(data.response?.items ?? []);
      } catch (err) {
        const errorData = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error(
          "Ошибка при получении товаров в разделе Хиты продаж: -->",
          errorData.message
        );
      }
    })();
  }, []);

  return (
    <section className={styles.root}>
      <SectionTitle
        title={
          !deviceType.isMobile ? "Хиты продаж: наши бестселлеры" : "Хиты продаж"
        }
      />
      {!!products.length && (
        <GeneralProductsSlider
          products={products}
          isMobile={deviceType.isMobile}
          isDesktop={deviceType.isDesktop}
          isLargeDesktop={deviceType.isLargeDesktop}
          shouldAutoSwitched
          shouldBeLooped
        />
      )}
      <CustomLink
        path="/catalog?badges=bestseller"
        title="Смотреть все"
        className={styles["see-more"]}
      />
    </section>
  );
};

export default HitsBlock;
