import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import {
  addUserFavoriteProduct,
  removeUserFavoriteProduct
} from "../../store/user/userThunks/userThunks";

import CustomButton from "../CustomButton";
import CustomLink from "../CustomLink";
import ProductCardLabel from "../ProductCardLabel";
import ProductCardOffersModal from "../UI/ProductCardOffersModal";

import Cosmetics from "../../assets/icons/svg/cosmetics.svg";
import newIcon from "../../assets/icons/sale-icons/new.svg";
import saleIcon from "../../assets/icons/sale-icons/saleIcon.svg";
import fireIcon from "../../assets/icons/sale-icons/trace.svg";

import { formatPrice } from "utils/helpers/formatedPrice";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { IProductWithOffers } from "types/IProduct";
import { IEcommerceYandex } from "types/IEcommerceYandex";
import { IProductVolume } from "types/IProductVolume";
import { IProductCardWorkerResults } from "utils/webWorkers/productCardWebWorker";
import { toast } from "react-toastify";
import { FavoriteProductButton } from "pages/UserCart/components/CartProducts/components/CartProductItemV2/components/CartProductActions/components/FavoriteProductButton/FavoriteProductButton";

import styles from "./styles.module.scss";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";
interface IProductCardProps {
  product: IProductWithOffers;
  onToggleIsOffersModalOpenedStatus?: React.Dispatch<SetStateAction<boolean>>;
  showLabels?: boolean;
  customHeartClass?: string;
}

const ProductCard: React.FC<IProductCardProps> = ({
  product,
  onToggleIsOffersModalOpenedStatus,
  showLabels = true,
  customHeartClass
}) => {
  const { user, deviceType } = useAppSelector((state) => state.user);
  const [showSelectOfferModal, setShowSelectOfferModal] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const [isNewProduct, setIsNewProduct] = useState<boolean | null>(null);
  const [isBestsellerProduct, setIsBestsellerProduct] = useState<
    boolean | null
  >(null);
  const [isSaleProduct, setIsSaleProduct] = useState<boolean | null>(null);
  const [productMinDescription, setProductMinDescription] = useState<
    string | null
  >(null);
  const [productAmount, setProductAmount] = useState<IProductVolume | null>(
    null
  );
  const [salePercent, setSalePercent] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const isProductInFavourite = useMemo(() => {
    if (!Array.isArray(user.favouriteProducts)) {
      return false;
    }
    const idx = user.favouriteProducts.findIndex(
      (item) => item.product.id === product.id
    );
    return idx !== -1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.favouriteProducts]);

  const [isFavorite, setIsFavorite] = useState(isProductInFavourite);

  const dispatch = useAppDispatch();
  // Производим вычесления параметров карточки товара
  // ПОДСЧЕТ ЧЕРЕЗ web worker

  useEffect(() => {
    const shouldCalculate = deviceType.isLargeDesktop || deviceType.isDesktop;
    const worker = new Worker(
      new URL("../../utils/webWorkers/productCardWebWorker.ts", import.meta.url)
    );
    setIsLoading(true);
    worker.postMessage({ product, shouldCalculate });

    worker.onmessage = (e: MessageEvent<IProductCardWorkerResults>) => {
      const results = e.data;
      setIsNewProduct(results.isNewProduct);
      setIsBestsellerProduct(results.isBestsellerProduct);
      setIsSaleProduct(results.isSaleProduct);
      setProductMinDescription(results.productMinDescription);
      setProductAmount(results.productAmount);
      setSalePercent(results.salePercent);
      setIsLoading(false);
    };

    return () => {
      worker.terminate();
    };
  }, [deviceType.isDesktop, deviceType.isLargeDesktop, product]);

  const brandTitle = useMemo(() => {
    const productWithBrand = product.brands.find((item) => item.brand.name);

    if (!productWithBrand?.brand?.name) return "";

    return productWithBrand.brand.name;
  }, [product.brands]);

  const productUrl = `/product/${product.id}-${product.product_rewrite_name}`;

  const onToggleFavoriteStatus = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      e.preventDefault();
      e.stopPropagation();

      try {
        setIsFavorite(!isFavorite);
        if (!isFavorite) {
          dispatch(addUserFavoriteProduct(product.id));
        } else {
          dispatch(removeUserFavoriteProduct({ product: product.id }));
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(
          "Произошла ошибка добавить/убрать из избранного товар",
          err
        );
        toast.error(
          <div>
            Произошла ошибка добавить/убрать из избранного товар.
            <br />
            ТОВАР: {`${product.name}`}
          </div>
        );
        throw new Error("Произошла ошибка добавить/убрать из избранного товар");
      }
    },
    [dispatch, isFavorite, product.id, setIsFavorite, product.name]
  );

  const onOpenSelectOfferModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setShowSelectOfferModal(true);
    onToggleIsOffersModalOpenedStatus?.(true);
  };

  const onCloseSelectOfferModal = () => {
    setShowSelectOfferModal(false);
    onToggleIsOffersModalOpenedStatus?.(false);
  };

  const handleSelectCard = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    // yandex ecommerce 'click'
    const ecommerceClickData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.click]: {
          products: [
            {
              id: product.id?.toString(),
              name: product.name,
              category: product.category.name,
              brand:
                product.brands && !!product.brands.length
                  ? product.brands[0].brand.name
                  : ""
            }
          ]
        }
      }
    };
    await handleYandexEcommerce(ecommerceClickData);
  };

  const LinkToButton = useCallback(() => {
    const customClassName = styles.linkInfo;
    return (
      <CustomLink
        className={customClassName}
        title="Подробнее о товаре"
        path={productUrl}
        isPrimary
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noImageLink = useMemo(() => {
    return product?.category.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [product?.category]);

  const mainSrc = product?.productImage?.original?.link
    ? product.productImage.original.link
    : noImageLink;

  const isMinEqualMax = product.minPrice === product.maxPrice;

  const skeletonLabelStyle = {
    width: "50px",
    height: deviceType.isMobile ? "15px" : "20px"
  };

  const skeletonStyle = {
    width: deviceType.isMobile ? "70px" : "100px",
    height: "15px"
  };

  return (
    <>
      <div className={styles.card__wrapper}>
        <Link
          to={productUrl}
          className={styles.card}
          onClick={handleSelectCard}
          itemType="https://schema.org/Product"
          itemScope
        >
          {showLabels && (
            <div className={styles.card__labels}>
              {/* {isStockProduct && (
              <ProductCardLabel title="Акция" imgSrc={mouthpieceIcon} imgAlt="promotion-icon" />
            )} */}
              {isLoading ? (
                <SkeletonLine style={skeletonLabelStyle}/>
              ) : isNewProduct ? (
                <ProductCardLabel
                  iconStyle={styles.newIcon}
                  title="Новинка"
                  imgSrc={newIcon}
                  imgAlt="new-icon"
                  shouldShowTitle
                />
              ) : isBestsellerProduct ? (
                <ProductCardLabel
                  title="Бестселлер"
                  iconStyle={styles.fireIcon}
                  imgSrc={fireIcon}
                  imgAlt="best_seller-icon"
                  shouldShowTitle
                />
              ) : (
                <div className={styles["card__empty-label"]} />
              )}
              {/* {isNewProduct ? (
                <ProductCardLabel
                  iconStyle={styles.newIcon}
                  title="Новинка"
                  imgSrc={newIcon}
                  imgAlt="new-icon"
                  shouldShowTitle
                />
              ) : isBestsellerProduct ? (
                <ProductCardLabel
                  title="Бестселлер"
                  iconStyle={styles.fireIcon}
                  imgSrc={fireIcon}
                  imgAlt="best_seller-icon"
                  shouldShowTitle
                />
              ) : (
                <div className={styles["card__empty-label"]} />
              )} */}
            </div>
          )}

          {/* Image */}
          <span className={styles["card_img-wrapper"]}>
            {!loadImg && (
              <img src={Cosmetics} className={styles["card_img-svg"]} />
            )}
            <img
              itemProp="image"
              src={mainSrc}
              alt={product.product_rewrite_name}
              className={cn(styles.card_img, {
                [styles["card_img-show"]]: loadImg
              })}
              onLoad={() => setLoadImg(true)}
            />
            {!!user?.phone && (
              <div
                className={cn(
                  styles["card__heart_icon-wrapper"],
                  customHeartClass
                )}
              >
                <FavoriteProductButton
                  isFavorite={isFavorite}
                  onToggleFavoriteStatus={onToggleFavoriteStatus}
                />
              </div>
            )}
          </span>
          {showLabels && (
            <div className={styles.card__labels}>
              {isLoading ? (
                <SkeletonLine style={skeletonLabelStyle} />
              ) : isSaleProduct ? (
                <ProductCardLabel
                  title="Распродажа"
                  iconStyle={styles.saleIcon}
                  imgSrc={saleIcon}
                  imgAlt="sale-icon"
                  className={styles["sale-label"]}
                  shouldShowTitle
                />
              ) : (
                <div className={styles["card__empty-label-row"]} />
              )}
              {/* {isSaleProduct ? (
                <ProductCardLabel
                  title="Распродажа"
                  iconStyle={styles.saleIcon}
                  imgSrc={saleIcon}
                  imgAlt="sale-icon"
                  className={styles["sale-label"]}
                  shouldShowTitle
                />
              ) : (
                <div className={styles["card__empty-label-row"]} />
              )} */}
            </div>
          )}

          {/* Brand */}
          <span className={styles["card_brand-title"]} itemProp="brand">
            {brandTitle}
          </span>

          {/* Title */}
          <span className={styles.card_title} itemProp="name">
            {product.name}
          </span>

          {/* Description */}
          <div className={styles.card_description} itemProp="description">
            {isLoading ? (
              <SkeletonLine style={skeletonStyle}/>
            ) : productMinDescription ? (
              productMinDescription
            ) : (
              <span>&nbsp;</span>
            )}
            {/* {productMinDescription ? (
              productMinDescription
            ) : (
              <span>&nbsp;</span>
            )} */}
          </div>

          <div
            className={cn(styles.card__price, {
              [styles["card__price-min-content"]]: isMinEqualMax
            })}
            itemProp="offers"
            itemType="https://schema.org/Offer"
            itemScope
          >
            <meta itemProp="priceCurrency" content="RUB" />

            {/* Price */}
            {isMinEqualMax ? (
              <>
                <span>
                  <span itemProp="price" content={`${product.minPrice}`}>
                    {formatPrice(product.minPrice)}
                  </span>
                  <span> ₽ </span>
                </span>
              </>
            ) : (
              <>
                <span>
                  <span> От </span>
                  <span itemProp="lowPrice" content={`${product.minPrice}`}>
                    {formatPrice(product.minPrice)}
                  </span>
                  <span> ₽ </span>
                </span>

                <span>
                  <span> До </span>
                  <span itemProp="highPrice" content={`${product.maxPrice}`}>
                    {formatPrice(product.maxPrice)}
                  </span>
                  <span> ₽ </span>
                </span>
              </>
            )}
          </div>
          {showLabels && (
            <div>
              {isLoading ? (
                <SkeletonLine style={skeletonLabelStyle}/>
              ) : !!salePercent && (
                <ProductCardLabel
                  title={`${salePercent}%`}
                  imgAlt="sale-icon"
                  className={styles["sale-percent"]}
                  shouldShowTitle
                />
              )}
              {/* {!!salePercent && (
                <ProductCardLabel
                  title={`${salePercent}%`}
                  imgAlt="sale-icon"
                  className={styles["sale-percent"]}
                  shouldShowTitle
                />
              )} */}
            </div>
          )}

          {/* Volume */}
          {productAmount && !!productAmount.min && (
            <span
              className={cn(styles.card_amount, {
                [styles["card_amount-min-content"]]: !productAmount.max
              })}
            >
              {!productAmount.max ? (
                <span>{`${productAmount.min} ${productAmount.productVolume}.`}</span>
              ) : (
                <>
                  <span>{`От ${productAmount.min} ${productAmount.productVolume}`}</span>
                  <span>{`До ${productAmount.max} ${productAmount.productVolume}`}</span>
                </>
              )}
            </span>
          )}
          <div className={styles.card__buttons}>
            <CustomButton
              title="Добавить в корзину"
              onClick={onOpenSelectOfferModal}
            />
          </div>

          {showSelectOfferModal && (
            <ProductCardOffersModal
              logoSrc={
                product.productImage?.thumbnail_q_50?.link
                  ? product.productImage.thumbnail_q_50.link
                  : product.productImage?.original?.link
                    ? product.productImage.original.link
                    : noImageLink
              }
              title={product.name}
              productDescription={productMinDescription ?? ""}
              offers={product.offers}
              productUrl={productUrl}
              modalRef={modalRef}
              onClose={onCloseSelectOfferModal}
              productItem={product}
            />
          )}
        </Link>
        <LinkToButton />
      </div>
    </>
  );
};

export default ProductCard;
