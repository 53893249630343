import { Favorites, MyOrders, OrderDetails, PersonalDataV2, Profile } from "pages";

export const catalogPaths: string[] = [
  "/catalog",
  "/catalog/kosmetika",
  "/catalog/parfumeria",
];

export const personalAreaPaths: string[] = [
  "/personal-area/profile",
  "/personal-area/orders",
  "/personal-area/products",
  "/personal-area/settings",
];

export enum ProfileRoutesList {
  PROFILE = "/profile",
  FAVORITES = "/profile/favorites",
  MY_ORDERS = "/profile/my-orders",
  PERSONAL_DATA = "/profile/personal-data",
  ORDER_DETAILS = "/profile/my-orders/:id",
}

interface RouteProfile {
  path: string;
  component: React.ComponentType;
}

export const routesProfile: RouteProfile[] = [
  { path: ProfileRoutesList.PROFILE, component: Profile },
  { path: ProfileRoutesList.FAVORITES, component: Favorites },
  { path: ProfileRoutesList.MY_ORDERS, component: MyOrders },
  { path: ProfileRoutesList.ORDER_DETAILS, component: OrderDetails },
  { path: ProfileRoutesList.PERSONAL_DATA, component: PersonalDataV2 },
];