import React, { useCallback, useRef, useState } from "react";
import cn from "classnames";

import useDidUpdate from "../../../../../../utils/hooks/useDidUpdate";
import useShowShadow from "../../../../../../utils/hooks/useShowShadow";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import {
  IFilterWithKeyObj,
  setFiltersData,
  setMobileSearchParams,
  setSelectedFilters
} from "store/catalogV3";
import {
  isFilterCommon,
  isFilterUncommon
} from "utils/helpers/filtersTypeGuards";

import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import CustomButton from "../../../../../../components/CustomButton/CustomButton";
import { ICatalogPrevState } from "pages/Catalog/components/MobileFiltersButtonV3/MobileFiltersButtonV3";
import SearchInputV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/FilterButtonV3/SearchInputV3/SearchInputV3";
import FilterButtonItemV3 from "pages/Catalog/components/CatalogFiltersV3/MainFiltersV3/FilterButtonV3/FilterButtonItemV3/FilterButtonItemV3";

import styles from "./styles.module.scss";

interface IMoreFiltersBSV3Props {
  isOpen: boolean;
  onMoreFilterBSClose: () => void;
}

const MoreFiltersBSV3: React.FC<IMoreFiltersBSV3Props> = ({
  isOpen,
  onMoreFilterBSClose
}) => {
  const {
    chosenFilterWithKey,
    selectedFilters,
    filtersV3,
    mobileSearchParams
  } = useAppSelector((state) => state.catalogV3);
  const [currentFilters, setCurrentFilters] =
    useState<IFilterWithKeyObj | null>(chosenFilterWithKey);
  const [isError, setIsError] = useState(false);

  const { showShadow, changeShowShadow, onScrollHandler } = useShowShadow();

  const listRef = useRef<HTMLUListElement | null>(null);
  // для возврата на предыдущее состояние при закрытии и не применении фильтров нам нужен этот реф его мы обновляем при открытии боттомщита
  const catalogV3PrevState = useRef<ICatalogPrevState>({
    prevSearchParams: new URLSearchParams({}),
    prevSelectedFilters: [],
    prevFiltersV3: {}
  });
  const shouldReturnPrevCatalogState = useRef(false);

  const dispatch = useAppDispatch();

  useDidUpdate(() => {
    setCurrentFilters(chosenFilterWithKey);
  }, [chosenFilterWithKey]);

  useDidUpdate(() => {
    handleEnteredBS();
  }, [currentFilters?.subFilters?.length]);

  const changeCurrentFilters = useCallback(
    ({
      filterWithKey,
      isError
    }: {
      filterWithKey: IFilterWithKeyObj;
      isError: boolean;
    }) => {
      setCurrentFilters(filterWithKey);
      setIsError(isError);
    },
    []
  );

  const handleEnteredBS = useCallback(() => {
    if (listRef.current) {
      changeShowShadow({
        top: false,
        bottom: listRef.current?.scrollHeight > listRef.current?.offsetHeight
      });
    }

    catalogV3PrevState.current = {
      prevSearchParams: new URLSearchParams(mobileSearchParams),
      prevSelectedFilters: selectedFilters,
      prevFiltersV3: filtersV3
    };
  }, [changeShowShadow, filtersV3, mobileSearchParams, selectedFilters]);

  const handleSubSheetClose = useCallback(() => {
    onMoreFilterBSClose();
    shouldReturnPrevCatalogState.current = true;
  }, [onMoreFilterBSClose]);

  const handleSubmitFilters = useCallback(() => {
    onMoreFilterBSClose();
    if (shouldReturnPrevCatalogState.current) {
      dispatch(
        setMobileSearchParams(
          Object.fromEntries(catalogV3PrevState.current.prevSearchParams)
        )
      );
      dispatch(
        setSelectedFilters(catalogV3PrevState.current.prevSelectedFilters)
      );
      dispatch(setFiltersData(catalogV3PrevState.current.prevFiltersV3));
    }
    shouldReturnPrevCatalogState.current = false;
  }, [dispatch, onMoreFilterBSClose]);

  return (
    <BottomSheet
      isOpen={isOpen}
      shouldBeOnFullScreen
      // при изменении кода в функциях handleSubSheetClose handleEnteredBS handleSubmitFilters - внимательно ознакомится какая за какой выполняется
      onClose={handleSubSheetClose}
      onEntered={handleEnteredBS}
      onExited={handleSubmitFilters}
      disableClickOutside
    >
      {chosenFilterWithKey && (
        <SearchInputV3
          filterWithKey={chosenFilterWithKey}
          changeCurrentFilters={changeCurrentFilters}
          containerStyles={styles.search}
        />
      )}
      <div
        className={cn(
          styles["list-wrapper"],
          { [styles["list-wrapper--top-shadow"]]: showShadow.top },
          { [styles["list-wrapper--bottom-shadow"]]: showShadow.bottom }
        )}
      >
        <ul className={styles.list} ref={listRef} onScroll={onScrollHandler}>
          {isError ? (
            <p className={styles.error}>Ничего не найдено</p>
          ) : (
            currentFilters &&
            currentFilters.subFilters.map((item, index) => {
              if (isFilterCommon(item)) {
                return (
                  <FilterButtonItemV3
                    key={`${item.rewrite_name}_${index}`}
                    item={item}
                    mainFilterRewriteName={currentFilters.mainFilterKey}
                    isFilterProductProperties={
                      currentFilters.isFilterProductProperties
                    }
                    mainFilterLocalizedName={currentFilters.mainFilterName}
                    isMobileDevice={true}
                  />
                );
              }

              if (isFilterUncommon(item)) {
                return (
                  <FilterButtonItemV3
                    key={`${item.rewrite_value}_${index}`}
                    item={item}
                    mainFilterRewriteName={currentFilters.mainFilterKey}
                    isFilterProductProperties={
                      currentFilters.isFilterProductProperties
                    }
                    mainFilterLocalizedName={currentFilters.mainFilterName}
                    isMobileDevice={true}
                  />
                );
              }
            })
          )}
        </ul>
      </div>
      <CustomButton
        title="Применить"
        className={styles["accept-filters"]}
        onClick={handleSubmitFilters}
      />
    </BottomSheet>
  );
};

export default MoreFiltersBSV3;
