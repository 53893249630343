import cn from "classnames";
import { SELECT_TYPE_TABS } from "../../../../pages/Main/components/DiscountsBlock/DiscountsBlock";
import styles from "./styles.module.scss";

interface SliderTabProps {
  onClickSelectTab?: (tabId: string) => void;
  activeTabId?: string;
  handleResumed: () => void;
  handleStopped: () => void;
}

const SliderTab: React.FC<SliderTabProps> = (props) => {
  return (
    <div className={styles.tabs}>
      {SELECT_TYPE_TABS.map((item) => {
        return (
          <button
            key={item.id}
            className={cn(styles.tabs_button, {
              [styles["tabs_button--active"]]: props.activeTabId === item.id
            })}
            onClick={() => {
              if(props.onClickSelectTab) {
                props.onClickSelectTab(item.id);
              }
              props.handleStopped();
            }}
            onMouseUp={props.handleResumed}
            onMouseLeave={props.handleResumed}
          >
            {item.title}
          </button>
        );
      })}
    </div>
  );
};

export default SliderTab;
