export enum OrderStatusesEnum {
  NEW = "created",
  ACCEPTED = "accepted",
  CONFIRMED = "confirmed",
  PACKING = "packing",
  PACKED = "packed",
  DELIVERING = "delivering",
  WAIT_RECEIPT = "wait_receipt",
  DELIVERED = "delivered",
  RECEIVED = "received",
  PART_RECEIVED = "part_received",
  CANCELED = "canceled",
  UNKNOWN_STATUS = "unknown_status"
}

export const localizedOrderStatuses = {
  [OrderStatusesEnum.NEW]: "Новый",
  [OrderStatusesEnum.ACCEPTED]: "Принят",
  [OrderStatusesEnum.CONFIRMED]: "Подтвержден",
  [OrderStatusesEnum.PACKING]: "Упаковывается",
  [OrderStatusesEnum.PACKED]: "Упакован",
  [OrderStatusesEnum.DELIVERING]: "Доставляется",
  [OrderStatusesEnum.WAIT_RECEIPT]: "Ожидает получения",
  [OrderStatusesEnum.DELIVERED]: "Доставлен",
  [OrderStatusesEnum.RECEIVED]: "Получен",
  [OrderStatusesEnum.PART_RECEIVED]: "Частично получен",
  [OrderStatusesEnum.CANCELED]: "Отменен",
  [OrderStatusesEnum.UNKNOWN_STATUS]: "Статус неизвестен"
};

export const statusColors = {
  green: ["accepted", "confirmed", "delivered" ],
  red: [ "wait_receipt" ],
  yellow: ["created", "packing", "delivering", "packed" ],
  grey: [ "received", "part_received", "canceled", "unknown_status" ]
};

export const getStatusStyle = (status: OrderStatusesEnum) => {
  let color = "";
  if (statusColors.green.includes(status)) {
    color = "green";
  } else if (statusColors.red.includes(status)) {
    color = "red";
  } else if (statusColors.yellow.includes(status)) {
    color = "yellow";
  } else if (statusColors.grey.includes(status)) {
    color = "grey";
  }

  return {
    text: localizedOrderStatuses[status],
    color
  };
};

export const mapStatusToEnum = (status: string): OrderStatusesEnum => {
  switch (status) {
  case "created":
    return OrderStatusesEnum.NEW;
  case "accepted":
    return OrderStatusesEnum.ACCEPTED;
  case "confirmed":
    return OrderStatusesEnum.CONFIRMED;
  case "packing":
    return OrderStatusesEnum.PACKING;
  case "packed":
    return OrderStatusesEnum.PACKED;
  case "delivering":
    return OrderStatusesEnum.DELIVERING;
  case "wait_receipt":
    return OrderStatusesEnum.WAIT_RECEIPT;
  case "delivered":
    return OrderStatusesEnum.DELIVERED;
  case "received":
    return OrderStatusesEnum.RECEIVED;
  case "part_received":
    return OrderStatusesEnum.PART_RECEIVED;
  case "canceled":
    return OrderStatusesEnum.CANCELED;
  default:
    return OrderStatusesEnum.UNKNOWN_STATUS;
  }
};