import { createSlice } from "@reduxjs/toolkit";
import { IBannerDataReal } from "types/IBannersOnMainPage";
import { fetchBanners, fetchProfitBanner } from "./bannerThunks/bannerThunks";
import { IProductWithOffers } from "types/IProduct";
import { ISaleBlockProduct } from "types/IProfitBlockData";

interface BannersState {
  banners: IBannerDataReal[];
  isLoading: boolean;
  errorMessage: string | null;
  profitBanner: {
    profitData: IProductWithOffers[] | null;
    bannerImage: string | null;
    title: string | null;
    isLoading: boolean;
    errorMessage: string | null;
  };
}

const initialState: BannersState = {
  banners: [],
  isLoading: false,
  errorMessage: null,
  profitBanner: {
    profitData: null,
    bannerImage: null,
    title: null,
    isLoading: false,
    errorMessage: null,
  },
};

const banners = createSlice({
  name: "banners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = null;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.banners = action.payload.response?.items || [];
        state.isLoading = false;
        state.errorMessage = null;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message || "Ошибка при загрузке баннеров";
      })
      .addCase(fetchProfitBanner.pending, (state) => {
        state.profitBanner.isLoading = true;
        state.profitBanner.errorMessage = null;
      })
      .addCase(fetchProfitBanner.fulfilled, (state, action) => {
        if (action.payload.items) {
          const products = action.payload.items[0].saleBlockProducts.flatMap((item: ISaleBlockProduct) => item.product).slice(0, 4);
          state.profitBanner.profitData = products;
          state.profitBanner.bannerImage = action.payload.items[0].banner_image?.original_image?.link ?? null;
          state.profitBanner.title = action.payload.items[0].title ?? null;
        } else {
          state.profitBanner.profitData = [];
          state.profitBanner.bannerImage = null;
          state.profitBanner.title = null;
        }
        state.profitBanner.isLoading = false;
      })
      .addCase(fetchProfitBanner.rejected, (state, action) => {
        state.profitBanner.isLoading = false;
        state.profitBanner.errorMessage = action.payload?.message ?? "Ошибка при загрузке Profit Banner";
      });
  },
});

export default banners.reducer;