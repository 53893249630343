/* eslint-disable react/prop-types */
import { useMemo } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import { formatPrice } from "utils/helpers/formatedPrice";
import { ICartItem } from "types/ICartItem";
import { IOrderOffer } from "types/UserApiTypes";

interface CheckoutOrderItemProps {
  item: ICartItem | IOrderOffer;
  rootStyles?: string;
  imageStyles?: string;
}

interface ITotalDisplayPrice {
  displaySalePrice: string | null;
  displayCurrentPrice: string;
}

const CheckoutOrderItem: React.FC<CheckoutOrderItemProps> = ({
  item,
  rootStyles,
  imageStyles
}) => {
  const totalDisplayPrice: ITotalDisplayPrice = useMemo(() => {
    const discountPrice = item.offer.oldPrice * item.count;
    const calculatedPrice = (item as ICartItem)?.calculated_amount;

    if (!calculatedPrice) {
      const calculatedPriceOrderedItem =
        (item as IOrderOffer).site_price * item.count;
      const calculatedSaleOrderedItem =
        (item as IOrderOffer).sale_price * item.count;

      if (calculatedPriceOrderedItem === calculatedSaleOrderedItem) {
        const displayPrices: ITotalDisplayPrice = {
          displaySalePrice: null,
          displayCurrentPrice: `${formatPrice(calculatedSaleOrderedItem)} ₽`
        };

        return displayPrices;
      }

      const displayPrices: ITotalDisplayPrice = {
        displaySalePrice: `${formatPrice(calculatedPriceOrderedItem)} ₽`,
        displayCurrentPrice: `${formatPrice(calculatedSaleOrderedItem)} ₽`
      };

      return displayPrices;

    }

    if (discountPrice === calculatedPrice) {
      const displayPrices: ITotalDisplayPrice = {
        displaySalePrice: null,
        displayCurrentPrice: `${formatPrice(calculatedPrice)} ₽`
      };
      return displayPrices;
    }

    const displayPrices: ITotalDisplayPrice = {
      displaySalePrice: `${formatPrice(discountPrice)} ₽`,
      displayCurrentPrice: `${formatPrice(calculatedPrice)} ₽`
    };
    return displayPrices;

  }, [item]);

  const offerName = item?.offer?.name || "";

  const noImageLink = useMemo(() => {
    return item.offer.catalog.category?.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [item.offer.catalog.category?.rewrite_name]);

  const productImageLink = useMemo(() => {
    return item.offer.offerImage?.thumbnail_q_100?.link
      ? item.offer.offerImage.thumbnail_q_100.link
      : item.offer.offerImage?.original?.link
        ? item.offer.offerImage.original.link
        : item.offer?.catalog?.productImage?.thumbnail_q_100?.link
          ? item.offer.catalog.productImage.thumbnail_q_100.link
          : item.offer.catalog.productImage?.original?.link
            ? item.offer.catalog.productImage.original.link
            : noImageLink;
  }, [noImageLink, item]);

  return (
    <div
      className={cn(styles.root, rootStyles)}
      data-offer_id={item.offer.id}
      data-group_id={item.offer.catalog.id}
    >
      <img
        src={productImageLink}
        alt={item.offer.catalog.name}
        className={cn(styles.image, imageStyles)}
      />
      <div className={styles["product-data"]}>
        <p className={styles["product-data__name"]}>
          {item.offer.catalog.name}
          {offerName && `, ${offerName}`}
        </p>
        <p className={styles["product-data__code"]}>
          {`Артикул: ${item.offer.catalog.vendorCode}`}
        </p>
      </div>
      <div className={styles["price-wrapper"]}>
        <p className={styles.amount}>{`x ${item.count}`}</p>
        {totalDisplayPrice.displaySalePrice === null ? (
          <p className={styles["sale-price"]}>
            {totalDisplayPrice.displayCurrentPrice}
          </p>
        ) : (
          <>
            <p className={styles["main-price"]}>
              {totalDisplayPrice.displaySalePrice}
            </p>
            <p className={styles["sale-price"]}>
              {totalDisplayPrice.displayCurrentPrice}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutOrderItem;
