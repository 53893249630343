import { OrderOffer } from "types/IGetUserOrderData";
import { useMemo, useRef } from "react";
import noImageNew from "../../../../../../assets/images/no-image-new.png";
import { Link } from "react-router-dom";
import { formatPrice } from "utils/helpers/formatedPrice";
import { IImagesCache } from "types/IImagesCache";
import { ProductProgressiveImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductProgressiveImage/ProductProgressiveImage";
import styles from "./styles.module.scss";

interface OrderTotalItemProps {
  item: OrderOffer;
}

const OrderTotalItem: React.FC<OrderTotalItemProps> = ({ item }) => {
  const { offer, count } = item;
  const { offerImage, currentPrice } = offer;
  const imagesCacheRef = useRef<IImagesCache>({});

  const productImage = useMemo(() => {
    return offerImage || offer.catalog.productImage || null;
  }, [offerImage, offer.catalog.productImage]);

  const productUrl = `/product/${offer.catalog.id}-${offer.catalog.product_rewrite_name}?type=${offer.id}`;

  const isNoImage = !productImage;
  const totalPrice = currentPrice * count;

  return (
    <Link to={productUrl} className={styles.item}>
      <div className={styles.image}>
        {isNoImage ? (
          <div className={styles["wrapper-no-image"]}>
            <img
              src={noImageNew}
              alt="Изображения нет"
              className={styles["no-image"]}
            />
          </div>
        ) : (
          <ProductProgressiveImage
            thumbnail_q_0={productImage?.thumbnail_q_0}
            thumbnail_q_50={productImage?.thumbnail_q_50}
            thumbnail_q_100={productImage?.thumbnail_q_100}
            original={productImage?.original}
            imagesCache={imagesCacheRef.current}
            imgProps={{ alt: offer.catalog.name, itemProp: "image" }}
          />
        )}
      </div>
      <div className={styles.item__details}>
        <div className={styles.item__description}>
          <div className={styles["item__price-row"]}>
            <p className={styles.item__price}>
              {`${formatPrice(currentPrice)} ₽`}
              <span className={styles["item__price-separator"]}>x</span>
              {count}
            </p>
            <p className={styles["item__price-total"]}>{`${formatPrice(totalPrice)} ₽`}</p>
          </div>
          <p className={styles.item__name}>{offer.catalog.name}</p>
          <p className={styles.item__volume}>{offer.catalog_name}</p>
        </div>
      </div>
    </Link>
  );
};

export default OrderTotalItem;
