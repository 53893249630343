import { productsApi } from "api/productsApi";
import { useCallback, useEffect, useRef, useState } from "react";
import { getQueryStringForRequest } from "utils/helpers/catalogPageHelper";
import { CustomError, getErrorData, REJECT_ERROR_CODE } from "utils/getErrorData";
import { IProductWithOffers } from "types/IProduct";
import { isError } from "store/user/userThunks/userThunks";

const MAX_PRODUCTS_PER_PAGE = 18;

const useLandingsProducts = (queryOptions: Record<string, string | undefined>) => {
  const [products, setProducts] = useState<IProductWithOffers[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [totalProducts, setTotalProducts] = useState<number | null>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const abortController = useRef<AbortController | null>(null);

  useEffect(() => {
    if (!shouldFetch) {
      return;
    }
    if (totalProducts !== null && products.length >= totalProducts) {
      setShouldFetch(false);
      return;
    }
    (async () => {
      try {
        abortController.current = new AbortController();
        setIsLoadingProducts(true);

        const requestString = getQueryStringForRequest({
          limit: String(MAX_PRODUCTS_PER_PAGE),
          offset: String(products.length),
          ...queryOptions
        });

        const data = await productsApi.getCatalogWithSearchOptions({
          requestString,
          signal: abortController.current.signal
        });

        if (isError(data)) {
          const errorData = getErrorData(data);
          throw new CustomError(errorData.message, errorData?.code);
        }

        if (!data.response) {
          throw new CustomError("Данных о товарах нет");
        }

        const filteredProducts = data.response.items
          .map((product) => ({
            ...product,
            offers: product.offers.filter((offer) => offer.available)
          }))
          .filter((product) => product.offers.length > 0);

        setProducts((prevProducts) => [...prevProducts, ...filteredProducts]);
        setTotalProducts(data.response.meta.total);
        setShouldFetch(false);
        setIsFirstLoad(false);
      } catch (err) {
        const errorData = getErrorData(err);
        if (errorData?.code === REJECT_ERROR_CODE) {
          // eslint-disable-next-line no-console
          console.error(errorData.message);
        } else {
          // eslint-disable-next-line no-console
          console.error(
            "Ошибка при получении товаров:",
            errorData.message
          );
        }
      } finally {
        if (isFirstLoading) {
          setIsFirstLoading(false);
        }
        setIsLoadingProducts(false);
      }
    })();

    return () => {
      abortController.current?.abort();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTrigger]);

  const handleChangeShouldGetProductsStatus = useCallback((value: boolean) => {
    setShouldFetch(value);
    setFetchTrigger((prev) => prev + 1);
  }, []);

  return {
    products,
    isLoadingProducts,
    handleChangeShouldGetProductsStatus,
    isFirstLoad,
    isFirstLoading
  };
};

export default useLandingsProducts;