import { useMemo, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";

import { addProductToCart } from "../../../../../store/user/cartThunks/cartThunks";
import getSortedOffersByPriceAndVolume from "../../../../../utils/getSortedOffersByPriceAndVolume";

// import saleTicketIcon from "../../../../../assets/icons/png/sale_ticket-icon.png";

import ProductOffersHeader from "../../ProductOffersHeader/ProductOffersHeader";
import MobileOffersList from "./components/MobileOffersList/MobileOffersList";
import DolyamiDropDown from "../../ProductMainContent/components/DropDowns/DolyamiDropDown/DolyamiDropDown";
import HelpDropDown from "../../ProductMainContent/components/DropDowns/HelpDropDown/HelpDropDown";
import CostCalculationDropDown from "../../ProductMainContent/components/DropDowns/CostCalculationDropDown/CostCalculationDropDown";
import { metric } from "../../../../../utils/yandexMetrics/yandexMetrics";

import BottomSheet from "../../../../../components/UI/Bottomsheet/Bottomsheet";
import ContentFoundCheaper from "../../../../../components/UI/ContentFoundCheaper/ContentFoundCheaper";
import cn from "classnames";
import useSearchParamsHandler from "../../../../../utils/hooks/useSearchParamsHandler";
import { toast } from "react-toastify";
import { formatPrice } from "utils/helpers/formatedPrice";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { IProduct } from "types/IProduct";
import { IOffer } from "types/IOffer";
import { IEcommerceYandex } from "types/IEcommerceYandex";
import { ICurrentOffer } from "types/ICurrentOffer";
import { ProductGalleryMobile } from "pages/SingleProductPage/Components/ProductGallery/ProductGalleryMobile/ProductGalleryMobile";
// В случае необходимости можно использовать моковые данные о фотографиях товара
// import { PRODUCT_MOCK_IMAGES } from "mock/productCardMock/productCardMockImages";
import { IProductImageOffer } from "utils/createProductImages";

import styles from "./styles.module.scss";

interface IMobileMainProductContentProps {
  product: IProduct | null;
  offers: IOffer[];
  isLoading: boolean;
  productImages: IProductImageOffer[];
  onChangeCurrentOffer: (offer: ICurrentOffer) => void;
  currentOffer: ICurrentOffer;
  onSelectOffer: (options: ICurrentOffer) => void;
  activeImageIndex: number;
  setActiveImageIndex: React.Dispatch<React.SetStateAction<number>>;
}

const MobileMainProductContent: React.FC<IMobileMainProductContentProps> = (
  props
) => {
  const { cart } = useAppSelector((state) => state.user);
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);
  const dispatch = useAppDispatch();
  const { searchParams } = useSearchParamsHandler();
  const { type } = Object.fromEntries(searchParams);
  // const [, setSearchParams] = useSearchParams();

  const sortedOffers = useMemo(() => {
    if (!props.product || props.isLoading) return [];
    return getSortedOffersByPriceAndVolume(props.offers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.offers, props.isLoading]);

  const wordsArr = props.product?.name.split(" ") ?? [];
  const fiveWords = wordsArr?.splice(0, 5);
  const text =
    fiveWords?.length <= wordsArr?.length
      ? fiveWords?.join(" ")
      : fiveWords?.join(" ") + "...";

  // yandex ecommerce 'add'
  const yandexEcommerceAdd = async (id: number) => {
    const selectedOffer = sortedOffers.find((offer) => offer.id === id);

    if (!selectedOffer) {
      return;
    }

    if (!props.product) {
      return;
    }

    const productVariantString = `${selectedOffer.name}, ID: ${selectedOffer.id}`;
    const ecommerceAddData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.add]: {
          products: [
            {
              id: props.product.id?.toString(),
              name: props.product.name,
              category: props.product.category.name,
              brand:
                props.product.brands && !!props.product.brands.length
                  ? props.product.brands[0].brand.name
                  : "",
              price:
                props.currentOffer.promotionPrice ??
                props.currentOffer.price ??
                0,
              quantity: 1,
              variant: productVariantString
            }
          ]
        }
      }
    };

    await handleYandexEcommerce(ecommerceAddData);
  };

  const addProduct = async () => {
    if (!props.currentOffer.available) {
      toast.info("На данный момент этого товара нет в наличии.");
      return;
    }

    if (
      !props.product ||
      (!props.currentOffer.promotionPrice && !props.currentOffer.price)
    ) {
      return;
    }

    try {
      const options = {
        id: props.currentOffer.id,
        count: 1
      };
      dispatch(addProductToCart([options])).unwrap();
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(props.currentOffer.promotionPrice ?? 0, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };
  const productInCart = useMemo(
    () => cart.find((e) => e.offer.id === props.currentOffer.id),
    [cart, props.currentOffer]
  );

  const addProductAmount = async () => {
    try {
      if (!props.currentOffer.available) {
        toast.info("На данный момент этого товара нет в наличии.");
        return;
      }

      if (!productInCart) {
        return;
      }
      const options = {
        id: props.currentOffer.id,
        count: productInCart.count + 1
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      if (props.currentOffer.promotionPrice) {
        metric.addToBasketMetric(props.currentOffer.promotionPrice, "RUB");
      }

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };

  const noImageLink = useMemo(() => {
    return props.product?.category.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [props.product?.category.rewrite_name]);

  useEffect(() => {
    if (props.isLoading || sortedOffers.length === 0) return;

    const selectedOffer =
      sortedOffers.find((offer) => offer.id === Number(type)) ||
      sortedOffers[0];

    props.onChangeCurrentOffer({
      id: selectedOffer?.id ?? 0,
      description: selectedOffer?.name ?? "",
      promotionPrice: selectedOffer?.currentPrice,
      price: selectedOffer?.oldPrice,
      percent: selectedOffer?.percent ?? null,
      available: selectedOffer?.available ?? false
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedOffers, type]);

  const isCosmetic = props.product?.category.rewrite_name === "kosmetika";

  return (
    <section className={styles.root}>
      <h1 className={styles.seo}>
        {props?.product?.name}
        {isCosmetic
          ? ""
          : props?.offers?.[0]?.offerProductTypes?.[0]?.value || ""}
      </h1>
      <h4
        className={styles["vendor-code"]}
        itemProp="sku"
        content={props?.product?.vendorCode}
      >
        Артикул: {props?.product?.vendorCode}
      </h4>
      <ProductOffersHeader
        brandRewriteName={props.product?.brands[0].brand.rewrite_name ?? ""}
        brandName={props.product?.brands[0].brand.name ?? ""}
        productName={props.product?.name ?? ""}
        currentOffer={props.currentOffer}
        isLoading={props.isLoading}
      />
      {sortedOffers.length ? (
        <span className={styles["select-title"]}>Выберите вариант товара</span>
      ) : (
        <span className={styles["empty-title"]}></span>
      )}
      <ProductGalleryMobile
        // images={PRODUCT_MOCK_IMAGES}
        images={props.productImages}
        noImageLink={noImageLink}
        activeImageIndex={props.activeImageIndex}
        setActiveImageIndex={props.setActiveImageIndex}
      />
      <MobileOffersList
        productId={props.product?.id}
        activeOfferId={props.currentOffer.id}
        offers={sortedOffers}
        onSelectOffer={props.onSelectOffer}
        product={props.product}
      />
      {/* {sortedOffers.length ? (
        <button
          className={styles["button-cheap"]}
          onClick={() => setIsOpenBottomSheet(true)}
        >
          <img
            src={saleTicketIcon}
            alt="sale_ticket-icon"
            className={styles["button-cheap__icon"]}
          />
          <span className={styles["button-cheap__title"]}>
      Нашли дешевле? Сообщите нам!
          </span>
        </button>
      ) : (
        <div className={styles["button-cheap"]} />
      )} */}
      <div className={styles["button-cheap"]} />
      <button
        className={cn(styles["button-cart"], {
          [styles["button-cart-disabled"]]:
            props.isLoading || !props.currentOffer?.available,
          [styles["button-cart--no-price"]]: sortedOffers.length === 0
        })}
        onClick={!productInCart ? addProduct : addProductAmount}
        disabled={props.isLoading || !props.currentOffer?.available}
      >
        {productInCart ? (
          <span className={styles["button-cart--title"]}>Добавить ещё</span>
        ) : (
          <span className={styles["button-cart--title"]}>
            Добавить в корзину
          </span>
        )}
        {sortedOffers.length > 0 && props.currentOffer.price && (
          <span className={styles["button-cart__price"]}>
            {!props.currentOffer.promotionPrice ? (
              !!props.currentOffer.price && `${props.currentOffer.price} ₽`
            ) : (
              <>
                <span className={styles["button-cart__price--promo"]}>
                  {`${formatPrice(props.currentOffer.promotionPrice)} ₽ `}
                </span>
                {props.currentOffer.price &&
                  props.currentOffer.price !==
                    props.currentOffer.promotionPrice && (
                  <span className={styles["button-cart__price--main"]}>
                    {`${formatPrice(props.currentOffer.price)} ₽ `}
                  </span>
                )}
              </>
            )}
          </span>
        )}
      </button>
      <div className={styles.dropdowns}>
        {process.env.REACT_APP_COMPONENT_ENABLE_DOLYAMI_DROP_DOWN ===
          "true" && (
          <DolyamiDropDown
            price={
              props.currentOffer.promotionPrice ?? props.currentOffer.price ?? 0
            }
          />
        )}
        {!!sortedOffers.length && (
          <CostCalculationDropDown
            price={
              props.currentOffer.promotionPrice ?? props.currentOffer.price ?? 0
            }
          />
        )}
        <HelpDropDown />
      </div>
      <BottomSheet
        isOpen={isOpenBottomSheet}
        onClose={() => setIsOpenBottomSheet(false)}
      >
        <ContentFoundCheaper
          currentOfferId={props.currentOffer.id}
          onClose={() => setIsOpenBottomSheet(false)}
        />
      </BottomSheet>
    </section>
  );
};

export default MobileMainProductContent;
