import { getStatusStyle, OrderStatusesEnum } from "utils/helpers/getStatusStyle";
import styles from "./styles.module.scss";
import { useMemo } from "react";

const StatusBadge: React.FC<{ status: OrderStatusesEnum }> = ({ status }) => {
  const { text, color } = useMemo(() => getStatusStyle(status), [status]);

  return (
    <div className={`${styles.container} ${styles[color]}`}>
      <div className={styles.dot}></div>
      <span>{text}</span>
    </div>
  );
};

export default StatusBadge;