import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import Addresses from "./components/Addresses/Addresses";
import ChangingPassword from "./components/ChangingPassword/ChangingPassword";
import LinkingToSocial from "./components/LinkingToSocial/LinkingToSocial";
import UserDataUpdate from "./components/UserDataUpdate/UserDataUpdate";
import styles from "./styles.module.scss";

const PersonalDataV2: React.FC = () => {
  return (
    <ProfileWrapper title="Личные данные">
      <UserDataUpdate />
      <Addresses />
      <div className={styles.content__block}>
        <LinkingToSocial />
        <ChangingPassword />
      </div>
    </ProfileWrapper>
  );
};

export default PersonalDataV2;