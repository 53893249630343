import { useEffect, useMemo, useRef, useState } from "react";
import whatsupSvg from "../../../../assets/icons/whatsup.svg";
import copySvg from "../../../../assets/icons/copy.svg";
import vkSvg from "../../../../assets/icons/vk.svg";
import telegramSvg from "../../../../assets/icons/telegram.svg";
import okSvg from "../../../../assets/icons/ok.svg";
import mailSvg from "../../../../assets/icons/mail.svg";
import { ReactComponent as StarIcon } from "../../../../assets/icons/svg/star-icon.svg";

import {
  addUserFavoriteProduct,
  removeUserFavoriteProduct,
} from "../../../../store/user/userThunks/userThunks";

import Breadcrumbs from "../../../../components/Breadcrumbs";
// import heartEmptyIcon from "../../../../assets/icons/png/heart_empty-icon.png";
// import heartFoolIcon from "../../../../assets/icons/png/heart_fool-icon.png";
import shareIcon from "../../../../assets/icons/png/share-icon.png";

import useOutsideClick from "../../../../utils/hooks/useOutsideClick";
import { SkeletonLine } from "../../../../components/UI/Skeleton/SkeletonLine/SkeletonLine";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { IBreadcrumb } from "utils/breadcrumbsUtils";
import { FavoriteProductButton } from "pages/UserCart/components/CartProducts/components/CartProductItemV2/components/CartProductActions/components/FavoriteProductButton/FavoriteProductButton";
import { toast } from "react-toastify";

import styles from "./styles.module.scss";

interface IShareUrlItem {
  name: string;
  img: string;
  url: string;
}

interface IProductHeaderLineProps {
  productId?: number;
  breadcrumbsValues: IBreadcrumb[];
  productTitle?: string;
  image?: string;
  isLoading: boolean;
}

const ProductHeaderLine: React.FC<IProductHeaderLineProps> = (props) => {
  const { user } = useAppSelector((state) => state.user);
  const [isPopup, setIsPopup] = useState(false);
  const dispatch = useAppDispatch();
  const socialBox = useRef<HTMLDivElement | null>(null);
  const shareIconRef = useRef<HTMLButtonElement | null>(null);
  const url = window.location.href;

  const isProductInFavourite = useMemo(() => {
    if (!props.productId) return false;
    const idx = user.favouriteProducts.findIndex(
      item => item.product.id === props.productId
    );
    return idx !== -1;
  }, [user.favouriteProducts, props.productId]);

  const [isFavorite, setIsFavorite] = useState(isProductInFavourite);

  useEffect(() => {
    if (props.productId) {
      setIsFavorite(isProductInFavourite);
    }
  }, [isProductInFavourite, props.productId]);

  const formattedBody = ` Я хочу порекомендовать тебе этот продукт\n ${url}`;

  const shareUrl: IShareUrlItem[] = [
    {
      name: "vk",
      img: vkSvg,
      url: `https://vk.com/share.php?url=${url}&title=${props.productTitle}&image=${props.image}`,
    },
    {
      name: "whatsapp",
      img: whatsupSvg,
      url: `https://api.whatsapp.com/send/?text=${url}`,
    },
    {
      name: "telegram",
      img: telegramSvg,
      url: `https://t.me/share/url?url=${url}`,
    },
    {
      name: "ok",
      img: okSvg,
      url: `https://connect.ok.ru/offer?url=${url}`,
    },
    {
      name: "mail",
      img: mailSvg,
      url: "mailto:x@y.com?body=" + encodeURIComponent(formattedBody),
    },
  ];


  useOutsideClick({
    ref: socialBox,
    cb: () => setIsPopup(false),
    exceptions: [shareIconRef]
  });

  const onToggleFavoriteStatus = async () => {
    if (typeof props.productId !== "number") {
      return;
    }
    try {
      setIsFavorite(!isFavorite);
      if (!isFavorite) {
        dispatch(addUserFavoriteProduct(props.productId));
      } else {
        dispatch(removeUserFavoriteProduct({product: props.productId}));
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        "Произошла ошибка добавить/убрать из избранного товар",
        err
      );
      toast.error(
        <div>
          Произошла ошибка добавить/убрать из избранного товар.
          <br />
          ТОВАР: {`${props.productTitle}`}
        </div>
      );
      throw new Error("Произошла ошибка добавить/убрать из избранного товар");
    }
  };

  const activeBtn = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsPopup((prev) => !prev);
  };

  const socialClickHandler = () => {
    setIsPopup(!isPopup);
  };

  const copyBtn = () => {
    setIsPopup(!isPopup);
    navigator.clipboard.writeText(url);
  };

  return (
    <div className={styles.root}>
      <div className={styles.breadcrumbs}>
        {!props.isLoading ? <Breadcrumbs breadcrumbs={props.breadcrumbsValues} /> : <SkeletonLine className={styles["breadcrumbs-skeleton"]} />}
      </div>
      <div className={styles.header__additional}>
        {!props.isLoading ? (
          user?.phone && (
            <button
              className={styles.header__additional__item}
            >
              <FavoriteProductButton
                isFavorite={isFavorite}
                onToggleFavoriteStatus={onToggleFavoriteStatus}
              />
              <span className={styles.header__additional__item_title}>
                Избранное
              </span>
            </button>
          )
        ) : (
          <SkeletonLine className={styles["header__additional__item_title-skeleton"]} />
        )}
        <meta itemScope itemType="https://schema.org/Article"/>
        {!props.isLoading ?
          <div className={styles.header__share} itemScope itemType="https://schema.org/SocialMediaPosting">
            <button
              className={styles.header__share__item}
              onClick={activeBtn}
              ref={shareIconRef}
            >
              <img
                src={shareIcon}
                alt='share-icon'
                className={styles.header__share__item_icon}
              />
              <p className={styles.header__share__item_title}>
                Поделиться
              </p>
            </button>
            <div className={styles.social_container} ref={socialBox}>
              {isPopup && (
                <ul className={styles.social_container__popup}>
                  <li
                    className={styles.social_container__popup__item}
                    onClick={copyBtn}
                    itemScope
                    itemType="https://schema.org/Action"
                    itemProp="potentialAction"
                  >
                    <meta itemProp="actionStatus" content="CopyAction" />
                    <img
                      src={copySvg}
                      className={styles.social_container__popup__item_img}
                    />
                  </li>

                  {shareUrl.map(item => (
                    <li
                      className={styles.social_container__popup__item}
                      key={item.name}
                      onClick={socialClickHandler}
                      itemScope
                      itemType="https://schema.org/WebPage"
                      itemProp="sharedContent"
                    >
                      <a href={item.url} data-name={item.name} rel="noopener noreferrer" target="_blank">
                        <img
                          src={item.img}
                          className={styles.social_container__popup__item_img}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          : <SkeletonLine className={styles["header__additional__item_title-skeleton"]} />}

        <div>
          {process.env.REACT_APP_COMPONENT_ENABLE_FEEDBACK_FOR_OFFER !==
            "false" &&
            (
              <div className={styles["star-rating"]}>
                <div className={styles["star-rating__list"]}>
                  {[...Array(5)].map((_, idx) => {
                    return (
                      <StarIcon key={idx} className={styles["star-rating__item"]} />
                    );
                  })}
                </div>
                <button className={styles["star-rating__button"]}>25 отзывов</button>
              </div>
            )}
        </div>

      </div>
    </div>
  );
};

export default ProductHeaderLine;
