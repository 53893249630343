import { OrderOffer } from "types/IGetUserOrderData";
import OrderTotalItem from "./OrderTotalItem/OrderTotalItem";
import styles from "./styles.module.scss";

interface OrderTotalItemsProps {
  items: OrderOffer[];
}

const OrderTotalItems: React.FC<OrderTotalItemsProps> = ({ items }) => {
  return (
    <div className={styles.items}>
      <h2 className={styles.items__title}>Состав заказа {items.length} шт.</h2>
      <div className={styles.items__container}>
        {items.map((item, index) => (
          <OrderTotalItem key={`${item.id}-${item.offer.id}-${index}`} item={item} />
        ))}
      </div>
    </div>
  );
};

export default OrderTotalItems;