import React, { useEffect, useState } from "react";
import cn from "classnames";

import Portal from "HOC/Portal";
import { IImagesCache } from "types/IImagesCache";

import styles from "./styles.module.scss";

interface ProductModalImageProps {
  originalSrc: string;
  modalRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  imagesCache: IImagesCache;
}

export const ProductModalImage: React.FC<ProductModalImageProps> = ({
  originalSrc,
  modalRef,
  imagesCache,
  onClose
}) => {
  const [currentSrc, setCurrentSrc] = useState<string | null>(null);
  const [isMounted, setIsMounted] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    setIsMounted(true);

    const loadImages = async () => {
      if (hasError) {
        onClose();
        return;
      }

      const loadImage = (src: string): Promise<string> => {
        if (imagesCache[src]) {
          return Promise.resolve(imagesCache[src]);
        }

        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            imagesCache[src] = src;
            resolve(src);
          };
          img.onerror = (error) => {
            setHasError(true);
            // eslint-disable-next-line no-console
            console.error(`Не удалось загрузить картинку ${src}`, error);
            reject(error);
          };

          signal.addEventListener("abort", () => {
            reject(new DOMException("Aborted", "AbortError"));
          });
        });
      };

      try {
        if (originalSrc) {
          const src = await loadImage(originalSrc);
          if (isMounted && src) {
            setCurrentSrc(src);
          }
        }
      } catch (error) {
        if (
          error !== null &&
          typeof error === "object" &&
          "name" in error &&
          error.name === "AbortError"
        ) {
          // eslint-disable-next-line no-console
          console.log(
            "\x1b[33m%s\x1b[0m",
            "Произошла отмена загрузки фотографии" + error
          );
        } else {
          // eslint-disable-next-line no-console
          console.error(`Ошибка при загрузке картинки ${originalSrc}`, error);
        }
      }
    };

    loadImages();

    return () => {
      controller.abort();
      setIsMounted(false);
      setCurrentSrc(null);
      setHasError(false);
    };
  }, [hasError, imagesCache, isMounted, onClose, originalSrc]);

  return (
    <Portal modalRef={modalRef} onClose={onClose}>
      <div className={styles["modal-image"]} onClick={onClose}>
        <div className={styles["modal-image__backdrop"]} />
        <img
          src={currentSrc || ""}
          alt=""
          className={cn(styles["modal-image__image"], {
            [styles["modal-image__image--loaded"]]: currentSrc
          })}
          onClick={(e) => e.stopPropagation()}
        />
        <button
          className={styles["modal-image__close-button"]}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <span />
          <span />
        </button>
      </div>
    </Portal>
  );
};
