import React, { InputHTMLAttributes } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

interface CustomRadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  onCustomChange: (checked: boolean) => void;
  label?: string;
  className?: string;
  labelClassName?: string;
  size?: number;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  checked,
  onCustomChange,
  label,
  disabled,
  className,
  labelClassName,
  size = 22,
  ...props
}) => {
  return (
    <label className={cn(styles.radio, className)}>
      <input
        type="radio"
        checked={checked}
        onChange={(e) => onCustomChange(e.target.checked)}
        disabled={disabled}
        style={{ display: "none" }}
        {...props}
      />
      <span
        className={cn(styles.radio__indicator, { [styles.checked]: checked })}
        style={{ width: size, height: size }}
      >
        {checked && <span className={styles.radio__inner} />}
      </span>
      <span className={cn(styles.radio__label, labelClassName)}>{label}</span>
    </label>
  );
};

export default CustomRadioButton;